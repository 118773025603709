import Sheet from "react-modal-sheet";
import { CloseModal } from "@/app/screens/modal/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

export const InfoMobile = () => {
  const { title, info } = globalModalStore.state?.navState?.mobileInfo || {};

  if (!title || !info) globalModalStore.toggleModal();
  return (
    <div className={"flex h-full flex-grow flex-col "}>
      <CloseModal />
      <Sheet.Header
        className={"my-8 flex flex-col items-center justify-center"}
      >
        <span className="text-xl font-semibold">{title}</span>
      </Sheet.Header>
      <Sheet.Content className={"mx-5 flex h-full justify-between"}>
        <div>{info}</div>
      </Sheet.Content>
    </div>
  );
};
