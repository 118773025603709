import ReactPaginate from "react-paginate";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { TableDef, TableRowData } from "@/app/components/table-new";
import { useTable } from "@/app/components/table-new/table.hooks.ts";

const isPaginationEnabled = (table: TableDef<TableRowData>): boolean => {
  const manualPagination = table.options.manualPagination;
  if (manualPagination) {
    return true;
  }

  const isPaginationEnabled = table.options.getPaginationRowModel !== undefined;

  if (!isPaginationEnabled) {
    return false;
  }

  const totalPages = table.getPageCount();

  return totalPages > 1;
};

export const Pagination = () => {
  const { table } = useTable();

  if (!table) {
    return null;
  }

  const isEnabled = isPaginationEnabled(table);

  if (!isEnabled) {
    return null;
  }
  const totalPages = table.getPageCount();

  return (
    <ReactPaginate
      className={cn(
        "sticky inset-x-0 bottom-1 top-0 z-10 mt-4 flex h-8 w-full flex-1 items-center justify-center gap-x-2 bg-white text-neutral-800 hover:text-neutral-800 select-none",
      )}
      pageLinkClassName="flex justify-center items-center bg-transparent text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:text-gray-500 rounded-[4px] size-8"
      activeLinkClassName="!bg-gray-300 !text-gray-900 transition-all duration-50 ease-linear"
      containerClassName={"relative"}
      onPageChange={({ selected }) => table.setPageIndex(selected)}
      pageRangeDisplayed={2}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      breakLabel="..."
      nextLabel={<Icon className="size-4" type={"Chevron Right"} />}
      nextClassName={"size-8 font-semibold pointer-select select-none right-6 absolute"}
      nextLinkClassName="block p-2 bg-gray-300 text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 rounded-[4px] disabled:text-gray-500"
      previousLabel={<Icon className="size-4" type={"Chevron Left"} />}
      previousClassName={"size-8 font-semibold pointer-select select-none left-6 absolute"}
      previousLinkClassName="block p-2 bg-gray-300 text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 rounded-[4px] disabled:text-gray-500"
      disabledLinkClassName="pointer-events-none bg-gray-300 !text-gray-500 rounded-[4px]"
      renderOnZeroPageCount={null}
    />
  );
};
