export const dashboardSections = {
  addedByMe: {
    id: "addedByMe",
    label: "Incomplete companies added by me",
  },
  sentimentRequests: {
    id: "sentiment-requests",
    label: "Sentiment forms",
  },
  companiesForwarded: {
    id: "companies-forwarded",
    label: "Companies forwarded",
  },
  companyUpdates: {
    id: "company-updates",
    label: "Company updates",
  },
  notProgressing: {
    id: "not-progressing",
    label: "Close the loop",
  },
  assigned: {
    id: "assigned",
    label: "My live prospects",
  },
  following: {
    id: "following",
    label: "Tracking",
  },
};
