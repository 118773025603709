import { useState } from "react";

import { useRemoveAssigneeMutation } from "@/app/service/company-assignees.gql.ts";

import { AssigneeEdges, readAssigneesCache, writeAssigneesCache } from "./helpers";

function removeAssigneeFromCache(memberId: string, cachedAssignees: AssigneeEdges) {
  let isLeadRemoved = false;
  let newLead: AssigneeEdges[number] | null = null;

  const updatedAssignees = cachedAssignees.reduce<AssigneeEdges>((accumulator, assignee) => {
    const { node } = assignee;
    if (node.memberId === memberId) {
      if (node.status === "lead") {
        isLeadRemoved = true;
      }
      return accumulator;
    }
    return [...accumulator, assignee];
  }, []);

  if (isLeadRemoved && updatedAssignees.length > 0) {
    newLead = {
      ...updatedAssignees[0],
      node: { ...updatedAssignees[0].node, status: "lead" },
    };
    updatedAssignees[0] = newLead;
  }

  return updatedAssignees;
}

export const useRemoveAssignee = () => {
  const [loading, setLoading] = useState(false);
  const [removeAssignee, { error }] = useRemoveAssigneeMutation();

  const onRemoveAssignee = async (memberId: string, companyId: string) => {
    const cachedAssignees = readAssigneesCache(companyId);

    try {
      setLoading(true);

      writeAssigneesCache(companyId, removeAssigneeFromCache(memberId, cachedAssignees));

      const response = await removeAssignee({
        variables: {
          input: { companyId, memberId },
        },
      });

      return response;
    } catch {
      writeAssigneesCache(companyId, cachedAssignees);
    } finally {
      setLoading(false);
    }
  };

  return { onRemoveAssignee, loading, error };
};
