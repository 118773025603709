import React, { Suspense, useEffect } from "react";

import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { useMembersMapped, usePcgCompanies, useTopInvestors } from "@/lib/service/members.ts";

import { Loader } from "@/app/components";
import { VerticalNavbar, MobileNavbar } from "@/app/components/navbar";
import { Routes } from "@/app/constants";
import { GlobalModalProvider } from "@/app/providers";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { GlobalModal } from "@/app/screens/modal/global-modal.tsx";
import { GlobalNativeModal } from "@/app/screens/modal/global-native-modal.tsx";
import { SlideOver } from "@/app/screens/modal/slideover.tsx";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CompleteSentimentModalResponsive } from "../screens/modal/complete-sentiment";
import modalStore from "../stores/modal.store";
import { UserRoleType } from "@/gql/graphql.ts";
import { useMyRolesSuspenseQuery } from "@/app/service/user.gql.ts";

const AppLayout = () => {
  usePrivateLayout();
  const { isBigTablet } = useBreakpoints();

  return (
    <>
      <div className={"relative flex flex-col lg:flex-row"}>
        {isBigTablet ? <VerticalNavbar /> : <MobileNavbar />}
        <Suspense fallback={<Loader className="!relative !flex size-full" />}>
          <Outlet />
        </Suspense>
      </div>
      <GlobalNativeModal />
      <GlobalModal />
      <GlobalModalProvider />
      <SlideOver />
      {/*TODO: Find a different feedback mechanism - until then just commenting feedback.*/}
      {/*<Feedback />*/}
    </>
  );
};

export const PrivateLayout = observer(() => {
  if (!authStore.user || Object.keys(authStore.user).length == 0) {
    return <Navigate to="/login" state={{ next: location.pathname }} replace />;
  }

  return <AppLayout />;
});

const underConstructionRoutes = [];

const useUserRoles = () => {
  const data = useMyRolesSuspenseQuery();
  authStore.userRoles = (data?.data?.myRoles?.roles as UserRoleType[]) ?? [];
};

const usePrivateLayout = () => {
  useUserRoles();
  useMembersMapped();
  usePcgCompanies();
  useTopInvestors();

  const location = useLocation();
  const { id } = useParams();
  const { isBigTablet } = useBreakpoints();

  const shouldShowForms = location.pathname.startsWith(`${Routes.forms}/`);

  useEffect(() => {
    if (shouldShowForms && id) {
      modalStore.open(CompleteSentimentModalResponsive, {
        props: {
          label: "Complete Sentiment",
          id,
        },
      });
    }
    if (!isBigTablet && underConstructionRoutes.some((route) => location.pathname.includes(route))) {
      globalModalStore.navigate({
        modalType: "UnderConstruction",
        navState: { detent: "content-height" },
      });
    }
  }, [location, id]);
};
