import React from "react";

import { TooltipInfo } from "./info";
import { Popover, PopoverPlacement } from "./popover";

type TooltipProps = {
  text: string;
  placement?: PopoverPlacement;
  children: React.ReactNode;
  containerClassName?: string;
  hideArrow?: boolean;
};

export const Tooltip = ({ text, placement, children, containerClassName, hideArrow }: TooltipProps) => {
  return (
    <Popover
      containerClassName={containerClassName}
      placement={placement}
      childrenContent={<TooltipInfo text={text} />}
      hideArrow={hideArrow}
    >
      {children}
    </Popover>
  );
};
