import { GetCompanyAssigneesQuery } from "@/gql/graphql";

import { useGetCompanyAssigneesQuery } from "@/app/service/company-assignees.gql.ts";

type LgAssignee = NonNullable<GetCompanyAssigneesQuery["lgAssignees"]>["edges"][number]["node"];

type Assignee = {
  status?: string | null;
  companyId: string;
} & LgAssignee["member"];

export const useCompanyAssignees = (companyId: string) => {
  let assignees: Assignee[] = [];
  const { data, loading } = useGetCompanyAssigneesQuery({
    variables: { companyId },
    fetchPolicy: "cache-and-network",
  });

  if (data?.lgAssignees) {
    assignees = data.lgAssignees.edges
      .reduce<Assignee[]>((accumulator, { node }) => {
        const { status, companyId, member } = node;

        if (member) {
          return [...accumulator, { ...member, companyId, status }];
        }

        return accumulator;
      }, [])
      .sort((a, b) => {
        if (a.status === "lead" && b.status !== "lead") {
          return -1;
        } else if (a.status !== "lead" && b.status === "lead") {
          return 1;
        }
        return 0;
      });
  }

  return {
    assignees,
    loading,
  };
};
