import { normaliseDate } from "@/app/misc/helpers";
import membersStore from "@/app/stores/members.store";
import { ProfileImage } from "../../../../../components";
import { text } from "@/app/styles";
import { TrelloComment } from "@/lib/service/trello.types";
import { LinkedLabel } from "@/app/components/label/linked-label";
import { cn } from "@/lib/utils";

export interface CommentListProps {
  total: number;
  displayComments: TrelloComment[];
  route?: string;
  contentContainer?: string;
  commentContainer?: string;
  size?: "md" | "lg";
}

export const CommentList = ({
  total,
  displayComments,
  route = "",
  contentContainer,
  commentContainer,
  size = "lg",
}: CommentListProps) => {
  if (total === 0) return null;

  return (
    <div>
      {route && (
        <LinkedLabel label={"Comments"} href={route}>
          <span className={`${text.body} ml-2 font-medium text-primary`}>Read All</span>
          <span className={`${text.titleText} text-lg font-semibold text-primary`}>{String(total)}</span>
        </LinkedLabel>
      )}
      <div className={cn("grid grid-cols-2 gap-5", contentContainer)}>
        {displayComments.map(
          ({ idMemberCreator, date, data: { text: value } }, i) => {
            const {
              firstName = "",
              avatar,
              lastName = "",
              email,
            } = membersStore.getByTrelloId(idMemberCreator) || {};
            const displayName =
              size === "md"
                ? firstName
                : firstName || lastName
                ? `${firstName} ${lastName}`
                : email;

            return (
              <div
                className={cn(
                  "flex flex-col gap-y-1.5 rounded-md border p-1",
                  commentContainer
                )}
                key={i}
              >
                <div className={"flex items-center gap-2"}>
                  <ProfileImage
                    firstname={firstName}
                    lastname={lastName}
                    image={avatar ?? undefined}
                    containerClass={
                      size === "lg" ? "h-12 min-w-12" : "h-6 min-w-6"
                    }
                    imgClass={cn(
                      "rounded-xs",
                      size === "lg" ? "h-12 w-12" : "h-6 w-6"
                    )}
                  />
                  <div className={"flex w-full items-center justify-between"}>
                    <span
                      className={cn(
                        "text-sm font-semibold",
                        size === "lg" ? text.body : text.message
                      )}
                    >{displayName}</span>
                    <span
                      className={cn(
                        "text-neutral-400 text-sm",
                        size === "lg" ? "font-medium" : "font-semibold",
                        size === "lg" ? text.message : text.small,
                      )}
                    >{`on ${normaliseDate(date)}`}</span>
                  </div>
                </div>
                <span
                  className={cn(
                    "line-clamp-3 text-left font-medium text-neutral-700",
                    size === "lg" ? text.body : text.message,
                  )}
                >{value}</span>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
