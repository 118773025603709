import React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";

import { Button } from "@/app/components";
import { SignalFormFields } from "@/app/screens/add-company/company-signals-step/SignalFormFields.tsx"; // todo move ?

import { NetworkSignalData } from "./network-signal.types.ts";

const companySignalSchema = z.object({
  fund: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
  sourceName: z
    .string({ invalid_type_error: "Please select valid type", required_error: "Source is required" })
    .min(1, "Source is required"),
  sourceType: z.string({ required_error: "Name is required" }).min(1, "Source type is required"),
  introAvailable: z.boolean().optional().nullable(),
  highPriority: z.boolean().optional().nullable(),
  isDeepTech: z.boolean().optional().nullable(),
  // ballInOurControl: z.boolean().optional().nullable(),
  activelyRaising: z.boolean().optional().nullable(),
});

export type CompanySignalSchema = z.infer<typeof companySignalSchema>;

// todo can be shared with second step of "Add Company" flow, but do we really want to do it?
export const useNetworkSignalForm = (defaultValues?: NetworkSignalData) => {
  return useForm<CompanySignalSchema>({
    resolver: zodResolver(companySignalSchema),
    mode: "onChange",
    defaultValues: {
      // fund: "",
      note: defaultValues?.note ?? "",
      sourceName: defaultValues?.sourceName ?? "",
      sourceType: defaultValues?.sourceType ?? "",
      introAvailable: defaultValues?.attributes.canConnect ?? false,
      highPriority: defaultValues?.attributes.isHighPriority ?? false,
      // ballInOurControl:  false, // todo is it still needed?
      isDeepTech: defaultValues?.attributes.isDeepTech ?? false, // todo is this the right field?
      activelyRaising: defaultValues?.attributes.isActivelyFundraising ?? false,
    },
  });
};

export const NetworkSignalEdit = ({
  onClose,
  signal,
  onSubmit,
  loading,
}: {
  signal?: NetworkSignalData;
  onSubmit: (data: CompanySignalSchema) => void;
  onClose: () => void;
  loading?: boolean;
}) => {
  const { control, handleSubmit, reset, watch } = useNetworkSignalForm(signal);

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <SignalFormFields control={control} watch={watch} />

      <div className="flex justify-end gap-4">
        <Button className={"min-w-40"} variant="outline" size={"md"} onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button className={"min-w-40"} size={"md"} variant="gradient" loading={loading}>
          Save
        </Button>
      </div>
    </form>
  );
};
