import { cn } from "@/lib/utils";

import { Button, Icon } from "@/app/components";
import { Fund } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";

const fundOptions: Array<{ value: Fund; label: string }> = [
  { value: Fund.LocalGlobe, label: "LocalGlobe" },
  { value: Fund.Latitude, label: "Latitude" },
  { value: Fund.Solar, label: "Solar" },
  { value: Fund.Pipeline, label: "No - move straight to Pipeline" },
];

type FundSelectControlProps = {
  value?: Fund;
  onChange: (value: Fund) => void;
  recommended?: Fund;
};

export const FundSelectControl = ({ value, onChange, recommended }: FundSelectControlProps) => {
  return (
    <div className="@container">
      <ul className="grid select-none grid-cols-2 gap-4 @xl:grid-cols-4">
        {fundOptions.map((option) => (
          <li
            key={option.value}
            className={cn(
              "flex aspect-square overflow-hidden rounded-md border",
              option.value === value && "border-green-600",
            )}
          >
            <Button
              type="button"
              onClick={() => {
                onChange(option.value);
              }}
              size="lg"
              className="relative !size-full flex-col justify-center gap-1"
            >
              <Icon type="Check" className="mt-2 size-6 shrink-0 text-green-600" />
              <span className="my-auto">{option.label}</span>
              <span
                className={cn(
                  "invisible rounded-xxs bg-green-100/60 px-2 py-1 font-normal",
                  !!recommended && option.value === recommended && "visible",
                )}
              >
                Suggested
              </span>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};
