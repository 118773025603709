import { memo } from "react";

import { AssigneeStatus } from "@/gql/graphql";

import { DropdownV2, Icon, MemberCard, Popover } from "@/app/components";
import { ConfirmModal } from "@/app/screens/modal";
import { authStore } from "@/app/stores/auth.store";
import { LGMember } from "@/app/stores/members.store";
import modalStore from "@/app/stores/modal.store";

import { AddAssignees } from "./assign-dropdown";
import { useUpdateStatusMutation } from "./use-assign-status";
import { useCompanyAssignees } from "./use-company-assignees";
import { useRemoveAssignee } from "./use-remove-assignee";

export interface AssigneesViewProps {
  assignees: Array<LGMember & { assigneeId: string; status?: string }>;
  companyId: string;
}

export const Assignees = memo(function Assignees({ companyId }: AssigneesViewProps) {
  const { onRemoveAssignee } = useRemoveAssignee();
  const { onAssignStatus } = useUpdateStatusMutation();
  const { assignees } = useCompanyAssignees(companyId);

  const handleRemoveClick = ({ firstName, lastName, avatar, id }) =>
    modalStore.open(ConfirmModal, {
      props: {
        title:
          authStore.userId === id
            ? "Are you sure this Company is not relevant to you?"
            : `Remove ${firstName} ${lastName}?`,
        subTitle:
          authStore.userId === id
            ? "By confirming, you will be unassigned from the company"
            : `Are you sure you want to remove ${firstName} ${lastName} from reviewing this company?`,
        icon:
          authStore.userId !== id ? (
            <div className="flex w-full justify-center">
              <MemberCard
                key={id}
                firstname={firstName}
                lastname={lastName}
                image={avatar}
                includeName={false}
                imgClassName={"w-[68px] h-[68px] rounded-sm border-white mb-4"}
                className={"flex justify-start"}
              />
            </div>
          ) : undefined,
        handleConfirm: () => onRemoveAssignee(id, companyId),
      },
    });

  return (
    <div data-cy="assignees">
      <span className="text-lg font-medium text-neutral-900">Small Group</span>
      <div className="my-1 flex items-start gap-1" data-cy="assigneed-users">
        <div className="flex flex-wrap items-center gap-1">
          {assignees.map(({ id, firstName, lastName, avatar, status }) => {
            return (
              <div key={id} className="group relative">
                <DropdownV2>
                  <DropdownV2.Button className={"h-auto p-0 hover:bg-transparent"}>
                    <Popover
                      closeOnClick={true}
                      childrenClassName="mt-2 p-2.5"
                      childrenContent={
                        <span className="text-sm font-medium">
                          {firstName} {lastName} {status ? <span className="capitalize">({status})</span> : ""}
                        </span>
                      }
                    >
                      <MemberCard
                        key={id}
                        firstname={firstName}
                        lastname={lastName}
                        image={avatar}
                        includeName={false}
                        imgClassName={
                          "size-6 rounded-xxs border border-white hover:border-neutral-800 filter-none grayscale hover:opacity-80"
                        }
                        className={"flex cursor-pointer justify-start"}
                      />
                    </Popover>
                    {status === "lead" && (
                      <div className="absolute -bottom-0.5 -left-0.5 rounded-full bg-white p-px">
                        <Icon type={"Lead"} className="size-2" />
                      </div>
                    )}
                    <DropdownV2.Items className="rounded-xxs border" anchor={"bottom start"}>
                      <DropdownV2.Item
                        className={"py-2"}
                        onClick={() => handleRemoveClick({ id, firstName, lastName, avatar })}
                      >
                        Remove
                      </DropdownV2.Item>
                      {status !== "lead" && (
                        <DropdownV2.Item
                          className={"py-2"}
                          onClick={() => onAssignStatus({ companyId, memberId: id, status: AssigneeStatus.Lead })}
                        >
                          Make lead
                        </DropdownV2.Item>
                      )}
                    </DropdownV2.Items>
                  </DropdownV2.Button>
                </DropdownV2>
              </div>
            );
          })}
        </div>
        <AddAssignees alreadyAssigned={assignees.map(({ id }) => id)} companyId={companyId} />
      </div>
    </div>
  );
});
