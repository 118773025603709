import React, { useEffect, useMemo, useState } from "react";

import { DealroomFounderFilter, LgFounderFilter } from "@/gql/graphql.ts";

import { cn, getInitials } from "@/lib/utils.ts";

import { ImageWithErrorProfile, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  Autocomplete,
  AutocompleteButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
} from "@/app/components/forms/autocomplete.tsx";
import { useSearchFoundersGlobalQuery } from "@/app/service/founders.gql.ts";

type SearchField = keyof Pick<LgFounderFilter | DealroomFounderFilter, "name" | "linkedinUrl">;

const mapSearchFields = (searchFields: SearchField[], value: string): LgFounderFilter | DealroomFounderFilter => {
  if (searchFields.length === 0) {
    return {};
  }

  if (searchFields.length === 1) {
    return {
      [searchFields[0]]: { fuzzy: value },
    };
  }

  return {
    or: searchFields.map((field) => ({ [field]: { fuzzy: value } })),
  };
};

const useSearchResults = (searchFields: SearchField[] = ["name"]) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useSearchFoundersGlobalQuery({
    variables: {
      limit: 10,
      lgFilter: mapSearchFields(searchFields, searchTerm),
      dealroomFilter: {
        and: [mapSearchFields(searchFields, searchTerm), { srcFounderExist: false }],
      },
    },
    skip: !searchTerm,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = useMemo(() => {
    return [...(data?.lgFounders?.nodes ?? []), ...(data?.dealroomFounders?.nodes ?? [])];
  }, [data]);

  return { results, search, loading, searchTerm };
};

const FounderSearchInput = ({ handleSearch, loading, selectedFounder, onClear, placeholder }) => {
  const displayValue = (founder: FounderSearchResult | string | null): string => {
    return typeof founder === "string" ? founder : founder?.name ?? "";
  };

  return (
    <div className="relative">
      {selectedFounder && (
        <ImageWithErrorProfile
          src={typeof selectedFounder === "object" ? selectedFounder.image : null}
          className="absolute left-4 top-1/2 !size-7 !min-w-7 basis-7 -translate-y-1/2 rounded-xs"
          alt={selectedFounder.name ? getInitials(selectedFounder.name) : "N/A"}
        />
      )}
      <AutocompleteInput
        className={cn(
          "w-full rounded-sm border px-4 py-3 text-sm",
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
          selectedFounder && "pl-12",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-4 flex items-center gap-2">
        {loading && <ProgressSpinner className="size-4 text-inherit" />}
        {selectedFounder && <IconButtonV2 icon="X" size="xs" onClick={onClear} />}
        <AutocompleteButton as="div">
          <IconButtonV2 icon="Chevron Down" size="xs" />
        </AutocompleteButton>
      </div>
    </div>
  );
};

export type FounderSearchResult = ReturnType<typeof useSearchResults>["results"][number];

export type FounderChangeData = FounderSearchResult | string | null;

export const isDealroomFounder = (
  founder: FounderChangeData,
): founder is Extract<
  FounderSearchResult,
  {
    __typename?: "DealroomFounder";
  }
> => {
  return founder !== null && typeof founder === "object" && founder.__typename === "DealroomFounder";
};

export const isLgFounder = (
  founder: FounderChangeData,
): founder is Extract<
  FounderSearchResult,
  {
    __typename?: "LgFounder";
  }
> => {
  return founder !== null && typeof founder === "object" && founder.__typename === "LgFounder";
};

type FounderSearchAutocompleteProps = {
  onChange: (founder: FounderChangeData) => void;
  value?: string;
  selectedFounder?: FounderSearchResult | null;
  searchFields?: SearchField[];
  placeholder?: string;
};

export const FounderSearchAutocomplete = ({
  onChange,
  value,
  selectedFounder,
  searchFields,
  placeholder,
}: FounderSearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults(searchFields);

  useEffect(() => {
    // pre-search companies on initial load to open dropdown with results when input is clicked
    if (value) {
      search(value);
    }
  }, []);

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (founder: FounderSearchResult | string | null) => {
    // clear founder selection when using custom name
    onChange(founder);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <FounderSearchInput
            loading={loading}
            handleSearch={handleSearch}
            selectedFounder={selectedFounder}
            onClear={handleClear}
            placeholder={placeholder}
          />
        }
        options={
          <AutocompleteOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--input-width)] rounded-sm border bg-white p-1 [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "z-10", // needed because of broken location input
            )}
          >
            {!searchTerm && (
              <AutocompleteOption
                value={null}
                className={cn(
                  "mb-1 select-none rounded-sm bg-neutral-100 px-3 py-1.5 text-center text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
                disabled={true}
              >
                Start typing to see the results...
              </AutocompleteOption>
            )}
            {searchTerm && (
              <AutocompleteOption
                value={searchTerm}
                className={cn(
                  "mb-1 select-none rounded-sm bg-neutral-100 px-3 py-1.5 text-center text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
              >
                Use "{searchTerm}"
              </AutocompleteOption>
            )}
            {loading && (
              <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
            )}
            {results.map((founder) => (
              <AutocompleteOption
                key={founder.id ?? founder.name}
                value={founder}
                className={cn(
                  "flex select-none items-center gap-2 rounded-sm px-3 py-1.5 text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
              >
                <ImageWithErrorProfile
                  src={founder.image}
                  className="!size-8 !min-w-8 basis-8 rounded-xs"
                  labelClassName="rounded-xs"
                  alt={founder.name ? getInitials(founder.name) : "N/A"}
                />
                <div>
                  <div className="text-sm">{founder.name}</div>
                  <div className="text-xs text-gray-600">{founder.linkedinUrl}</div>
                </div>
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
