import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCompanyAssigneesQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"]["input"];
}>;

export type GetCompanyAssigneesQuery = {
  __typename?: "Query";
  lgAssignees?: {
    __typename?: "LgAssigneesConnection";
    edges: Array<{
      __typename?: "LgAssigneesEdge";
      node: {
        __typename?: "LgAssignee";
        status?: string | null;
        companyId: any;
        memberId: any;
        member?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          avatar?: string | null;
          initials: string;
        } | null;
      };
    }>;
  } | null;
};

export type AddAssigneesMutationVariables = Types.Exact<{
  input: Types.AddAssigneesInputInput;
}>;

export type AddAssigneesMutation = { __typename?: "Mutation"; assignUsers?: any | null };

export type AssignStatusMutationVariables = Types.Exact<{
  input: Types.AssignStatusInputInput;
}>;

export type AssignStatusMutation = { __typename?: "Mutation"; assigneeStatus?: any | null };

export type RemoveAssigneeMutationVariables = Types.Exact<{
  input: Types.RemoveAssigneesInput;
}>;

export type RemoveAssigneeMutation = { __typename?: "Mutation"; removeAssignee?: any | null };

export const GetCompanyAssigneesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyAssignees" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgAssignees" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_ASC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "companyId" } },
                            { kind: "Field", name: { kind: "Name", value: "memberId" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "member" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                  { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                  { kind: "Field", name: { kind: "Name", value: "initials" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyAssigneesQuery__
 *
 * To run a query within a React component, call `useGetCompanyAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAssigneesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAssigneesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables> &
    ({ variables: GetCompanyAssigneesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables>(
    GetCompanyAssigneesDocument,
    options,
  );
}
export function useGetCompanyAssigneesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables>(
    GetCompanyAssigneesDocument,
    options,
  );
}
export function useGetCompanyAssigneesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyAssigneesQuery, GetCompanyAssigneesQueryVariables>(
    GetCompanyAssigneesDocument,
    options,
  );
}
export type GetCompanyAssigneesQueryHookResult = ReturnType<typeof useGetCompanyAssigneesQuery>;
export type GetCompanyAssigneesLazyQueryHookResult = ReturnType<typeof useGetCompanyAssigneesLazyQuery>;
export type GetCompanyAssigneesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyAssigneesSuspenseQuery>;
export type GetCompanyAssigneesQueryResult = Apollo.QueryResult<
  GetCompanyAssigneesQuery,
  GetCompanyAssigneesQueryVariables
>;
export const AddAssigneesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddAssignees" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AddAssigneesInputInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "assignUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "addAssigneesInputInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddAssigneesMutationFn = Apollo.MutationFunction<AddAssigneesMutation, AddAssigneesMutationVariables>;

/**
 * __useAddAssigneesMutation__
 *
 * To run a mutation, you first call `useAddAssigneesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssigneesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssigneesMutation, { data, loading, error }] = useAddAssigneesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssigneesMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssigneesMutation, AddAssigneesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssigneesMutation, AddAssigneesMutationVariables>(AddAssigneesDocument, options);
}
export type AddAssigneesMutationHookResult = ReturnType<typeof useAddAssigneesMutation>;
export type AddAssigneesMutationResult = Apollo.MutationResult<AddAssigneesMutation>;
export type AddAssigneesMutationOptions = Apollo.BaseMutationOptions<
  AddAssigneesMutation,
  AddAssigneesMutationVariables
>;
export const AssignStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AssignStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AssignStatusInputInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "assigneeStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "assignStatusInputInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AssignStatusMutationFn = Apollo.MutationFunction<AssignStatusMutation, AssignStatusMutationVariables>;

/**
 * __useAssignStatusMutation__
 *
 * To run a mutation, you first call `useAssignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStatusMutation, { data, loading, error }] = useAssignStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignStatusMutation, AssignStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignStatusMutation, AssignStatusMutationVariables>(AssignStatusDocument, options);
}
export type AssignStatusMutationHookResult = ReturnType<typeof useAssignStatusMutation>;
export type AssignStatusMutationResult = Apollo.MutationResult<AssignStatusMutation>;
export type AssignStatusMutationOptions = Apollo.BaseMutationOptions<
  AssignStatusMutation,
  AssignStatusMutationVariables
>;
export const RemoveAssigneeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveAssignee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RemoveAssigneesInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeAssignee" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "removeAssigneesInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveAssigneeMutationFn = Apollo.MutationFunction<RemoveAssigneeMutation, RemoveAssigneeMutationVariables>;

/**
 * __useRemoveAssigneeMutation__
 *
 * To run a mutation, you first call `useRemoveAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssigneeMutation, { data, loading, error }] = useRemoveAssigneeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAssigneeMutation, RemoveAssigneeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAssigneeMutation, RemoveAssigneeMutationVariables>(RemoveAssigneeDocument, options);
}
export type RemoveAssigneeMutationHookResult = ReturnType<typeof useRemoveAssigneeMutation>;
export type RemoveAssigneeMutationResult = Apollo.MutationResult<RemoveAssigneeMutation>;
export type RemoveAssigneeMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssigneeMutation,
  RemoveAssigneeMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "ContactsConnectionsAggregated",
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;
