import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetDealroomCompanyFundingQueryVariables = Types.Exact<{
  companyId: Types.Scalars["BigInt"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type GetDealroomCompanyFundingQuery = {
  __typename?: "Query";
  dealroomFundingRounds?: {
    __typename?: "DealroomFundingRoundsConnection";
    nodes: Array<{
      __typename?: "DealroomFundingRound";
      id: number;
      round?: string | null;
      year?: number | null;
      month?: number | null;
    }>;
  } | null;
};

export const GetDealroomCompanyFundingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealroomCompanyFunding" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigInt" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomFundingRounds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "EnumValue", value: "YEAR_DESC" },
                    { kind: "EnumValue", value: "MONTH_DESC" },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "round" } },
                      { kind: "Field", name: { kind: "Name", value: "year" } },
                      { kind: "Field", name: { kind: "Name", value: "month" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetDealroomCompanyFundingQuery__
 *
 * To run a query within a React component, call `useGetDealroomCompanyFundingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealroomCompanyFundingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealroomCompanyFundingQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealroomCompanyFundingQuery(
  baseOptions: Apollo.QueryHookOptions<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables> &
    ({ variables: GetDealroomCompanyFundingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export function useGetDealroomCompanyFundingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export function useGetDealroomCompanyFundingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDealroomCompanyFundingQuery,
    GetDealroomCompanyFundingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export type GetDealroomCompanyFundingQueryHookResult = ReturnType<typeof useGetDealroomCompanyFundingQuery>;
export type GetDealroomCompanyFundingLazyQueryHookResult = ReturnType<typeof useGetDealroomCompanyFundingLazyQuery>;
export type GetDealroomCompanyFundingSuspenseQueryHookResult = ReturnType<
  typeof useGetDealroomCompanyFundingSuspenseQuery
>;
export type GetDealroomCompanyFundingQueryResult = Apollo.QueryResult<
  GetDealroomCompanyFundingQuery,
  GetDealroomCompanyFundingQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "ContactsConnectionsAggregated",
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;
