import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

// todo sync with db enum
export enum Fund {
  LocalGlobe = "LocalGlobe",
  Latitude = "Latitude",
  Solar = "Solar",
  Pipeline = "",
}

const companySignalSchema = z.object({
  fund: z.nativeEnum(Fund).optional(),
  note: z.string().optional().nullable(),
  sourceName: z
    .string({ invalid_type_error: "Please select valid type", required_error: "Source is required" })
    .min(1, "Source is required"),
  sourceType: z.string({ required_error: "Name is required" }).min(1, "Source type is required"),
  introAvailable: z.boolean().optional().nullable(),
  highPriority: z.boolean().optional().nullable(),
  ballInOurControl: z.boolean().optional().nullable(),
  activelyRaising: z.boolean().optional().nullable(),
  isDeepTech: z.boolean().optional().nullable(),
});

export type CompanySignalSchema = z.infer<typeof companySignalSchema>;

export const useCompanySignalForm = () => {
  return useForm<CompanySignalSchema>({
    resolver: zodResolver(companySignalSchema),
    mode: "onChange",
    defaultValues: {
      fund: Fund.Pipeline,
      note: "",
      sourceName: "",
      sourceType: "",
      introAvailable: false,
      highPriority: false,
      ballInOurControl: false,
      activelyRaising: false,
      isDeepTech: false,
    },
  });
};
