import { useState } from "react";

import { AssignStatusInputInput } from "@/gql/graphql.ts";

import { useAssignStatusMutation } from "@/app/service/company-assignees.gql.ts";

import { readAssigneesCache, writeAssigneesCache } from "./helpers";

export const useUpdateStatusMutation = () => {
  const [loading, setLoading] = useState(false);
  const [assignStatus, { error }] = useAssignStatusMutation();

  const onAssignStatus = async (input: AssignStatusInputInput, onSuccess?: () => void) => {
    const { companyId, memberId } = input;

    const assignees = readAssigneesCache(companyId);

    try {
      setLoading(true);

      writeAssigneesCache(
        companyId,
        assignees.map(({ node }) => {
          if (node.member?.id === memberId) {
            return {
              node: { ...node, status: input.status.toLocaleLowerCase() },
            };
          }

          return {
            node: { ...node, status: null },
          };
        }),
      );

      const response = await assignStatus({
        variables: {
          input,
        },
        onCompleted: () => {
          if (onSuccess) onSuccess();
        },
      });

      return response;
    } catch {
      writeAssigneesCache(companyId, assignees);
    } finally {
      setLoading(false);
    }
  };

  return { onAssignStatus, loading, error };
};
