import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Card, Icon, ProgressBarLoader } from "@/app/components";
import { Routes } from "@/app/constants";
import {
  EmailMetadata,
  isProcessUpdateEmailSuccessData,
  NotificationPayload,
  NotificationStatus,
} from "@/app/screens/dashboard/dashboard.types.ts";
import { standardMetrics } from "@/app/screens/metrics-extraction";

import { CompanyLink } from "../../components/company-link.tsx";
import { DateColumn } from "../../components/email-opportunity/date-column.tsx";
import { DetailsColumn } from "../../components/email-opportunity/details-column.tsx";
import { EmailAuthorColumn } from "../../components/email-opportunity/email-author-column.tsx";
import { EmailOpportunityActions } from "../../components/email-opportunity/email-opportunity-actions.tsx";
import { useSetEmailStatus } from "../../hooks/use-set-email-status.ts";

import { completedStatuses, useCompanyUpdates } from "./use-company-updates.ts";

type CompanyUpdateItemData = ReturnType<typeof useCompanyUpdates>["items"][number];

const getCompanyDataFromEmail = (
  email: CompanyUpdateItemData["nzrOpportunityEmailAsReference"],
): {
  id: string;
  name: string;
  image: string;
  totalMetrics: number;
} | null => {
  if (!email?.metadata) {
    return null;
  }

  const emailMetadata: EmailMetadata = email.metadata;
  let totalMetrics = 0;

  if (emailMetadata.process_metrics_extraction_update?.data?.body?.metrics) {
    totalMetrics += emailMetadata.process_metrics_extraction_update.data.body.metrics.filter(({ name }) =>
      standardMetrics.find(({ id }) => name === id),
    ).length;
  }

  if (emailMetadata.process_metrics_extraction_update?.data?.attathments?.length) {
    emailMetadata.process_metrics_extraction_update?.data?.attathments?.forEach((attachment) => {
      totalMetrics += attachment.metrics.filter(({ name }) => standardMetrics.find(({ id }) => name === id)).length;
    });
  }

  if (emailMetadata.process_metrics_extraction_update?.error !== null) {
    totalMetrics = 0;
  }
  return isProcessUpdateEmailSuccessData(emailMetadata?.process_update_email)
    ? {
        id: emailMetadata.process_update_email.data.company_id,
        name: emailMetadata.process_update_email.data.company_name,
        image: emailMetadata.process_update_email.data.company_image,
        totalMetrics,
      }
    : null;
};

export const CompanyUpdatesItem = ({
  notification,
  className,
}: {
  notification: CompanyUpdateItemData;
  className?: string;
}) => {
  const navigate = useNavigate();
  const { handleMarkAsDone, handleRemove, loading } = useSetEmailStatus({
    notificationId: notification.id,
    emailId: notification.nzrOpportunityEmailAsReference?.id,
  });

  const company = getCompanyDataFromEmail(notification.nzrOpportunityEmailAsReference);
  const title = (notification.payload as NotificationPayload).email.subject ?? "";
  const type = notification.type === "METRICS_EXTRACTION" && company?.totalMetrics ? "Metrics" : "Updates";
  const author =
    (notification.payload as NotificationPayload).email.source ??
    notification.nzrOpportunityEmailAsReference?.senderEmail ??
    "";
  const date = notification.createdUtc;

  const handleOpen = () => {
    navigate(`${Routes.metricsExtraction}/${notification.id}`, {
      state: { emailId: notification?.payload?.email?.id, emailOnly: !company?.totalMetrics },
    });
  };

  const isCompleted = completedStatuses.includes(notification.status as NotificationStatus);

  if (notification.status === NotificationStatus.LOADING) {
    return (
      <Card
        data-cy="dashboard-updates-item"
        className={cn(
          "group relative flex select-none flex-col items-start justify-between gap-4 gap-y-2 transition hover:scale-[1.01] md:flex-row md:items-center",
          className,
        )}
      >
        <Card.Body className="grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2">
          <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
            <EmailAuthorColumn
              author={author}
              label={title}
              styles={{
                labelStyle: "text-black text-sm font-semibold xl:min-w-[270px] line-clamp-1",
              }}
            />
            <DateColumn
              date={date}
              styles={{ container: "text-xs md:hidden min-w-[80px]", labelStyle: "w-full text-end" }}
            />
          </div>
          <DateColumn label="Date" date={date} styles={{ container: "hidden md:flex col-span-2", content: "h-full" }} />

          <div className={"flex w-full items-center gap-x-3"}>
            <div className={"flex items-center gap-x-2"}>
              <Icon type={"FillCheck"} className={"size-5 text-green-600"} />
              <span className="text-nowrap text-sm font-semibold">Email Received</span>
              <ProgressBarLoader />
            </div>
            <div className={"flex items-center gap-x-2"}>
              <Icon type={"FillCheck"} className={"size-5 animate-pulse rounded-full text-neutral"} />
              <span className="text-nowrap text-sm font-semibold">Parsing Email</span>
              <ProgressBarLoader loop={false} />
            </div>
            <div className={"flex items-center gap-x-2"}>
              <Icon type={"FillCheck"} className={"size-5 text-neutral-300"} />
              <span className="text-nowrap text-sm font-semibold">Ready</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card
      data-cy="dashboard-updates-item"
      className={cn(
        "group relative flex select-none flex-col items-start justify-between gap-4 gap-y-2 transition hover:scale-[1.01] md:flex-row md:items-center",
        className,
      )}
    >
      <Card.Body className={"grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2"}>
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          <EmailAuthorColumn
            author={author}
            label={title}
            styles={{
              labelStyle: "text-black text-sm font-semibold xl:min-w-[270px] line-clamp-1",
            }}
          />
          <DateColumn
            date={date}
            styles={{ container: "text-xs md:hidden min-w-[80px]", labelStyle: "w-full text-end" }}
          />
        </div>
        <DateColumn label="Date" date={date} styles={{ container: "hidden md:flex col-span-2", content: "h-full" }} />
        <DetailsColumn
          styles={{
            container: "col-span-1 md:col-span-3 xl:col-span-2",
            content: "flex items-center h-full",
            labelStyle: "mb-0",
          }}
        >
          {company !== null ? (
            <CompanyLink id={company.id} name={company.name} titleClassName="text-sm">
              <Card.Image src={company.image} className="mr-2" />
            </CompanyLink>
          ) : (
            <span
              onClick={!isCompleted ? handleOpen : undefined}
              className="cursor-pointer rounded-xs bg-red-100 px-1.5 py-1 text-sm text-red"
            >
              Company not linked
            </span>
          )}
        </DetailsColumn>

        {/*TODO: Commenting this out until adding an additional information from the server to only display Standard Metrics*/}
        <DetailsColumn
          label=""
          styles={{
            container: "col-span-1 md:col-span-3 xl:col-span-2",
            content: "",
          }}
        >
          {/*  <span>*/}
          {/*{type === "Metrics" && (*/}
          {/*  <span>*/}
          {/*    {company?.totalMetrics} Metrics <span className="mx-0.5 text-neutral-300">•</span>{" "}*/}
          {/*  </span>*/}
          {/*)}*/}
          {/*{notification.nzrOpportunityEmailAsReference?.attachments?.length || 0} Attachments*/}
          {/*  </span>*/}
        </DetailsColumn>
        <EmailOpportunityActions
          className="col-span-2 items-center md:col-span-3 md:w-auto md:justify-start xl:col-span-2 xl:justify-end"
          actions={{
            primary: {
              action: handleOpen,
              title: isCompleted ? "Viewed" : type === "Metrics" ? "Submit" : "Open",
            },
            secondary: !isCompleted
              ? {
                  action: company && type !== "Metrics" ? handleMarkAsDone : handleRemove,
                  title: company && type !== "Metrics" ? "Archive" : "Remove",
                  loading,
                }
              : undefined,
          }}
        />
      </Card.Body>
    </Card>
  );
};
