import { GraphQLErrors } from "@apollo/client/errors";
import { toast } from "react-hot-toast";

type GlobalErrorToast = {
  graphQLErrors?: GraphQLErrors;
};

export function globalErrorToast({ graphQLErrors }: GlobalErrorToast) {
  const err = graphQLErrors?.[0] as any;

  if (err?.extensions?.method && err?.extensions?.responseBody?.message) {
    toast.error(err?.extensions?.responseBody?.message);
    return;
  }

  toast.error("An error occurred. Please try again later.");
}
