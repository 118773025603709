import React, { useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { LgInvestor } from "@/gql/types.ts";

import { useBreakpoints } from "@/lib/hooks";

import { Card, ImageWithErrorFallback, Popover } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { TableLabel, TableText } from "@/app/screens/opportunities/opportunity/components/table-rows.tsx";
import { OpportunityTable } from "@/app/screens/opportunities/opportunity/components/table.tsx";

type BasecampData = {
  fund: string;
  entryRound?: string;
  entryDate?: string;
  teamOwners?: string; // todo check me. Not sure if needed
};

const useColumns = () => {
  const { isBigTablet } = useBreakpoints();

  const columns: ColumnDef<BasecampData>[] = useMemo(
    () => [
      {
        accessorKey: "fund",
        header: "Fund",
        size: isBigTablet ? 0 : 200,
        cell: (info) => <TableLabel label={info.renderValue()} />,
      },
      {
        accessorKey: "fundContact",
        header: "Fund Contact",
        size: isBigTablet ? 0 : 150,
        cell: (info) => <TableText text={info.renderValue()} />,
      },
      {
        accessorKey: "entryRound",
        header: "Entry Round",
        size: isBigTablet ? 0 : 150,
        cell: (info) => <TableLabel label={info.renderValue()} />,
      },
      {
        accessorKey: "entryDate",
        header: "Entry Date",
        size: isBigTablet ? 0 : 150,
        cell: (info) => <TableText text={info.renderValue()} />,
      },
      {
        accessorKey: "fundLead",
        header: "Fund Owner",
        size: isBigTablet ? 0 : 150,
        cell: (info) => {
          const person = info.getValue() as {
            firstName: string;
            lastName: string;
            avatar?: string;
          } | null;

          if(person == null) {
            return null;
          }

          const initials = [person.firstName, person.lastName]
            .filter(Boolean)
            .map((d) => d[0])
            .join("");

          return (
            <Popover
              childrenContent={
                <div className={"flex items-center space-x-2"}>
                  <ImageWithErrorFallback alt={initials} src={person?.avatar} className={"size-6 rounded-sm"} />
                  <span className="text-xs font-semibold text-black">{person.firstName}</span>
                </div>
              }
              placement={"bottom"}
            >
              <ImageWithErrorFallback alt={initials} src={person?.avatar} className={"size-6 rounded-xs"} />
            </Popover>
          );
        },
      },
    ],
    [isBigTablet],
  );

  return columns;
};

type BasecampInfoProps = {
  company: any;
  // todo
  // company: NonNullable<OpportunityCompany>;
};

export const BasecampInfo = ({ company }: BasecampInfoProps) => {
  const columns = useColumns();

  const investors: LgInvestor[] = useMemo(() => {
    const investorsMap: Record<string, LgInvestor> = {};

    company.fundingRounds?.items.forEach((fundingRound) => {
      fundingRound?.investors?.items.forEach(({ investor }) => {
        investorsMap[investor.id] = {
          ...investor,
          firstRound: fundingRound.round,
          firstRoundDate: `${fundingRound.year}-${fundingRound.month}`,
          fundLeadName: investor.fundLead ? `${investor.fundLead.firstName} ${investor.fundLead.lastName}` : null,
        };
      });
    });

    company?.investors?.items?.forEach((investor) => {
      if (!investorsMap[investor.id]) {
        investorsMap[investor.id] = {
          ...investor,
          fundLeadName: investor.fundLead ? `${investor.fundLead.firstName} ${investor.fundLead.lastName}` : null,
        };
      }
    });

    return Object.values(investorsMap).filter((investor) => investor.isBasecamp);
  }, [company?.fundingRounds]);

  const data: BasecampData[] = investors.map((investor: LgInvestor & any) => ({
    fund: investor.name,
    fundContact: null,
    entryRound: investor?.firstRound,
    entryDate: normaliseDate(investor?.firstRoundDate)?.split(" ")?.slice(1)?.join(" "),
    fundLead: investor?.fundLead,
  }));

  return (
    investors.length > 0 && (
      <Card className="p-0">
        <OpportunityTable data={data} columns={columns} containerClassName="p-4 md:p-5" />
      </Card>
    )
  );
};
