import { ColumnDefTemplate, HeaderContext } from "@tanstack/table-core";

import { cn } from "@/lib/utils.ts";

import { TableCellTotals } from "@/app/components/table-new/components/table-cell-total.tsx";
import { HeaderSortIndicator } from "@/app/components/table-new/components/table-header-cell.tsx";
import { RowWithCurrency } from "@/app/screens/opportunities/opportunity/components";

type PCGOverviewColumn = {
  fund: string;
  type: string;
  initialInvestment: string;
  totalInvested: string;
  unrealisedNav: string;
  vc: string;
  currentOwned: string;
  currentEv: string;
  mtpEv: string;
  currentNavPercentage: string;
  revenue: string;
  margin: string;
};

type HeaderWithTotalsProps = Pick<HeaderContext<PCGOverviewColumn, unknown>, "header" | "table"> & {
  text: string;
};

export const HeaderWithTotals: ColumnDefTemplate<HeaderWithTotalsProps> = ({
  header,
  table,
  text,
}: HeaderWithTotalsProps) => {
  const totals = (table.options.meta as { totals?: any }).totals ?? {};
  return (
    <div
      className={cn(
        "flex size-full cursor-pointer select-none flex-col items-center justify-between text-start font-medium text-neutral",
      )}
    >
      {!!totals[header.column.id] && (
        <TableCellTotals
          label={"TOTAL"}
          value={
            <RowWithCurrency
              label={totals[header.column.id]}
              labelClassName={"!text-neutral text-lg leading-6"}
              containerClassName={"flex justify-center items-center"}
            />
          }
        />
      )}
      <div className="mt-auto flex items-center py-2.5 text-center">
        {text}
        {header.column.getCanSort() && <HeaderSortIndicator isSorted={header.column.getIsSorted()} />}
      </div>
    </div>
  );
};
