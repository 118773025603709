import { useState } from "react";

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { CheckIcon, XCircle } from "lucide-react";
import Sheet from "react-modal-sheet";

import { cn, getInitials } from "@/lib/utils.ts";

import { Button, Icon, ImageWithErrorProfile, NativeModal } from "@/app/components";
import { InputWithPills } from "@/app/screens/modal/components";

export type OptionsType = {
  id?: string;
  value: string | number | Record<string, string | number>;
  label: string;
  image?: string | null;
  disabled?: string;
};

export type MobileMultiSelectProps = {
  options: Array<OptionsType>;
  defaultSelected?: Array<OptionsType>;
  showIcon?: boolean;
  noSearch?: boolean;
  autoFocus?: boolean;
  label?: string;
  multiSelect?: boolean;
  onChange?: (value: Array<OptionsType>) => void;
  goBack: () => void;
  handleClose: () => void;
};

export const MobileSelect = ({
  defaultSelected = [],
  options = [],
  autoFocus = false,
  showIcon = false,
  noSearch = false,
  label,
  multiSelect,
  onChange,
  goBack,
  handleClose,
}: MobileMultiSelectProps) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const [query, setQuery] = useState("");

  const handleValueChange = (ops: OptionsType[]) => {
    if (onChange) {
      onChange(ops);
    }
  };

  const handleChange = (ops: OptionsType) => {
    setQuery("");
    setSelectedOption([...selectedOption, ops]);

    if (multiSelect) return;

    handleValueChange([ops]);
    goBack();
  };

  const handleRemove = (op: OptionsType) => {
    const updated = selectedOption.filter(({ value }) => value !== op.value);
    setSelectedOption(updated);
  };

  const handleConfirm = () => {
    handleValueChange(selectedOption);
    goBack();
  };

  const handleCancel = () => {
    goBack();
  };

  const filteredOptions =
    query === "" ? options : options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));

  return (
    <NativeModal
      isOpen={true}
      handleClose={handleClose}
      header={
        <Sheet.Header className={"mb-6 mt-5 flex items-center px-4"}>
          <Icon type={"Chevron Left"} height={25} width={25} onClick={handleCancel} />
          <span className={"px-4 text-lg font-semibold"}>{label}</span>
        </Sheet.Header>
      }
      footer={
        multiSelect && (
          <div className="z-10 flex h-fit w-full flex-row items-center justify-between p-2 shadow-mobile-xs">
            <Button
              text={"Select"}
              variant={"secondary"}
              onClick={handleConfirm}
              className={"mx-2 w-full"}
              data-cy="confirm-select"
            />
          </div>
        )
      }
    >
      <Combobox as="div" className={"flex flex-col overflow-x-clip"} multiple={multiSelect} value={selectedOption}>
        {!noSearch && (
          <div className={`sticky top-0 z-10 bg-white pb-3 pt-2`}>
            <InputWithPills
              iconLeft={"Search"}
              inputFocus={inputFocus}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption as any}
              containerClassName={`py-0.5`}
            >
              <ComboboxInput
                autoFocus={autoFocus}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                value={query}
                className={`mx-2`}
                onChange={(event) => setQuery(event.target.value)}
              />
              <ComboboxButton
                onClick={handleCancel}
                className="absolute right-4 flex items-center rounded-r-md focus:outline-none"
              >
                <XCircle className={`size-5 ${inputFocus ? "text-primary" : "text-gray-400"}`} aria-hidden="true" />
              </ComboboxButton>
            </InputWithPills>
          </div>
        )}

        <ComboboxOptions
          static={true}
          className="mt-3 w-full divide-y divide-neutral-200 overflow-hidden bg-white py-1 text-base focus:outline-none"
        >
          {filteredOptions?.map((filteredOption) => (
            <ComboboxOption
              data-cy="select-option"
              key={filteredOption.label}
              value={filteredOption}
              className={({ active }) => cn("relative cursor-pointer select-none", active ? "bg-neutral-100" : "")}
            >
              {({ selected }) => (
                <div
                  className={"flex size-full items-center px-4 py-6 pr-9"}
                  onClick={() => (selected ? handleRemove(filteredOption) : handleChange(filteredOption))}
                >
                  {showIcon && (
                    <ImageWithErrorProfile
                      src={filteredOption.image}
                      alt={getInitials(filteredOption.label)}
                      className={`mr-2 flex size-8 items-center justify-center rounded-full ${
                        selected ? "bg-primary-100 text-neutral-800" : "bg-neutral-200 text-neutral-400"
                      }`}
                    />
                  )}
                  <span className={cn("block truncate", selected ? "text-primary" : "text-neutral-700")}>
                    {filteredOption.label}
                  </span>

                  {selected && (
                    <span className={cn("absolute inset-y-0 right-0 flex items-center pr-4 text-primary")}>
                      <CheckIcon className="rounded-xl bg-primary p-1 text-white" size={24} aria-hidden="true" />
                    </span>
                  )}
                </div>
              )}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </NativeModal>
  );
};
