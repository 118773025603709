import { useMemo } from "react";

import { TableNew } from "@/app/components/table-new";
import { TableCellTotals } from "@/app/components/table-new/components/table-cell-total.tsx";
import { DefaultHeaderCell } from "@/app/components/table-new/components/table-header-cell.tsx";
import { useTable } from "@/app/components/table-new/table.hooks.ts";

import { mapInvestmentColumnToValue } from "../utils.tsx";

// this is a quick hack for custom filters. refactor when there's more time
const useTotals = (aggregated, data) => {
  return useMemo(() => {
    return {
      totalInvestedCapital: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.totalInvestedCapital / data.length,
                  column: "totalInvestedCapital",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
          <TableCellTotals
            label={"TOTAL"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.totalInvestedCapital,
                  column: "totalInvestedCapital",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
      unrealisedValue: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.unrealisedValue / data.length,
                  column: "unrealisedValue",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
          <TableCellTotals
            label={"TOTAL"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.unrealisedValue,
                  column: "unrealisedValue",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
      currentEv: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.currentEv / data.length,
                  column: "currentEv",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
          <TableCellTotals
            label={"TOTAL"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.currentEv,
                  column: "currentEv",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
      goingInOwnership: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.goingInOwnership / data.length,
                  column: "goingInOwnership",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
      currentOwned: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.currentOwnership / data.length,
                  column: "currentOwned",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
      initialEv: (
        <div className="w-full space-y-1.5 text-center">
          <TableCellTotals
            label={"AVERAGE"}
            value={
              <span className="text-sm font-semibold">
                {mapInvestmentColumnToValue({
                  value: aggregated.goingInPostMoney / data.length,
                  column: "initialEv",
                  className: "text-neutral-400",
                })}
              </span>
            }
          />
        </div>
      ),
    };
  }, [aggregated, data]);
};

// could be also done using header groups
export const FundsTableHeader = ({ aggregated, data }) => {
  const { table } = useTable();

  const totals = useTotals(aggregated, data);

  return (
    <>
      {table?.getHeaderGroups().map((headerGroup) => (
        <TableNew.Row key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <DefaultHeaderCell key={header.id} header={header}>
              {totals[header.id]}
            </DefaultHeaderCell>
          ))}
        </TableNew.Row>
      ))}
    </>
  );
};
