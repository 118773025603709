import { useNavigate } from "react-router";

import { Button, Lottie } from "@/app/components";

export const NotAvailableOnMobile = () => {
  const navigate = useNavigate();
  return (
    <div className="flex min-h-dvh flex-col items-center justify-center bg-white px-6 pb-[15%] text-center">
      <Lottie type={"404"} />
      <p className={"mb-14 text-2xl font-bold"}>This page is not available for mobile yet.</p>

      <div className="flex items-center justify-center gap-x-6">
        <Button text={"Go back"} onClick={() => navigate(-1)} />
      </div>
    </div>
  );
};
