import { NetworkDashboardComing } from "./dashboardComing";
import { NetworkContactLookup } from "./contactLookup";
import { observer } from "mobx-react";
import { UserRoleType } from "@/gql/graphql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";


// TODO: get rid of observer after auth/user refactor
export const NetworkDashboard = observer(function NetworkDashboard() {
  const { userRoles: myRoles } = authStore;

  // not loaded yet
  if (myRoles === null) return null;

  if (myRoles?.includes(UserRoleType.UserRoleNetwork)) {
    return <NetworkContactLookup />;
  }

  return <NetworkDashboardComing />;
});

