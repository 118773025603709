import {
  GetDashboardCompaniesDocument,
  GetSentimentFormRequestsDocument,
  GetUserHighlightsDocument,
} from "@/gql/graphql.ts";

import { cn, getFullName } from "@/lib/utils.ts";

import { Button, Card } from "@/app/components";
import { CompleteSentimentModalResponsive } from "@/app/screens/modal/index.ts";
import { useDeclineSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";
import refetchStore from "@/app/stores/refetch-store.ts";

import { CompanyLink } from "../../components/company-link.tsx";
import { DateColumn } from "../../components/email-opportunity/date-column.tsx";
import { DetailsColumn } from "../../components/email-opportunity/details-column.tsx";
import { UserImage } from "../../components/user-image.tsx";

import { SentimentParticipants } from "./sentiment-participants.tsx";
import { SentimentFormData } from "./use-sentiment-form-requests.ts";

export const SentimentRequestItem = ({
  data,
  archived,
  className,
}: {
  data: SentimentFormData;
  archived: boolean;
  className?: string;
}) => {
  const [declineSentimentForm, { loading: declineLoading }] = useDeclineSentimentFormMutation();

  const handleComplete = () => {
    modalStore.open(CompleteSentimentModalResponsive, {
      props: {
        label: "Complete Sentiment",
        id: data.id,
        notificationId: data.nzrNotifications.nodes[0]?.id,
      },
    });
  };

  const handleDecline = () => {
    const refetchQueries = refetchStore.getRefetchQueries([
      GetDashboardCompaniesDocument,
      GetUserHighlightsDocument,
      GetSentimentFormRequestsDocument,
    ]);

    declineSentimentForm({
      variables: { input: { formId: data.id } },
      refetchQueries,
    });
  };

  return (
    <Card
      data-cy="dashboard-sentiments-item"
      className={cn(
        "group relative flex select-none flex-col items-center gap-y-2 transition hover:scale-[1.01]",
        className,
      )}
    >
      <Card.Body className="grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          {data.company ? (
            <CompanyLink
              id={data.company.id}
              name={data.type === "founder" ? `${data.founder?.name} (${data.company.name})` : data.company.name}
              subTitle={`${data.type} • ${data.formStatus}`}
            >
              <Card.Image src={data.company.image} className="mr-2 min-h-10 min-w-10 rounded-sm" />
            </CompanyLink>
          ) : (
            "N/A"
          )}
          <DateColumn date={data.createdAt} styles={{ container: "text-xs md:hidden" }} />
        </div>
        <DateColumn
          date={data.createdAt}
          styles={{ container: "hidden md:flex col-span-2", content: "h-full" }}
          label="Date"
        />
        <div className="md:col-span-3 lg:col-span-3 xl:col-span-2">
          <DetailsColumn label="Requested by">
            {data.createdBy ? (
              <UserImage user={data.createdBy}>
                <span className="ml-2">{getFullName(data.createdBy?.firstName, data.createdBy?.lastName)}</span>
              </UserImage>
            ) : (
              "N/A"
            )}
          </DetailsColumn>
        </div>
        <div className="md:col-span-3 lg:col-span-3 xl:col-span-2">
          <DetailsColumn
            styles={{
              container: "min-w-[100px] md:w-auto overflow-hidden",
              content: "w-full overflow-hidden",
            }}
            label="Participants"
          >
            <SentimentParticipants participants={data.nzrSentimentFormAssignees.nodes} />
          </DetailsColumn>
        </div>
        {!archived && (
          <div className="col-span-2 flex items-center gap-2 md:col-span-3 md:mt-0 xl:col-span-2 xl:justify-end">
            <Button
              size="sm"
              variant="primary"
              className="w-full md:w-auto"
              loading={declineLoading}
              onClick={handleDecline}
            >
              Decline
            </Button>
            <Button size="sm" variant="secondary" className="w-full md:w-auto" onClick={handleComplete}>
              Complete
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
