import { cn } from "@/lib/utils";

const btnClass = "block rounded-xs px-3 py-1.5 text-sm cursor-pointer";

const incativeClass = "bg-neutral-300 hover:bg-neutral-400";
const activeClass = "bg-neutral-200 hover:bg-neutral-300";

export const HistoryToggle = ({ value, setValue, activeLabel = "New", inActiveLabel = "Archived" }) => {
  return (
    <div className="ml-auto flex select-none items-center gap-1">
      <span className={cn(incativeClass, btnClass, value && activeClass)} onClick={() => setValue(false)}>
        {activeLabel}
      </span>
      <span className={cn(activeClass, btnClass, value && incativeClass)} onClick={() => setValue(true)}>
        {inActiveLabel}
      </span>
    </div>
  );
};
