import { useEffect } from "react";

import { observer } from "mobx-react";

import { Lottie } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

export const SuccessModal = observer(() => {
  const {
    navState: { label, lottie },
  } = globalModalStore.state;

  useEffect(() => {
    setTimeout(() => {
      globalModalStore.toggleModal();
    }, 2500);
  }, []);

  return (
    <div className={"flex flex-1 flex-col items-center justify-center "}>
      <Lottie type={lottie || "completed"} className={"size-52"} />
      <p className={"text-2xl font-semibold text-primary lg:mt-10"}>{label || "Success"}</p>
    </div>
  );
});
