import { useCallback, useMemo, useState } from "react";

import toast from "react-hot-toast";

import { GetSentimentFormRequestsDocument, GetUserHighlightsDocument } from "@/gql/graphql";

import { Button } from "@/app/components";
import { Company, useForm } from "@/app/hooks";
import { useRequestSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";
import refetchStore from "@/app/stores/refetch-store";

import { CompleteSentimentModalResponsive } from "../../../complete-sentiment";
import { SearchCompany, SentimentForm } from "../components";

export interface AddSentimentProps {
  type: "sentiment" | "founderSentiment";
  founderId?: string;
  company?: Company;
}

export enum FormStep {
  SEARCH = "SEARCH",
  SUBMIT = "SUBMIT",
}

export const useRequestSentiment = ({ type, company: defaultCompany, founderId }: AddSentimentProps) => {
  const form = useForm("SentimentRequest");
  const [step, setStep] = useState<FormStep>(defaultCompany ? FormStep.SUBMIT : FormStep.SEARCH);
  const [company, setCompany] = useState<Company | undefined>(defaultCompany);
  const [requestSentiment, { loading }] = useRequestSentimentFormMutation();

  const primaryAction = useCallback(async () => {
    if (!company) return;

    if (step === FormStep.SUBMIT && company) {
      const { assignees, meetingDate, category, founder: formFounder } = form.values();
      const founder = formFounder
        ? {
            id: formFounder.id,
            name: formFounder.value,
            image: formFounder.image,
          }
        : {};

      const refetchQueries = refetchStore.getRefetchQueries([
        GetSentimentFormRequestsDocument,
        GetUserHighlightsDocument,
      ]);

      await requestSentiment({
        variables: {
          input: {
            founder,
            company: { id: company.id, name: company.name },
            assignees: Array.from(assignees).map((item: any) => item?.value),
            meetingDate: meetingDate,
            category: category?.value,
          },
        },
        refetchQueries,
        onCompleted: (data) => {
          const formId = data.sentimentRequest.id;

          modalStore.close();
          toast((t) => (
            <div data-cy="complete-sentiment-toast" className="flex flex-col items-center gap-2">
              <span className="my-3 text-center">
                Successfully requested <b>{category?.value}</b> sentiment for <b>{company.name}</b>.
              </span>
              <Button
                variant={"gradient"}
                text={"Complete Now"}
                onClick={() => {
                  toast.dismiss(t.id);
                  modalStore.open(CompleteSentimentModalResponsive, {
                    props: { id: formId },
                  });
                }}
              />
            </div>
          ));
        },
      });
    }

    if (step === FormStep.SEARCH) setStep(FormStep.SUBMIT);
  }, [company, step, form, requestSentiment]);

  const secondaryAction = useCallback(() => {
    if (step === FormStep.SEARCH) {
      modalStore.goBack();
    } else {
      setStep(FormStep.SEARCH);
    }
  }, [step]);

  const isValid = useMemo(() => {
    if (company && step === FormStep.SEARCH) return true;

    const isValid = form.$("assignees").value && form.$("meetingDate").value && form.$("category").value;

    if (type === "founderSentiment") return Boolean(form.$("founder").value && isValid);

    return !!isValid;
  }, [type, company, step, form, form.$changed]);

  const content = useMemo(() => {
    if (company && step === FormStep.SUBMIT) {
      return <SentimentForm company={company} type={type} form={form} founderId={founderId} />;
    }

    return (
      <div className="flex size-full flex-col">
        <SearchCompany selectCompany={(comp) => setCompany(comp)} />
      </div>
    );
  }, [company, step, type, form, founderId]);

  return {
    content,
    isValid,
    step,
    loading,
    primaryAction,
    secondaryAction,
  };
};
