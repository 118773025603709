import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LegendItem,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, { useMemo, useState } from "react";
import "chart.js/auto";
import { cn } from "@/lib/utils.ts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const scales = {
  employee: {
    type: "linear" as const,
    display: true,
    position: "left" as const,
  },
  funding_round: {
    type: "linear" as const,
    display: true,
    position: "right" as const,
    grid: {
      drawOnChartArea: false,
    },
  },
  website: {
    type: "linear" as const,
    display: true,
    position: "left" as const,
    grid: {
      drawOnChartArea: false,
    },
  },
};

export const getOptions = (showColumns) => ({
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  animation: false,
  stacked: true,
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
    htmlLegend: {
      containerID: "legend-container",
    },
    legend: {
      display: false,
    },
  },
  spanGaps: true,
  scales: Object.fromEntries(
    showColumns.map((column) => [column, scales[column]])
  ),
});

const useLegend = () => {
  const [items, setItems] = useState<(LegendItem & { onClick: () => void })[]>(
    []
  );

  const htmlLegendPlugin: Plugin<"line"> = useMemo(() => {
    return {
      id: "htmlLegend",
      afterUpdate(chart: ChartJS<"line">) {
        const items =
          chart?.legend?.legendItems?.map((item) => ({
            ...item,
            onClick: () => {
              if (item.datasetIndex !== undefined) {
                chart.setDatasetVisibility(
                  item.datasetIndex,
                  !chart.isDatasetVisible(item.datasetIndex)
                );
                chart.update();
              }
            },
          })) ?? [];

        setItems(items);
      },
    };
  }, []);

  return { items, htmlLegendPlugin };
};

export function CompanyHistory({ options, labels, datasets }) {
  const { items, htmlLegendPlugin } = useLegend();

  return (
    <div className="max-h-[500px] flex-1 pb-10">
      <div id="legend-container" className="flex items-center gap-3">
        <span className="hidden font-semibold text-neutral-600 md:block">
          Show:
        </span>
        <ul className="flex gap-3">
          {items.map((item) => (
            <li
              key={item.text}
              className={cn(
                "flex h-9 items-center rounded-sm px-2 text-sm font-medium transition-opacity cursor-pointer hover:brightness-90 active:brightness-75",
                item.hidden && "opacity-50"
              )}
              style={{ backgroundColor: item.fillStyle as string }}
              onClick={item.onClick}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
      <Chart
        className="mt-5"
        type="line"
        options={options}
        data={{
          labels,
          datasets,
        }}
        plugins={[htmlLegendPlugin]}
      />
    </div>
  );
}
