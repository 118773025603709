import { cn } from "@/lib/utils.ts";

import { text } from "@/app/styles";

export const FormDetails = ({ noOfParticipants, totalSentimentForms }) => {
  return (
    <div className="">
      <div className={"flex border-t py-2"}>
        <span className={cn("text-sm font-semibold", text.subTitleText)}>Number of Participants</span>
        <span className={cn(`ml-4 text-lg font-semibold text-primary`, text.titleText)}>
          {String(noOfParticipants)}
        </span>
      </div>
      <div className={"flex border-y py-2"}>
        <span className={cn("text-sm font-semibold", text.subTitleText)}>Sentiment Forms</span>
        <span className={cn(`ml-4 text-lg font-semibold text-primary`, text.titleText)}>
          {String(totalSentimentForms)}
        </span>
      </div>
    </div>
  );
};
