import { forwardRef } from "react";

import { Input, InputProps } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "../icon";

export interface InputV2Props extends InputProps {
  iconLeft?: IconType;
  iconRight?: IconType;
  containerClassName?: string;
}

export const InputV2 = forwardRef<HTMLInputElement, InputV2Props>(function InputV2(props, ref) {
  const { iconLeft, iconRight, containerClassName, value, ...inputProps } = props;

  return (
    <div className={cn("flex w-full items-center overflow-hidden rounded-sm border bg-white px-4", containerClassName)}>
      {iconLeft && (
        <Icon
          aria-hidden="true"
          type={iconLeft}
          className={cn("mr-2 size-4", value ? "text-neutral-900" : "text-neutral-400")}
        />
      )}
      <Input
        ref={ref}
        {...inputProps}
        value={value}
        className={cn("flex-1 bg-inherit py-3 text-sm text-neutral-900", props.className)}
      />
      {iconRight && (
        <Icon
          aria-hidden="true"
          type={iconRight}
          className={cn("ml-2 size-4", value ? "text-neutral-900" : "text-neutral-400")}
        />
      )}
    </div>
  );
});
