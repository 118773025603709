import { useState } from "react";

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOptions, ComboboxOption } from "@headlessui/react";
import { CheckIcon, XCircle } from "lucide-react";
import { observer } from "mobx-react";
import Sheet from "react-modal-sheet";

import { cn, getInitials } from "@/lib/utils.ts";

import { Button, Icon, ImageWithErrorProfile, IosTransitionEnter } from "@/app/components";
import { CloseModal, InputWithPills } from "@/app/screens/modal/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";

type MobileMultiSelectProps = {
  getOptions: (fieldId?: string) => { data: Array<OptionsType> };
  defaultOptions?: Array<OptionsType>;
  options?: Array<OptionsType>;
  showIcon?: boolean;
  noSearch?: boolean;
  autoFocus?: boolean;
};
export const MobileSelect = observer(
  ({
    getOptions,
    defaultOptions = [],
    options,
    autoFocus = false,
    showIcon = false,
    noSearch = false,
  }: MobileMultiSelectProps) => {
    const [inputFocus, setInputFocus] = useState(false);
    const [query, setQuery] = useState("");
    let allOptions: OptionsType[] = [];

    const {
      navState: { label, form, fieldId, withButtons, multiSelect },
    } = globalModalStore.state;

    if (options) {
      allOptions = options;
    } else {
      const { data } = getOptions(fieldId);
      allOptions = data;
    }

    const field = form.$(fieldId);

    const selectedOption = field.value || defaultOptions || [];

    const setSelectedOption = (value) => {
      field.value = value;
    };

    const filteredOptions =
      query === ""
        ? allOptions
        : allOptions.filter((person) => person.label.toLowerCase().includes(query.toLowerCase()));

    return (
      <IosTransitionEnter type={"enter"}>
        <CloseModal />
        <div className={"flex h-full flex-col"}>
          <Sheet.Header className={"mb-6 mt-5 flex items-center px-4"}>
            <Icon
              type={"Chevron Left"}
              height={25}
              width={25}
              onClick={() => {
                if (withButtons?.onClick) {
                  withButtons.onClick();
                  return;
                }
                globalModalStore.goBack();
              }}
            />
            <span className={"px-4 text-lg font-semibold"}>{label}</span>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <Combobox
                as="div"
                by="label"
                className={"flex size-full flex-col"}
                multiple={Boolean(multiSelect) as any}
                value={selectedOption}
                onChange={(value) => {
                  setQuery("");
                  field.value = value;
                }}
              >
                {!noSearch && (
                  <div className={`sticky top-0 z-10 w-[95dvw] bg-white pb-3 pt-2`}>
                    <InputWithPills
                      iconLeft={"Search"}
                      inputFocus={inputFocus}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption as any}
                      containerClassName={`mx-4 py-0.5`}
                    >
                      <ComboboxInput
                        autoFocus={autoFocus}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        value={query}
                        className={`mx-2`}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <ComboboxButton
                        onClick={() => {
                          if (query) {
                            setQuery("");
                            return;
                          }
                          field.value = multiSelect ? [] : undefined;
                        }}
                        className="absolute right-0 flex items-center rounded-r-md focus:outline-none"
                      >
                        <XCircle
                          className={`h-5 w-5 ${inputFocus ? "text-primary" : "text-gray-400"}`}
                          aria-hidden="true"
                        />
                      </ComboboxButton>
                    </InputWithPills>
                  </div>
                )}

                <ComboboxOptions
                  static={true}
                  className="mt-3 w-full divide-y divide-neutral-200 overflow-auto bg-white py-1 text-base focus:outline-none"
                >
                  {filteredOptions?.map((filteredOption) => (
                    <ComboboxOption
                      key={filteredOption.label}
                      value={filteredOption}
                      className={({ active }) =>
                        cn("relative cursor-pointer select-none px-4 py-6 pr-9", active ? "bg-neutral-100" : "")
                      }
                    >
                      {({ selected }) => (
                        <div className={"flex items-center"}>
                          {showIcon && (
                            <ImageWithErrorProfile
                              src={filteredOption.image}
                              alt={getInitials(filteredOption.label)}
                              className={`mr-2 flex h-8 w-8 items-center justify-center rounded-full ${
                                selected ? "bg-primary-100 text-neutral-800" : "bg-neutral-200 text-neutral-400"
                              }`}
                            />
                          )}
                          <span className={cn("block truncate", selected ? "text-primary" : "text-neutral-700")}>
                            {filteredOption.label}
                          </span>

                          {selected && (
                            <span className={cn("absolute inset-y-0 right-0 flex items-center pr-4 text-primary")}>
                              <CheckIcon
                                className="rounded-xl bg-primary p-1 text-white"
                                size={24}
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </Combobox>
            </Sheet.Scroller>
          </Sheet.Content>
          {withButtons && (
            <div className="z-10 flex h-fit w-full flex-row items-center justify-between p-2 shadow-mobile-xs">
              <Button
                text={
                  "Select " +
                    withButtons?.buttonText
                      .split("_")
                      .map((e: string) => e[0].toUpperCase() + e.slice(1))
                      .join(" ") || "Done"
                }
                variant="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  if (withButtons.onClick) withButtons.onClick();
                }}
                iconLeft={withButtons?.iconRight}
                className={"mx-2 w-full"}
              />
            </div>
          )}
        </div>
      </IosTransitionEnter>
    );
  },
);
