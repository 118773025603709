import { WatchQueryFetchPolicy } from "@apollo/client";

import { GetAssignedCompaniesDocument, LgCompanyFilter } from "@/gql/graphql.ts";

import { usePaginatedQuery } from "@/app/hooks";
import { authStore } from "@/app/stores/auth.store.tsx";

import { DashboardCompany, DashboardSectionData } from "../dashboard.types";

export const useAssigneeCompanies = (
  filter: LgCompanyFilter = {},
  fetchPolicy: WatchQueryFetchPolicy = "cache-and-network",
): DashboardSectionData<DashboardCompany> => {
  const queryVariables = {
    filter: {
      memberId: { equalTo: authStore.userId },
      company: {
        nzrUserCompanyTagsByCompanyId: {
          none: {
            userId: { equalTo: authStore.userId },
            tags: {
              contains: {
                hide: "1",
              },
            },
          },
        },
        ...filter,
      },
    },
    pageSize: 6,
    after: null,
  };

  const {
    data,
    loading,
    loadLess,
    loadMore: refetch,
    loadingLess,
    loadingMore,
  } = usePaginatedQuery({
    query: GetAssignedCompaniesDocument,
    rootEntity: "lgAssignees",
    variables: queryVariables,
    options: { fetchPolicy },
  });

  const loadMore = () => {
    const endCursor = data?.lgAssignees?.pageInfo.endCursor;

    if (refetch) {
      refetch(queryVariables, endCursor);
    }
  };

  if (!data?.lgAssignees) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingMore: false,
      loadingLess: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.lgAssignees.totalCount,
    items: data.lgAssignees.edges.reduce<Array<DashboardCompany>>(
      (accumulator, { node }) => (node.company ? [...accumulator, node.company] : accumulator),
      [],
    ),
    pageInfo: data.lgAssignees.pageInfo,
    loading: loading && !loadingLess,
    loadingMore,
    loadingLess,
    loadLess,
    loadMore,
  };
};
