import { cn, reduceSignalsToFormattedText } from "@/lib/utils";

import { Icon, Popover } from "@/app/components";
import { Signal } from "@/app/types";

export interface SignalProps {
  signals: Signal[];
  iconClassName?: string;
}

export const Signals = ({ signals = [], iconClassName }: SignalProps) => {
  const formattedText = reduceSignalsToFormattedText(signals);
  return (
    <Popover
      containerClassName="outline-none"
      childrenClassName="bg-gradient-to-r from-primary-400 to-secondary-400 text-white border-none shadow-md"
      directionClassName="bg-primary-400 border-none"
      placement="right"
      childrenContent={
        formattedText ? (
          <p
            className="max-w-[350px] px-0.5 text-xs font-medium"
            dangerouslySetInnerHTML={{
              __html:
                String(formattedText)
                  ?.replaceAll("<b>", '<b class="font-semibold">')
                  ?.replaceAll("<br/>", ' <b class="font-semibold">•</b> ') || "",
            }}
          />
        ) : null
      }
    >
      <Icon type="SignalGradient" className={cn("md:h-5 lg:h-3 2xl:h-4", iconClassName)} />
    </Popover>
  );
};
