import { useState } from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils";

import { Button } from "@/app/components";

import { ConfirmModalActionHandlers, ConfirmModalActionState } from "../types";

export type ConfirmModalActionProps = ConfirmModalActionHandlers & ConfirmModalActionState;

export const ConfirmModalActionButtons = ({
  disabled,
  primaryBtnTitle = "Yes, I'm sure",
  secondaryBtnTitle = "Cancel",
  handleCancel,
  handleConfirm,
}: ConfirmModalActionProps) => {
  const [loading, toggleLoading] = useState(false);

  const { isBigTablet } = useBreakpoints();

  const onConfirm = async () => {
    try {
      toggleLoading(true);
      await handleConfirm();
    } finally {
      toggleLoading(false);
    }
  };
  return (
    <div className={cn("mt-4 flex justify-center", isBigTablet ? "flex-row" : "flex-col")}>
      <Button
        text={secondaryBtnTitle}
        variant={"outline"}
        className="w-full lg:mb-0 lg:mr-4"
        size={isBigTablet ? "md" : "lg"}
        data-cy="cancel-modal"
        onClick={handleCancel}
      />
      <Button
        text={primaryBtnTitle}
        variant={"gradient"}
        disabled={disabled}
        loading={loading}
        className="order-first mb-4 w-full lg:order-last lg:mb-0"
        size={isBigTablet ? "md" : "lg"}
        data-cy="confirm-modal"
        onClick={onConfirm}
      />
    </div>
  );
};
