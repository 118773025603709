import { initApollo } from "./apollo-client.ts";
import { configureMobxPersistable } from "./mobx.ts";
import { initPosthog } from "./posthog.ts";
import { initSentry } from "./sentry.ts";

// todo should be moved inside initConfig
export const apolloClient = initApollo();

export function initConfig() {
  configureMobxPersistable();
  initSentry();
  initPosthog();

  return { apolloClient };
}
