import React from "react";

import { getInitials } from "@/lib/utils.ts";

import { CompanyImg, Icon, ImageWithErrorProfile, ResponsiveHorizontalList } from "@/app/components";
import { CompanyDataSchema } from "@/app/screens/add-company/company-data-step/company-data-form.ts";

type DealroomCompanyData = NonNullable<CompanyDataSchema["dealroomCompany"]>;
type CompanyMnEntryData = Pick<CompanyDataSchema, "name" | "websiteUrl" | "founders">;

type SelectedDealroomCompanyData = CompanyMnEntryData | DealroomCompanyData;

const isDealroomCompany = (companyData: SelectedDealroomCompanyData): companyData is DealroomCompanyData => {
  return "id" in companyData;
};

type FounderData = {
  name?: string | null;
  image?: string | null;
  linkedinUrl?: string | null;
};

export const CompanyDetailsPreview = ({ company }: { company: SelectedDealroomCompanyData }) => {
  const isDealroomData = isDealroomCompany(company);

  const founders: FounderData[] =
    (isDealroomData
      ? company.founders?.nodes
      : company.founders?.map((founder) => {
          return {
            name: founder.dealroomFounder?.name ?? founder.lgFounder?.name ?? founder.details?.name,
            linkedinUrl: founder.dealroomFounder?.linkedinUrl ?? founder.lgFounder?.linkedinUrl ?? founder.details?.url,
          };
        })) ?? [];

  return (
    <div className="flex gap-2">
      {isDealroomData && <CompanyImg src={company.image} className="size-12 shrink-0" />}
      <div className="flex w-full flex-col overflow-hidden">
        <div className="flex items-center gap-1">
          <span>{company.name || "Company without a name"}</span>
          {company.websiteUrl && (
            <a href={company.websiteUrl} target="_blank" className="text-sm" rel="noreferrer">
              <Icon type="Continent" className="size-4" />
            </a>
          )}
        </div>
        {isDealroomData && <span className="text-sm text-gray-700">{company.tagline}</span>}

        {!!founders?.length && (
          <div className="mt-5 w-full">
            <span className="font-semibold">Founders</span>
            <ResponsiveHorizontalList className="mt-3 gap-4" itemProps={{ className: "overflow-hidden shrink-0" }}>
              {founders.map((founder) => (
                <div key={`${founder.name}-${founder.linkedinUrl}`} className="flex items-center gap-2">
                  <ImageWithErrorProfile
                    labelClassName={"text-gray-800 text-lg overflow-hidden text-nowrap"}
                    src={founder.image ? "https://" + founder.image : null}
                    alt={founder.name ? getInitials(founder.name) : "N/A"}
                    className="size-8 rounded-sm bg-gray-200"
                  />
                  <span className="text-nowrap text-sm">{founder.name}</span>
                </div>
              ))}
            </ResponsiveHorizontalList>
          </div>
        )}
      </div>
    </div>
  );
};
