import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils";

import { Button } from "@/app/components";

export interface ActionButtonProps {
  disabled?: boolean;
  actions: {
    handlePrimaryAction?: () => void;
    handleCancel?: () => void;
  };
}

export const ActionButtons = ({ disabled, actions }: ActionButtonProps) => {
  const { handlePrimaryAction, handleCancel } = actions;
  const { isBigTablet } = useBreakpoints();

  return (
    <div className={cn("mt-auto flex w-full flex-col items-center justify-center lg:flex-row lg:justify-end")}>
      <Button
        text={"Cancel"}
        variant={"outline"}
        className="mb-4 w-full text-black lg:mb-0 lg:mr-4 lg:w-auto"
        size={isBigTablet ? "md" : "lg"}
        onClick={handleCancel}
      />
      <Button
        text={"Next"}
        disabled={disabled}
        variant={"gradient"}
        className="order-first mb-4 w-full lg:order-last lg:mb-0 lg:w-auto"
        size={isBigTablet ? "md" : "lg"}
        onClick={handlePrimaryAction}
      />
    </div>
  );
};
