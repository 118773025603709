import { Button, Icon, IosTransitionEnter } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

import { opportunityFilterStore } from "@/app/screens/opportunities";
import { observer } from "mobx-react";
import Sheet from "react-modal-sheet";

import { cn } from "@/lib/utils.ts";
import { opportunitiesSort, sortDirections } from "@/app/stores/filter.store.tsx";
import { useState } from "react";
import { ModalTitle } from "@/app/screens/modal/opportunities/components/modal-title.tsx";

export const SortMobile = observer(() => {
  const { navState } = globalModalStore.state;
  const { dir, label } = opportunityFilterStore.ordering || {};
  const directionLabel = dir === "DESC" ? "Descending" : "Ascending";

  const [sortDirection, setSortDirection] = useState(directionLabel);
  const [sortLabel, setSortLabel] = useState(label || "Default");

  return (
    <IosTransitionEnter type={navState?.transitionAnimation || "static"}>
      <div className={"h-fit pb-[30%]"}>
        <Sheet.Scroller className={"no-scrollbar mx-6 overflow-x-clip"}>
          <div className={"flex flex-col"}>
            <ModalTitle title={"Sort"} className={"my-5"} />
            <div className={"flex flex-col space-y-2 px-1"}>
              {opportunitiesSort.map(({ value, label, defaultDirection }) => {
                const isSelected = sortLabel === label;
                return (
                  <div
                    key={value}
                    onClick={() => {
                      setSortLabel(label);
                      if (value === "DEFAULT") {
                        opportunityFilterStore.ordering = {
                          ...opportunityFilterStore.defaultOrdering,
                        };
                        return;
                      }

                      opportunityFilterStore.ordering = {
                        dir: defaultDirection,
                        value,
                        label,
                      };
                    }}
                    className={cn(
                      "flex w-full cursor-pointer items-center justify-between rounded-sm border border-neutral-200 p-4 transition-all duration-100 ease-in-out active:bg-neutral-100",
                      isSelected ? "rounded-md bg-neutral-100" : "",
                    )}
                  >
                    <span className={"select-none text-nowrap text-sm font-semibold"}>{label}</span>
                    <Icon
                      type={"FillCheck"}
                      width={16}
                      height={16}
                      className={cn("ml-5 text-primary", isSelected ? "" : "opacity-0")}
                    />
                  </div>
                );
              })}
            </div>
            <div className={"space-y-2"}>
              <span className={"mx-5 my-3 text-sm font-medium text-neutral-400"}>Sort by</span>
              {sortDirections.map(({ value, label }) => {
                const isSelected = sortDirection === label;
                return (
                  <div
                    key={value}
                    onClick={() => {
                      setSortDirection(label);
                      opportunityFilterStore.ordering = {
                        ...opportunityFilterStore.ordering,
                        dir: value,
                      };
                    }}
                    className={cn(
                      "flex w-full cursor-pointer items-center justify-between rounded-sm border border-neutral-200 p-4 transition-all duration-100 ease-in-out active:bg-neutral-100",
                      isSelected ? "rounded-md bg-neutral-100" : "",
                    )}
                  >
                    <div className={"flex items-center"}>
                      <Icon
                        type={label === "Ascending" ? "UpDown" : "DownUp"}
                        width={16}
                        height={16}
                        className={cn("mr-2")}
                      />
                      <span className={"select-none text-nowrap text-sm font-semibold"}>{label}</span>
                    </div>
                    <Icon
                      type={"FillCheck"}
                      width={16}
                      height={16}
                      className={cn("ml-5 text-primary", isSelected ? "" : "opacity-0")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Sheet.Scroller>
        <div className={"py-4.5 w-full px-5"}>
          <Button
            text={"Apply"}
            onClick={() => {
              globalModalStore.toggleModal();
            }}
            className={"w-full"}
          />
        </div>
      </div>
    </IosTransitionEnter>
  );
});
