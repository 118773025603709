import { forwardRef } from "react";

import { cn } from "@/lib/utils";

import { Popover, PopoverPlacement } from "@/app/components";

import { Pill } from "./pill";

export interface TagProps {
  label: string;
  tooltip?: string | React.ReactNode;
  popoverPlacement?: PopoverPlacement;
  className?: string;
  tagClassName?: string;
}

export const Tag = forwardRef<HTMLDivElement, TagProps>(function Tag(
  { label, tooltip, popoverPlacement = "bottom-start", className, tagClassName }: TagProps,
  ref,
) {
  return (
    <Popover
      containerClassName={cn("outline-none", className)}
      placement={popoverPlacement}
      childrenContent={
        typeof tooltip === "string" ? <span className="text-xs font-medium text-neutral-700">{tooltip}</span> : tooltip
      }
    >
      <Pill ref={ref} label={label} className={tagClassName} />
    </Popover>
  );
});
