import { Loader } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { Icon, Dropdown, DropdownOption } from "@/app/components";
import { useActionWrapper } from "@/app/hooks";

export type ActionsMenuProps = {
  options: DropdownOption[];
  className?: string;
};

const asyncActionLabels = ["Hide", "Unhide"];

export function ActionsMenu({ className, options = [] }: ActionsMenuProps) {
  const { loading, wrapAction } = useActionWrapper();

  return (
    <Dropdown options={options} className={cn("size-8 pl-2", className)}>
      <Dropdown.Button onClick={(e) => e.stopPropagation()} size="sm">
        <Icon type={"MoreVertical"} className={"size-3.5 2xl:size-4"} />
      </Dropdown.Button>

      <Dropdown.Items className={"max-h-40"}>
        {({ label, action }) => (
          <Dropdown.Item
            className={"py-2 md:py-3"}
            key={label}
            onClick={(e) =>
              action && wrapAction({ label, event: e, action, isAsync: asyncActionLabels.includes(label) })
            }
          >
            <span className={cn("truncate text-black")}>{label}</span>
            {loading[label] && <Loader size={12} className="ml-1 animate-spin" />}
          </Dropdown.Item>
        )}
      </Dropdown.Items>
    </Dropdown>
  );
}
