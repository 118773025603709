import { memo, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";

import { CompanyDetailsEdit } from "./company-details-edit.tsx";
import { CompanyDetailsViewMode } from "./company-details-view.tsx";

export const CompanyDetails = memo(function CompanyDetails() {
  const [mode, setMode] = useState<"view" | "edit">("view");

  return (
    <div className="relative">
      <IconButtonV2
        icon="Pencil"
        className={cn("absolute right-0", mode === "edit" && "hidden")}
        onClick={() => setMode("edit")}
      />
      {mode === "edit" ? <CompanyDetailsEdit onClose={() => setMode("view")} /> : <CompanyDetailsViewMode />}
    </div>
  );
});
