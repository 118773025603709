import React, { ReactElement, useMemo } from "react";

import { useExcitementScores } from "@/app/hooks/use-excitement-scores.tsx";
import { normaliseDate } from "@/app/misc/helpers.ts";

export type ExcitementScoreData = {
  title: string;
  number: number;
  increase?: string;
  members?: {
    name: string;
    score: number;
  }[];
  isOld?: boolean;
  infoText?: ReactElement;
  decrease?: string;
  date: string | null;
};

const infoTextClass = "text-xs text-black font-normal leading-4";

export const useExcitementScore = (sentiments, previouslyMet, portCo: boolean): ExcitementScoreData | null => {
  const { stats } = useExcitementScores({
    sentiments,
    previouslyMet,
    isPortco: portCo,
  });

  const infoText = useMemo(() => {
    if (stats.isOld) {
      return <p className={infoTextClass}>This insight is older than 6 months</p>;
    }

    const formattedLastResponseTime = stats.lastResponseTime ? normaliseDate(stats.lastResponseTime) : "";
    const formattedPreviousResponseTime = stats.previousResponseTime ? normaliseDate(stats.previousResponseTime) : "";

    if (stats.decrease) {
      return (
        <p className={infoTextClass}>
          The latest average excitement score of <span className="font-bold">{stats.excitement.toFixed(1)}</span> (
          {formattedLastResponseTime}) represents a decrease of&nbsp;
          <span className="font-semibold text-red-500">{stats.decrease}</span> over the company’s previous score&nbsp;(
          {formattedPreviousResponseTime})
        </p>
      );
    }

    if (stats.increase) {
      return (
        <p className={infoTextClass}>
          The latest average excitement score of <span className="font-bold">{stats.excitement.toFixed(1)}</span>
          &nbsp;({formattedLastResponseTime}) represents an increase of&nbsp;
          <span className="font-semibold text-green-700">{stats.increase}</span> over the company’s previous
          score&nbsp;(
          {formattedPreviousResponseTime})
        </p>
      );
    }

    return <span />;
  }, [stats]);

  return stats?.excitement
    ? {
        title: "Excitement Score",
        number: stats.excitement,
        members: stats.members.map(
          ({ name, score }: { name: string | { id: string; other: string }; score: number }) => ({
            // sometimes person data is an object, not a name, thus we want to get the name from 'other' property
            name: typeof name === "string" ? name : name.other,
            score,
          }),
        ),
        infoText,
        isOld: stats.isOld,
        increase: stats.increase,
        decrease: stats.decrease,
        date: normaliseDate(String(stats.lastResponseTime)),
      }
    : null;
};
