import { SearchCompaniesGlobalQuery } from "@/app/service/opportunities.gql.ts";

export type CompanyResult = LgCompanyResult | DealroomCompanyResult;
export type LgCompanyResult = NonNullable<SearchCompaniesGlobalQuery["lgCompanies"]>["nodes"][number];
export type DealroomCompanyResult = NonNullable<SearchCompaniesGlobalQuery["dealroomCompanies"]>["nodes"][number];

// todo duplicate
export const isLgCompany = (company: CompanyResult): company is LgCompanyResult => {
  return "__typename" in company && company.__typename === "LgCompany";
};

// todo duplicate
export const isDealroomCompany = (company: CompanyResult): company is DealroomCompanyResult => {
  return "__typename" in company && company.__typename === "DealroomCompany";
};
