import { useState } from "react";

import { AddAssigneesInputInput } from "@/gql/graphql.ts";

import { useAddAssigneesMutation } from "@/app/service/company-assignees.gql.ts";

import { readAssigneesCache, readLgMembersCache, writeAssigneesCache } from "./helpers";

export const useAddAssigneeMutation = () => {
  const [loading, setLoading] = useState(false);
  const [addAssignees, { error }] = useAddAssigneesMutation();

  const onAddAssignees = async (input: AddAssigneesInputInput, onSuccess?: () => void) => {
    const existingAssignees = readAssigneesCache(input.companyId);
    const newMembers = readLgMembersCache(input.members);

    try {
      setLoading(true);

      writeAssigneesCache(input.companyId, [
        ...existingAssignees,
        ...newMembers.map((member) => ({
          node: {
            companyId: input.companyId,
            memberId: member.id,
            status: null,
            member,
          },
        })),
      ]);

      const response = await addAssignees({
        variables: {
          input,
        },
        onCompleted: () => {
          if (onSuccess) onSuccess();
        },
      });

      return response;
    } catch {
      writeAssigneesCache(input.companyId, existingAssignees);
    } finally {
      setLoading(false);
    }
  };

  return { onAddAssignees, loading, error };
};
