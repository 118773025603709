import { useEffect } from "react";

import { observer } from "mobx-react";
import { useLocation } from "react-router";

import { cn } from "@/lib/utils.ts";

import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

import { usePageNav } from "./page-nav-provider.tsx";

type PageNavItem = string | { id: string; label: string };

const usePageScroller = (enabled: boolean) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (enabled && hash) {
      setTimeout(() => {
        document.getElementById(hash.replace("#", ""))?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, []);
};

export const PageNav = observer(
  ({ navLinks, scrollOnLoad = true }: { navLinks: PageNavItem[]; scrollOnLoad?: boolean }) => {
    const { activeElements: activeItems } = usePageNav();
    const { isOpen } = globalModalStore.navbarState;

    usePageScroller(scrollOnLoad);

    const topActiveItem = navLinks.find((link) =>
      activeItems?.includes(typeof link === "string" ? link.toLowerCase() : link.id.toLowerCase()),
    );

    return (
      <div
        className={cn(
          "hidden rounded-md bg-neutral-100 bg-opacity-50 p-0.5 shadow-xs backdrop-blur lg:block",
          // position: bottom centered. margin left is needed to center inside the main section considering width of left nav bar
          "fixed bottom-8 left-1/2 ml-10 -translate-x-1/2",
          isOpen && "ml-[100px]",
        )}
      >
        <ul className="flex justify-between p-0.5">
          {navLinks.map((link) => {
            const { id, label } = typeof link === "string" ? { id: link, label: link } : link;
            const isActive = typeof topActiveItem === "string" ? id === topActiveItem : id === topActiveItem?.id;
            return (
              <li key={id}>
                <a href={`#${id}`}>
                  <span
                    className={cn(
                      "block cursor-pointer text-nowrap bg-opacity-50 px-3 py-2 text-sm font-semibold hover:rounded-md hover:bg-neutral-100",
                      isActive && "rounded-md bg-neutral-200 bg-opacity-50",
                    )}
                  >
                    {label !== "pcg" ? label : label.toUpperCase()}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);
