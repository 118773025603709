import { useState } from "react";

import { RadioGroup, Radio } from "@headlessui/react";

import { cn } from "@/lib/utils";

import { Icon, IconType } from "..";

export type OptionType = string | boolean | number;

export interface RadioOption<T extends OptionType> {
  value: T;
  label: string;
  icon?: IconType;
  checkedIcon?: IconType;
}

export interface RadioGroupProps<T extends OptionType> {
  className?: string;
  optionClassName?: string;
  optionContainerClassName?: string;
  checkedOptionContainerClassName?: string;
  iconClassName?: string;
  checkedClassName?: string;
  labelClassName?: string;
  options: RadioOption<T>[];
  withCheck?: boolean;
  defaultValue?: T;
  checkIconContainer?: string;
  checkIconClassName?: string;
  checkedIconClassName?: string;
  onSelected: (option?: RadioOption<T>) => void;
}

export function RadioGroups<T extends OptionType = string>({
  className,
  optionClassName,
  optionContainerClassName,
  checkedOptionContainerClassName,
  checkedClassName,
  labelClassName,
  iconClassName,
  options,
  withCheck,
  checkIconContainer,
  checkIconClassName,
  checkedIconClassName,
  defaultValue,
  onSelected,
}: RadioGroupProps<T>) {
  const [selected, setSelected] = useState<T | string | undefined>(defaultValue);

  const handleSelect = (value: T) => {
    const selectedValue = selected !== value ? value : "";
    setSelected(selectedValue);
    onSelected(options.find(({ value: val }) => val === selectedValue));
  };

  return (
    <RadioGroup value={selected} defaultValue={defaultValue} className={className}>
      {options.map((option) => (
        <Radio
          key={String(option.value)}
          value={option.value}
          className={cn(optionContainerClassName, option.value === selected ? checkedOptionContainerClassName : "")}
          onClick={() => handleSelect(option.value)}
        >
          {({ checked }) => (
            <>
              <div className={cn("transition-all", optionClassName, checked ? checkedClassName : "")}>
                {option.icon && (
                  <Icon
                    type={checked ? option.checkedIcon || option.icon : option.icon}
                    className={cn(iconClassName, checked ? checkedIconClassName : "")}
                  />
                )}
                <p className={labelClassName}>{option.label}</p>
                {withCheck && (
                  <div
                    className={cn(
                      "mx-auto mt-6 flex size-6 items-center justify-center rounded-xxl border-2 bg-white",
                      checkIconContainer,
                    )}
                  >
                    {checked && (
                      <div
                        className={cn(
                          "size-4 rounded-xxl bg-gradient-to-r from-primary-400 to-secondary-400",
                          checkIconClassName,
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </Radio>
      ))}
    </RadioGroup>
  );
}
