import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

import { Button } from "@/app/components";
import { Card } from "@/app/components/card";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { CompanyDataSchema } from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import {
  CompanySignalSchema,
  Fund,
  useCompanySignalForm,
} from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { FundSelectControl } from "@/app/screens/add-company/company-signals-step/fund-select-control.tsx";
import { SignalFormFields } from "@/app/screens/add-company/company-signals-step/SignalFormFields.tsx";
import { useGetDealroomCompanyFundingQuery } from "@/app/service/dealroom-company.gql.ts";

import { CompanyDetailsPreview } from "./company-preview";

export type AddCompanyStepProps = {
  onSubmit: (data: CompanySignalSchema) => void;
  onCancel: () => void;
  loading?: boolean;
};

const useRecommendedFund = (companyId?: string): Fund => {
  const funds = [
    {
      value: Fund.LocalGlobe,
      recommendedRounds: ["SEED", "ANGEL", "PRE-SEED"],
    },
    {
      value: Fund.Latitude,
      recommendedRounds: ["SERIES A", "SERIES B"],
    },
    {
      value: Fund.Solar,
      recommendedRounds: ["SERIES D", "SERIES E", "SERIES F", "SERIES G", "SERIES H", "SERIES I", "LATE GROWTH"],
    },
  ];

  const { data: dealroomFundingData, loading } = useGetDealroomCompanyFundingQuery({
    variables: {
      companyId,
      limit: 1,
    },
    skip: !companyId,
  });

  const latestRound = dealroomFundingData?.dealroomFundingRounds?.nodes[0]?.round;

  if (!latestRound) {
    return Fund.Pipeline;
  }

  return funds.find((fund) => fund.recommendedRounds.includes(latestRound))?.value ?? Fund.Pipeline;
};

export const CompanySignals = ({
  onSubmit,
  onCancel,
  loading,
  companyData,
}: AddCompanyStepProps & {
  companyData: CompanyDataSchema;
}) => {
  const form = useCompanySignalForm();
  const { control, handleSubmit, formState, watch } = form;

  const recommendedFund = useRecommendedFund(companyData.dealroomCompany?.id);

  useEffect(() => {
    form.setValue("fund", recommendedFund);
  }, [recommendedFund]);

  return (
    <div>
      <Card>
        <CompanyDetailsPreview company={companyData.dealroomCompany ?? companyData} />
      </Card>
      <form className="flex flex-1 flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"fund"}
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              label="Is this a LocalGlobe, Latitude or Solar prospect?"
              error={fieldState.error?.message}
              styles={{ container: "w-full space-y-4" }}
            >
              <FundSelectControl
                onChange={(fund) => field.onChange(fund)}
                value={field.value}
                recommended={recommendedFund}
              />
            </FormField>
          )}
        ></Controller>
        <SignalFormFields control={control} watch={watch} />
        <div className="flex w-full max-w-[680px] flex-col items-center justify-between gap-2 p-4 lg:flex-row lg:pb-10">
          <Button
            text={"Finish"}
            type="submit"
            loading={loading}
            disabled={loading || !formState.isValid}
            variant={"gradient"}
            className="w-full px-10 lg:order-last lg:w-auto"
          />
          <Button text="Back" variant="outline" onClick={onCancel} className="w-full px-10 lg:w-auto" />
        </div>
      </form>
    </div>
  );
};
