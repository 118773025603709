import { createContext, useContext } from "react";
import MobxReactForm from "mobx-react-form";
import { observer } from "mobx-react";
import { cn } from "@/lib/utils.ts";

type FormContextType = {
  form: MobxReactForm | null;
};
type FormFieldContextType = {
  fieldId?: string;
  field?: any;
};

const FormContext = createContext<FormContextType>({ form: null });
const FormFieldContext = createContext<FormFieldContextType>({});

const Form = observer(({ form, children, className }: any) => (
  <FormContext.Provider value={{ form }}>
    <div className={cn(className)}>{children}</div>
  </FormContext.Provider>
));

/**
 * @deprecated
 */
export const FormField = observer(({ fieldId, render }: { fieldId: string; render: any; ctx?: any }) => {
  const { form } = useContext(FormContext);

  const field = form?.$(fieldId);
  return (
    <FormFieldContext.Provider value={{ fieldId, field }}>
      {render({ ...field.bind(), fieldId })}
    </FormFieldContext.Provider>
  );
});

export const FormFieldLabel = ({ className = "" }: { className?: string }) => {
  const {
    field: { label },
  } = useContext(FormFieldContext);
  let required = false,
    labelText = label;

  if (label.split(" ").at(-1) === "*") {
    required = true;
    labelText = labelText.replace("*", " ");
  }

  return (
    <div className={`mb-1.5 flex leading-6 ${className}`}>
      <span className="text-xs font-medium text-neutral-600">{labelText}</span>
      {required && (
        <>
          &nbsp;
          <span className="text-xs font-medium text-red-400">*</span>
        </>
      )}
    </div>
  );
};

export const FormFieldError = observer(() => {
  const { field } = useContext(FormFieldContext);
  const hasError = Boolean(field?.error);

  return (
    <div className={cn("p-2 transition-all", hasError ? "flex" : "hidden")}>
      <span className="text-xs font-semibold text-red-400">{field.error || "empty"}</span>
    </div>
  );
});

const Root = Form;
const Field = FormField;
const Label = FormFieldLabel;
const Error = FormFieldError;

export default { Root, Field, Label, Error };
