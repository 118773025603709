import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { useMembersMapped } from "@/lib/service/members.ts";
import { cn, debounce } from "@/lib/utils.ts";

import { Button, CompanyList, Icon, Input, Lottie, RadioGroups, SearchBar, TextArea } from "@/app/components";
import Form from "@/app/components/forms";
import { Switch } from "@/app/components/input";
import { WebSelect } from "@/app/screens/modal/components";
import { LocationInput } from "@/app/screens/opportunities/components";
import { useSearchCompaniesGlobalLazyQuery } from "@/app/service/opportunities.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import { NewOpportunityDefault } from "@/app/stores/form";
import { countries, stages, targetFund, industries } from "@/data/data.tsx";

export const Signal = ({ form }) => {
  const { data: allOptions } = useMembersMapped();

  useEffect(() => {
    if (authStore?.user) {
      const defaultAssignees = [
        {
          label: `${authStore?.user?.firstName} ${authStore.user?.lastName}`,
          value: authStore.user?.id,
        },
      ];
      form.$("assignees").set(defaultAssignees);
    }
  }, []);

  const showNote =
    form.$("assignees").value.length && !form.$("assignees").value.every(({ value }) => value === authStore.user?.id);

  const sourceType = form.$("source_type").value;

  return (
    <div className={cn("w-full space-y-6")}>
      <div className={"grid grid-cols-2 gap-4"}>
        <Form.Field
          fieldId="source_type"
          render={({ onChange, value }) => (
            <div className="flex w-full flex-col pr-2">
              <WebSelect
                allOptions={[
                  { value: "angel", label: "Angel" },
                  { value: "investor", label: "Investor" },
                  { value: "operator", label: "Operator" },
                  { value: "portfolio_founder", label: "Portfolio Founder" },
                  {
                    value: "none_portfolio_founder",
                    label: "Non Portfolio Founder",
                  },
                  { value: "service_provider", label: "Service Provider" },
                  { value: "other", label: "Other" },
                ]}
                label={"Heard about it from"}
                required
                defaultSelected={value || []}
                multiple={false}
                storeCallback={onChange}
              />
            </div>
          )}
        />
        {sourceType?.length ? (
          <Form.Field
            fieldId="source"
            render={({ onChange }) => (
              <Input
                label={sourceType[0].value !== "other" ? `Which ${sourceType[0].label}` : "Additional Information"}
                containerClassName={"mb-0"}
                required
                onChange={({ target: { value: text } }) => onChange(text)}
              />
            )}
          />
        ) : null}
      </div>
      <div className={"grid grid-cols-4 gap-4"}>
        <Form.Field
          fieldId={"intro_available"}
          render={({ onChange, value }) => (
            <div>
              <Form.Label />
              <Switch
                onChange={(op) => onChange(op?.value)}
                defaultValue={!!value}
                on={{ value: true, label: "Yes" }}
                off={{ value: false, label: "No" }}
              />
            </div>
          )}
        />
        {Object.entries({
          is_actively_raising: "Actively Raising",
          high_priority: "High Priority",
          ball_in_our_control: "Ball in Our Control",
        }).map(([key]) => (
          <Form.Field
            key={key}
            fieldId={key}
            render={({ onChange }) => (
              <div>
                <Form.Label />
                <Switch
                  on={{ value: true, label: "Yes" }}
                  off={{ value: false, label: "No" }}
                  onChange={(op) => onChange(op?.value)}
                />
              </div>
            )}
          />
        ))}
      </div>
      <Form.Field
        fieldId="assignees"
        render={({ onChange, value }) => (
          <WebSelect
            allOptions={allOptions}
            label={"Assign to"}
            required
            defaultSelected={value}
            storeCallback={onChange}
          />
        )}
      />
      {showNote ? (
        <Form.Field
          fieldId="assignees_notes"
          render={({ onChange, value }) => (
            <TextArea
              className={"w-full"}
              label={"Share a note with assignees"}
              placeholder={"Add context on the opportunity…"}
              value={value}
              onChange={onChange}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export const Note = () => {
  return (
    <div className={"flex w-full flex-col"}>
      <Form.Field
        fieldId="notes"
        render={({ onChange, value }) => (
          <div className="mt-2 text-start">
            <TextArea
              label={"Note"}
              placeholder={
                "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
              }
              onChange={onChange}
              value={value}
            />
          </div>
        )}
      />
    </div>
  );
};

export const CompanyAdditionalDetails = () => {
  return (
    <div className={"flex w-full flex-col"}>
      <Form.Field
        fieldId="industries"
        render={({ onChange, value }) => (
          <div className="mb-4 flex flex-col items-start">
            <WebSelect
              label={"Sector"}
              iconLeft={"Search"}
              optionsDirection={"bottom"}
              allOptions={industries}
              placeholder="Please select..."
              required={true}
              defaultSelected={value}
              storeCallback={onChange}
            />
          </div>
        )}
      />
      <Form.Field
        fieldId="round"
        render={({ onChange, value }) => (
          <div className="mb-4 flex flex-1 flex-col items-start">
            <WebSelect
              label={"Last Round Raised"}
              iconLeft={"Search"}
              required={true}
              optionsDirection={"bottom"}
              placeholder="Please select..."
              defaultSelected={value}
              storeCallback={(value) => onChange(value?.[0])}
              allOptions={stages}
              multiple={false}
            />
          </div>
        )}
      />
      <Form.Field
        fieldId="target_fund"
        render={({ onChange, value }) => (
          <div className="mb-4 flex flex-1 flex-col items-start">
            <WebSelect
              label={"Target Fund"}
              iconLeft={"Search"}
              optionsDirection={"bottom"}
              placeholder="Please select..."
              defaultSelected={value}
              required={true}
              allOptions={targetFund}
              storeCallback={(value) => onChange(value?.[0])}
              multiple={false}
            />
          </div>
        )}
      />
      <Form.Field
        fieldId="investment_type"
        render={({ onChange, value }) => (
          <>
            <Form.Label />
            <RadioGroups
              onSelected={(op) => onChange(op?.value)}
              options={[
                { value: "core", label: "Core" },
                { value: "strategic", label: "Strategic" },
              ]}
              defaultValue={value}
              className={`flex`}
              optionClassName="flex font-semibold cursor-pointer mr-4 py-3"
              checkedClassName="text-black"
              labelClassName="inline-block order-last text-sm font-normal"
              checkIconContainer="mt-0 mr-2"
              iconClassName="h-11 w-11 text-black"
              withCheck
            />
          </>
        )}
      />
    </div>
  );
};

export const CompanyDetails = () => {
  return (
    <div className={"flex w-full flex-col"}>
      <div className={"mb-4 w-full"}>
        <Form.Field
          fieldId="location"
          render={({ onChange, value }) => (
            <LocationInput
              label={"HQ Location"}
              required={true}
              defaultValue={
                value?.country
                  ? {
                      value: `${value.city ? value.city + ", " : ""}${value.country}`,
                      label: `${value.city ? value.city + ", " : ""}${value.country}`,
                    }
                  : undefined
              }
              onChange={(val) => {
                onChange(val);
              }}
            />
          )}
        />
      </div>
      <div className={"mb-4 w-full"}>
        <Form.Field
          fieldId="website_url"
          render={({ onChange, value }) => (
            <>
              <Input
                label={"Company Website"}
                placeholder={"www.domain.com"}
                onChange={({ target: { value } }) => onChange(value)}
                value={value || ""}
              />
            </>
          )}
        />
      </div>

      <Form.Field
        fieldId="about"
        render={({ onChange, value }) => (
          <div className="text-start">
            <TextArea
              label={"Company Description"}
              placeholder={"Add company description here..."}
              onChange={onChange}
              value={value || ""}
            />
          </div>
        )}
      />
    </div>
  );
};

export const CompanyName = observer(({ form }) => {
  const [results, setResults] = useState<any[]>([]);
  const [name, setName] = useState(form.$("name").value ?? "");
  const [searchTerm, setSearchTerm] = useState("");
  const [companyLG, setCompanyLG] = useState<{
    id: string;
    origin: "lg" | "dealroom";
    image: string;
    name: string;
    websiteUrl: string;
  } | null>(null);

  const [autocompleteCompanyName, { loading }] = useSearchCompaniesGlobalLazyQuery();

  const handleSelect = (item) => {
    const { company } = item;

    setCompanyLG({
      id: company.id,
      image: company.image,
      name: company.name,
      websiteUrl: company.websiteUrl,
      origin: company.origin,
    });
    setName(company.name);
    setSearchTerm("");

    const inds = company.industries
      ? company.industries.map((industry) => industries.find((value) => value.value === industry)).filter(Boolean)
      : [];

    const location = countries.find((c) => c.value === company.country);
    const updateForm = {
      ...NewOpportunityDefault,
      source: company.origin,
      name: company.name,
      company_id: company.id,
      website_url: company["website_url"],
      about: company.tagline,
      industries: inds,
      location: location || null,
      signal_id: company.signalId,
      image: item.image,
    };

    Object.entries(updateForm).forEach(([key, value]) => {
      const field = form.$(key);
      field.value = value;
    });
  };

  const mapperFunction = ({ data: result }) => {
    let response: any[] = [];

    const { dealroomCompanies, lgCompanies } = result;

    if (dealroomCompanies?.nodes?.length) {
      response = dealroomCompanies?.nodes?.map(
        ({ id, image, name, websiteUrl, hqLocations, about, tagline, industries, team }) => {
          const country = hqLocations?.[0]?.country?.[0]?.name;
          const city = hqLocations?.[0]?.city?.[0]?.name;
          const inds = industries?.map(({ name }) => name.toLowerCase());
          const founders = (team?.items || [])
            .filter(({ is_founder, linkedin_url }) => Boolean(is_founder && linkedin_url))
            .map(({ linkedin_url }) => linkedin_url);

          return {
            id,
            image: image ? (image.startsWith("http") ? image : "https://" + image) : null,
            name,
            value: websiteUrl,
            founders,
            company: {
              origin: "dealroom",
              about,
              tagline,
              city,
              country,
              name,
              website_url: websiteUrl,
              industries: inds,
              id,
            },
          };
        },
      );
    }

    if (lgCompanies?.nodes?.length) {
      setResults([
        ...response,
        ...lgCompanies.nodes.map((company) => ({
          id: company.id,
          image: company.image,
          name: company.name,
          value: company.websiteUrl,
          company: { ...company, origin: "lg" },
        })),
      ]);
    } else {
      setResults([...response]);
    }
  };

  const search = debounce(async (value: string) => {
    if (value) {
      autocompleteCompanyName({
        variables: {
          limit: 12,
          offset: 0,
          lgFilter: { name: { fuzzy: value } },
          dealroomFilter: { name: { fuzzy: value } },
        },
      }).then(mapperFunction);
    }
  }, 1000);

  const handleQuery = (value) => {
    setCompanyLG(null);
    setSearchTerm(value);
    search(value);
    setName(value);
  };

  const handleReset = () => {
    const updateForm = {
      ...NewOpportunityDefault,
      source: null,
      name: null,
      company_id: null,
      website_url: null,
      about: null,
      industries: [],
      location: null,
      signal_id: null,
      image: null,
    };

    Object.entries(updateForm).forEach(([key, value]) => {
      const field = form.$(key);
      field.value = value;
    });
    setResults([]);
    setCompanyLG(null);
    setSearchTerm("");
    setName("");
  };

  return (
    <div className="relative w-full">
      <SearchBar
        containerClassName="w-full"
        loading={loading}
        onQueryChange={handleQuery}
        onReset={handleReset}
        selected={name}
      />
      {searchTerm.length > 1 && companyLG?.origin !== "lg" && (
        <span
          className={
            "flex w-full cursor-pointer select-none justify-center py-2 text-sm font-semibold text-primary hover:bg-neutral-200"
          }
          onClick={() => {
            form.$("name").value = searchTerm;
            setResults([]);
            setSearchTerm("");
          }}
        >{`Create company "${searchTerm}"`}</span>
      )}
      {companyLG?.origin === "lg" && (
        <motion.div
          className={
            "mt-4 flex w-full cursor-pointer select-none items-center rounded-md border p-4 hover:scale-[0.99] hover:border-none hover:bg-neutral-50 hover:shadow-xs active:bg-white"
          }
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          <div className={"flex items-center justify-center"}>
            <Icon type={"Shield Alert"} className={"mr-2 size-10 text-red"} />
          </div>
          <div className={"flex  flex-col"}>
            <span className="text-lg font-semibold">This company is already in Nazare.</span>
            <Link to={`/company/${companyLG.id}`} target="_blank">
              <p className={"text-sm font-semibold italic text-neutral-600"}>Click here to see or edit the details.</p>
            </Link>
          </div>
        </motion.div>
      )}
      {results.length > 0 && !companyLG && (
        <CompanyList
          results={results}
          searchTerm={searchTerm}
          containerClassName="bg-neutral-100 rounded-md mt-1 max-h-[250px] overflow-y-auto z-10"
          className=""
          handleSelect={handleSelect}
        />
      )}
    </div>
  );
});

export const Founders = ({ form }) => {
  const defaultFounders = form.$("founders").value?.length ? form.$("founders").value : [{ name: "", url: "" }];
  const { isPhone, isBigTablet } = useBreakpoints();
  const [founders, setFounders] = useState<Array<{ name: string; url: string }>>(defaultFounders);

  const allLinks = founders.every(({ url }) =>
    url.length ? /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www.)/g.test(url) : true,
  );

  React.useEffect(() => {
    form.$("founders").set(founders);
  }, [form, founders]);

  return (
    <Form.Field
      fieldId="founders"
      render={() => (
        <div className={"flex w-full flex-col"}>
          <div className={"flex flex-col"}>
            <div
              className={
                "sm:space-2 lg:space-3 my-2 flex max-h-[50dvh] w-full flex-col overflow-auto py-2 lg:max-h-[30dvh]"
              }
            >
              {founders.map((e, i) => (
                <div className={"mb-5 w-full items-center lg:flex lg:space-x-5"} key={i}>
                  <Input
                    required={form.$("stealth").value === "stealth"}
                    value={e.url}
                    iconLeft={"Linkedin"}
                    iconLeftClassName={"text-[#0077B5] size-5"}
                    placeholder={"Linkedin URL"}
                    containerClassName={"flex w-full lg:w-3/4"}
                    autoFocus={i === founders.length - 1}
                    label={i === 0 && isBigTablet ? `LinkedIn URL` : ""}
                    onChange={({ target: { value } }) => {
                      const newFounders = [...founders];
                      newFounders[i].url = value;
                      setFounders(newFounders);
                    }}
                  />
                  <div className="flex w-full lg:w-1/3">
                    <Input
                      required={form.$("stealth").value === "stealth" && i === 0}
                      value={e.name}
                      iconLeft={"User"}
                      label={i === 0 && isBigTablet ? "Name" : ""}
                      placeholder={"Founder's Name"}
                      containerClassName={"flex w-full"}
                      autoFocus={false}
                      onChange={({ target: { value } }) => {
                        const newFounders = [...founders];
                        newFounders[i].name = value;
                        setFounders(newFounders);
                      }}
                    />
                    {i ? (
                      <Icon
                        type={"X"}
                        className={"mb-4 ml-2 size-5 cursor-pointer self-center"}
                        onClick={() => {
                          setFounders((prevState) => prevState.filter((_, index) => index !== i));
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            <div className={"flex items-center justify-center gap-x-5 sm:mt-1 lg:mt-4 lg:justify-start"}>
              <Button
                text={"Add Founder"}
                iconLeft={"Add"}
                size={isPhone ? "md" : "sm"}
                onClick={(e) => {
                  e.preventDefault();
                  setFounders((prevState) => [...prevState, { name: "", url: "" }]);
                }}
              />
              {!allLinks && <span className={"text-sm font-semibold text-red"}>Please enter a valid Linkedin URL</span>}
            </div>
          </div>
        </div>
      )}
    />
  );
};
export const StealthClassName = `flex cursor-pointer items-center justify-center rounded-md p-2 hover:scale-[1.04] focus:scale-[1.02] focus:border-2 focus:border-primary-50 hover:bg-neutral-200 focus:outline focus:outline-primary-100 focus:outline-offset-1 hover:shadow-xs`;

export const Stealth = ({ form }) => {
  const { isPhone, isBigTablet } = useBreakpoints();

  const companyRef = useRef<HTMLButtonElement>(null);
  const stealthRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (!form.$("stealth").value) {
      form.$("stealth").set("company");
    }
    if (form.$("stealth").value === "company") {
      companyRef.current?.focus();
    } else {
      stealthRef.current?.focus();
    }
  }, [form]);

  return (
    <Form.Field
      fieldId="stealth"
      render={({ onChange, value, error }) => (
        <div className={cn("flex w-full items-center justify-start")}>
          <div
            className={cn(
              "flex w-full items-center justify-around gap-4 lg:py-4",
              isBigTablet ? "lg:flex-row" : "flex-col",
            )}
          >
            <button
              ref={companyRef}
              onClick={(e) => {
                e.preventDefault();
                onChange("company");
              }}
              className={cn(StealthClassName, "relative", value === "company" ? "bg-neutral-200" : "bg-neutral-100")}
            >
              <Lottie
                type={"rocket"}
                className={cn("size-64 opacity-50 hover:opacity-100", value === "company" ? "opacity-100" : "")}
                active={value === "company"}
                onHoverPlay={true}
              />
              <span
                className={cn(
                  "absolute bottom text-lg font-semibold sm:text-xl",
                  value === "company" ? "opacity-100" : "opacity-50",
                )}
              >
                Company
              </span>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                onChange("stealth");
              }}
              ref={stealthRef}
              className={cn(StealthClassName, "relative", value === "stealth" ? "bg-neutral-200" : "bg-neutral-100")}
            >
              <Lottie
                type={"stealth"}
                className={cn("size-64 opacity-50 hover:opacity-100", value === "stealth" ? "opacity-100" : "")}
                active={value === "stealth"}
                onHoverPlay={true}
              />
              <span
                className={cn(
                  "absolute bottom text-lg font-semibold sm:text-xl",
                  value === "stealth" ? "opacity-100" : "opacity-50",
                )}
              >
                Founder in Stealth
              </span>
            </button>
          </div>
          {error && <Form.Error />}
        </div>
      )}
    />
  );
};
