import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";

export const FoundersSchema = z.object({
  founders: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        url: z.string().regex(linkedinProfileUrlRegex, `Please enter a valid url`),
      }),
    )
    .nonempty(),
});

export type FoundersFormData = z.infer<typeof FoundersSchema>;

export const useFoundersForm = () => {
  return useForm<FoundersFormData>({
    resolver: zodResolver(FoundersSchema),
    mode: "onChange",
    defaultValues: {
      founders: [],
    },
  });
};
