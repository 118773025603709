import { Button } from "@/app/components";

type BreadCrumbsProps = {
  title: string;
  action: () => void;
  className?: string;
};

export function BreadCrumbs({ title, action, className }: BreadCrumbsProps) {
  return (
    <Button
      size="sm"
      variant="text"
      data-cy="bread-crumbs"
      className={className}
      onClick={action}
      iconLeft="ArrowLeft"
      text={title}
    />
  );
}
