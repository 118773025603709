import { observer } from "mobx-react";

import { Button, Lottie, ProgressSpinner } from "@/app/components";
import { MobileFab } from "@/app/components/navbar";
import { useOpportunitiesDashboard } from "@/app/screens/opportunities";
import { SubHeader, WebOpportunitiesList } from "@/app/screens/opportunities/components";
import modalStore from "@/app/stores/modal.store";

import { AddNewResponsiveModal } from "../modal/add-new";

export const OpportunitiesDashboard = observer(function OpportunitiesDashboard() {
  const { listRef, isLoading, scrollBox, totalLength, responseCount, data } = useOpportunitiesDashboard();

  return (
    <>
      <div
        ref={scrollBox}
        className={"flex h-fit min-h-screen w-full flex-1 grow flex-col items-center bg-background pt-[19dvh] lg:pt-0"}
      >
        <SubHeader containerClass={"sticky top-0 z-30 hidden bg-opacity-80 backdrop-blur-md shadow-xs"} />

        <div className={"flex size-full flex-1 grow flex-col items-center p-2 sm:px-4 lg:px-6 lg:py-4 2xl:px-8"}>
          {!totalLength && isLoading && (
            <div className="!relative inset-y-[33%] flex h-[75dvh] justify-center items-center select-none flex-col rounded-xs">
              <ProgressSpinner />
              <span className="mt-2 text-gray-800">Fetching data, hold on...</span>
            </div>
          )}
          <WebOpportunitiesList allOpportunities={data?.lgCompanies?.edges} />

          <div className="mt-4 h-full lg:my-6">
            {!isLoading && responseCount === 0 && (
              <div className="!relative inset-y-[33%] flex h-[75dvh] select-none flex-col rounded-xs">
                <Lottie
                  type={"noResult"}
                  subtitleBottom={"No results to show"}
                  labelBottom={"Try adjusting the filters or explore other options"}
                  className={"h-full w-1/2 self-center"}
                />
              </div>
            )}
            {responseCount !== 0 && totalLength !== data?.lgCompanies?.edges.length && (
              <Button
                ref={listRef}
                variant="secondary"
                size={"md"}
                loading={isLoading}
                className={"self-center"}
              />
            )}
          </div>
        </div>
      </div>
      <MobileFab
        className={"fixed bottom-7 right-7"}
        onClick={() => {
          modalStore.open(AddNewResponsiveModal);
        }}
      />
    </>
  );
});
