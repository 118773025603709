import React, { PropsWithChildren } from "react";

import { Description, Field, Label } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils";

import { Icon, Tooltip } from "@/app/components";

export type LabelVariant = "primary" | "secondary" | "tertiary";

type FormFieldProps = {
  label?: string;
  labelVariant?: LabelVariant;
  description?: string;
  error?: string;
  disabled?: boolean;
  // TODO: need to revise better plan with keeping styles consistent so we don't have to use this
  styles?: {
    container?: string;
    label?: string;
    error?: string;
  };
  enableReset?: boolean;
  onReset?: () => void;
  required?: boolean;
};

// todo do we need multiple styles for a label? probably not. discuss with @luigi
const getLabelStyles = (variant: LabelVariant) => {
  switch (variant) {
    case "tertiary":
      return "font-normal text-neutral-700 lg:text-sm";
    case "secondary":
      return "font-medium text-neutral-600 lg:text-xs";
    case "primary":
    default:
      return "font-semibold text-neutral-900 lg:text-xl";
  }
};

export function FormField({
  description,
  error,
  label,
  disabled,
  labelVariant = "primary",
  styles,
  children,
  onReset,
  enableReset,
  required,
}: PropsWithChildren<FormFieldProps>) {
  const { container, label: labelStyle, error: errorStyle } = styles || {};
  return (
    <Field className={cn("space-y-2", container)} disabled={disabled}>
      {label && (
        <Label
          className={cn(
            "flex items-center text-base data-[disabled]:opacity-50",
            getLabelStyles(labelVariant),
            labelStyle,
          )}
        >
          {label}
          {required && <span className="text-red">*</span>}
          {enableReset && onReset && (
            <Tooltip text="Reset" placement="top" hideArrow>
              <Icon type="Reset" className="ml-1 size-[0.75em]" onClick={onReset} />
            </Tooltip>
          )}
        </Label>
      )}
      {description && <Description className="data-[disabled]:opacity-50">{description}</Description>}
      {children}
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className={cn("ml-1 overflow-hidden text-xs font-semibold text-red-500", errorStyle)}
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </Field>
  );
}
