import React from "react";

import { Control, Controller, FieldPath } from "react-hook-form";

import { RadioGroups, RadioOption } from "@/app/components";
import { FormField } from "@/app/screens/opportunities/add-new/shared/components/form-field.tsx";
import { CompanyAttributesFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-attributes-form.ts";

type Field = { name: keyof CompanyAttributesFormData["attributes"]; label: string };
const fields: Field[] = [
  { name: "introAvailable", label: "Can we get connected?" },
  { name: "activelyRaising", label: "Are they actively raising?" },
  { name: "highPriority", label: "Is this a high priority?" },
  { name: "ballInOurControl", label: "Is this moving quickly and/or is very competitive?" }, // todo
];

const options: RadioOption<boolean>[] = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

type CompanyAttributeFieldsProps<
  TFieldValues extends {
    [key: string]: CompanyAttributesFormData["attributes"];
  },
  TName extends FieldPath<TFieldValues>,
> = {
  control: Control<TFieldValues>;
  name: TName;
  required?: boolean;
};

export const CompanyAttributeFields = <
  TFieldValues extends {
    [key: string]: CompanyAttributesFormData["attributes"];
  },
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  required = true,
}: CompanyAttributeFieldsProps<TFieldValues, TName>) => {
  return (
    <div className="flex flex-wrap justify-between lg:flex-nowrap gap-4">
      {fields.map((fieldDef) => (
        <Controller
          key={fieldDef.name}
          name={`${name}.${fieldDef.name}`}
          control={control}
          render={({ field, fieldState }) => (
            <FormField label={fieldDef.label} required={required} errorMessage={fieldState.error?.message}>
              <RadioGroups
                onSelected={(op) => field.onChange(op?.value)}
                options={options}
                className={`flex rounded-xs border`}
                defaultValue={field.value}
                optionContainerClassName="even:border-l text-neutral-500"
                checkedClassName="text-black bg-neutral-100 text-neutral-500"
                optionClassName="flex font-semibold cursor-pointer px-3 py-2 w-20 justify-center"
                labelClassName="inline-block order-last text-sm font-semibold"
                checkIconContainer="hidden"
                withCheck
              />
            </FormField>
          )}
        ></Controller>
      ))}
    </div>
  );
};
