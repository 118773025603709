import React, { useEffect, useState } from "react";

import { Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

import { Button, Icon, TextArea, Toast } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { isDealroomCompany } from "@/app/screens/opportunities/add-new/add-company-new.types.ts";
import {
  CompanyChangeData,
  CompanySearchAutocomplete,
  DealroomCompanyResult,
} from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";
import { LocationInputV2 } from "@/app/screens/opportunities/components/location-input-v2.tsx";
import {
  CompanyDetailsData,
  useUpdateCompanyDetails,
} from "@/app/screens/opportunities/opportunity/components/details/use-update-company-details.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { industries, stages, targetFund } from "@/data/data.tsx";

import { CompanyDetailsEditFormData, useCompanyDetailsEditForm } from "./company-details-edit-form.ts";

export const CompanyDetailsEdit = ({ onClose }) => {
  const { company } = useOpportunityContext();
  const { control, handleSubmit, reset, resetField, setValue, formState, ...form } = useCompanyDetailsEditForm(company);
  const [selectedDealroomCompany, setSelectedDealroomCompany] = useState<DealroomCompanyResult | null>(null);

  const [update, { loading }] = useUpdateCompanyDetails();

  const dealroomCompanySource = company?.companiesSources.items.find(
    (source) => source.sourceType === "DealroomCompany",
  )?.dealroomCompanyAsSource;

  useEffect(() => {
    if (dealroomCompanySource) {
      // fixme check and specify which fields are needed from DealroomCompanyResult
      setSelectedDealroomCompany(dealroomCompanySource);
    }
  }, [dealroomCompanySource]);

  const onSubmit = (data: CompanyDetailsEditFormData) => {
    console.log(data);

    if (!company) {
      return;
    }

    const updateData: CompanyDetailsData = {
      name: data.name,
      about: data.description,
      country: data.location.country,
      city: data.location.city,

      industries: data.sectors as [string, ...string[]],
      // investmentType: data.investmentType,
      round: data.lastRound,
      targetFund: data.targetFund,
      websiteUrl: data.websiteUrl,
      dealroomCompany: data.dealroomCompany,
    };

    update(company.id, updateData)
      .then(() => {
        toast.custom((t) => (
          <Toast
            title="Success!"
            visible={t.visible}
            icon={<Icon type={"FillCheck"} className="text-green-500" />}
            subTitle="Company has been updated"
            handleClose={() => toast.dismiss(t.id)}
          />
        ));
        onClose();
      })
      .catch(() => {
        toast.error("Failed to update company. Invalid data");
      });
  };

  const handleSelectCompany = (selectedCompany: CompanyChangeData) => {
    if (!selectedCompany || typeof selectedCompany === "string") {
      setSelectedDealroomCompany(null);
      setValue("dealroomCompany", undefined);
    } else if (isDealroomCompany(selectedCompany)) {
      setSelectedDealroomCompany(selectedCompany);

      setValue("dealroomCompany", selectedCompany.id);

      const location =
        Array.isArray(selectedCompany.hqLocations) && selectedCompany.hqLocations.length
          ? {
              country: selectedCompany.hqLocations[0].country[0]?.name,
              city: selectedCompany.hqLocations[0].city[0]?.name,
            }
          : null;

      selectedCompany.name && setValue("name", selectedCompany.name, { shouldDirty: true });
      selectedCompany.websiteUrl && setValue("websiteUrl", selectedCompany.websiteUrl, { shouldDirty: true });
      selectedCompany.tagline && setValue("description", selectedCompany.tagline, { shouldDirty: true }); // todo or about?
      location && setValue("location", location, { shouldDirty: true });
      Array.isArray(selectedCompany.industries) &&
        selectedCompany.industries.length &&
        setValue(
          "sectors",
          selectedCompany.industries.map((industry) => industry.name),
          { shouldDirty: true },
        );
    }
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  console.log(formState.isValid);
  console.log(formState.isDirty);
  console.log(formState.errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <h2 className="text-xl font-semibold">Edit Company</h2>
      <div className="grid gap-4 lg:grid-cols-2">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormField label="Company name" labelVariant="tertiary">
              <CompanySearchAutocomplete
                value={field.value}
                selectedCompany={selectedDealroomCompany}
                onChange={(company) => {
                  if (typeof company === "object") {
                    handleSelectCompany(company);
                  } else {
                    // clear when custom name is used
                    handleSelectCompany(null);
                  }

                  const companyName = typeof company === "object" ? company?.name : company;
                  field.onChange(companyName ?? "");
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name="websiteUrl"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <FormField
                label="Website Url"
                error={fieldState.error?.message}
                labelVariant="tertiary"
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <InputV2 iconLeft={"Continent"} placeholder={"https://websitename.com"} {...field} />
              </FormField>
            );
          }}
        />
      </div>
      {selectedDealroomCompany != null && form.getFieldState("name").isDirty && (
        <div className="rounded-sm border border-primary-100 bg-primary-50 p-2 text-sm text-primary-500">
          Dealroom company has been selected. We will pull available data from dealroom into current company
        </div>
      )}
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            enableReset={fieldState.isDirty}
            onReset={() => resetField(field.name)}
            error={fieldState.error?.message}
            label={"Description"}
            labelVariant="tertiary"
          >
            <TextArea
              containerClassName="w-full max-h-[230px]"
              placeholder={"Type..."}
              onChange={field.onChange}
              value={field.value}
            />
          </FormField>
        )}
      />

      <div>
        <h3>Details</h3>
        <div className="grid w-full gap-4 lg:grid-cols-2">
          <Controller
            name="location"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="HQ Location"
                labelVariant="tertiary"
                error={[fieldState.error?.message, fieldState.error?.country?.message, fieldState.error?.city?.message]
                  .filter(Boolean)
                  .join("; ")}
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <LocationInputV2
                  value={field.value}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                />
              </FormField>
            )}
          />
          <Controller
            name="sectors"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Industries"
                error={fieldState.error?.message}
                labelVariant="tertiary"
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <SelectV2
                  placeholder="Select..."
                  options={industries}
                  multiple={true}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              </FormField>
            )}
          />
          <Controller
            name="lastRound"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Round"
                error={fieldState.error?.message}
                labelVariant="tertiary"
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <SelectV2
                  placeholder="Select..."
                  options={stages}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              </FormField>
            )}
          />

          <Controller
            name="targetFund"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Target Fund"
                error={fieldState.error?.message}
                labelVariant="tertiary"
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <SelectV2
                  placeholder="Select..."
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  options={targetFund}
                />
              </FormField>
            )}
          />

          <Controller
            name="investmentType"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Core or Strategic"
                error={fieldState.error?.message}
                labelVariant="tertiary"
                enableReset={fieldState.isDirty}
                onReset={() => resetField(field.name)}
              >
                <SelectV2
                  placeholder="Select..."
                  options={[
                    { value: "core", label: "Core" },
                    { value: "strategic", label: "Strategic" },
                  ]}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              </FormField>
            )}
          />
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <Button variant="outline" type="button" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button variant="gradient" type="submit" disabled={!formState.isValid} loading={loading}>
          Save
        </Button>
      </div>
    </form>
  );
};
