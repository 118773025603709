import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";

import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { MnEntry } from "@/app/types";

export const CompanyDetailsEditSchema = z.object({
  name: z.string().optional(),
  websiteUrl: z
    .string()
    .regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/, { message: "Please enter a valid url" })
    .optional(),
  description: z.string().optional(),

  location: z
    .object({
      country: z.string(),
      city: z.string(),
    })
    .optional(),
  sectors: z.array(z.string()).optional(),
  lastRound: z.string().optional(),
  targetFund: z.string().optional(),
  investmentType: z.enum(["core", "strategic"]).optional(),

  dealroomCompany: z.string().optional(),
});

export type CompanyDetailsEditFormData = z.infer<typeof CompanyDetailsEditSchema>;

export const useCompanyDetailsEditForm = (company: OpportunityCompany) => {
  const mnEntryData = (
    company?.companiesSources.items.find((item) => item.sourceType === "MnEntry")?.mnEntryAsSource as MnEntry
  )?.data;

  return useForm<CompanyDetailsEditFormData>({
    resolver: zodResolver(CompanyDetailsEditSchema),
    mode: "all",
    defaultValues: {
      name: company?.name ?? "",
      websiteUrl: company?.websiteUrl ?? "",
      description: company?.about ?? "",

      location: {
        country: company?.country ?? "",
        city: company?.city ?? "",
      },

      sectors: company?.industries ?? [],
      lastRound: mnEntryData?.round ?? "",
      targetFund: company?.targetFund ?? mnEntryData?.target_fund ?? "",
      investmentType: (mnEntryData?.investment_type as "core" | "strategic") ?? undefined,
    },
  });
};
