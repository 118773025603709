import React, { useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { GetOpportunityEmailQuery } from "@/app/service/dashboard.gql.ts";

import { CompanyRawData } from "./add-opportunities-via-email.types.ts";
import { mapCompany } from "./add-opportunities-via-email.utils.ts";
import { AddOpportunityForm } from "./add-opportunity-component.tsx";
import { AlreadyExistingCompany } from "./already-existing-company.tsx";
import { CompaniesFromEmailForm, useAddCompaniesFromEmailForm } from "./use-add-company-from-email-form.ts";
import { useSubmitCompaniesViaEmail } from "./use-submit-companies-via-email.ts";

export const AddOpportunitiesViaEmail = ({ data }: { data: GetOpportunityEmailQuery }) => {
  const { form, fieldArray } = useAddCompaniesFromEmailForm();
  const opportunityEmail = data?.nzrNotification?.nzrOpportunityEmail;
  const { accept, decline, loading } = useSubmitCompaniesViaEmail(data?.nzrNotification?.id, opportunityEmail?.id);

  const companiesFromEmails = opportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes;
  const [companiesAlreadyInNazare, setCompaniesAlreadyInNazare] = useState<CompanyRawData[]>([]);

  const isCompleted = opportunityEmail?.status === "COMPLETED";

  useEffect(() => {
    const { alreadyInNazare, candidates } = (companiesFromEmails ?? []).reduce(
      (res, company) => {
        if (company.lgCompany) {
          return {
            ...res,
            alreadyInNazare: [...res.alreadyInNazare, company],
          };
        }

        return {
          ...res,
          candidates: [...res.candidates, mapCompany(company, opportunityEmail?.source)],
        };
      },
      {
        alreadyInNazare: [],
        candidates: [],
      } as {
        alreadyInNazare: CompanyRawData[];
        candidates: CompaniesFromEmailForm["companies"];
      },
    );

    setCompaniesAlreadyInNazare(alreadyInNazare);

    fieldArray.replace(candidates);
    // trigger validation manually to highlight form errors
    form.trigger();
  }, [companiesFromEmails]);

  const isValid = form.formState.isValid;

  const openEmailDetails = () => {
    globalModalStore.toggleSlideOver({
      isOpen: true,
      slideOverType: "OpportunityEmail",
      navState: { email: opportunityEmail },
    });
  };

  const error = opportunityEmail?.metadata?.error;

  const onSubmit = () => {};

  const handleAccept = () => {
    const { companies } = form.getValues();

    accept(companies);
  };

  const handleAddNewCompany = () => {
    fieldArray.append(mapCompany(null, opportunityEmail?.source));
    // trigger validation manually to highlight form errors
    form.trigger();
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-xl font-semibold">Already in Nazare</h2>
        <div
          className={cn(
            "mt-4",
            companiesAlreadyInNazare.length > 1
              ? "grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3"
              : "flex w-full",
          )}
        >
          {companiesAlreadyInNazare.map((company) => (
            <AlreadyExistingCompany
              key={company.lgCompany?.id ?? company.name}
              company={company}
              className={cn(companiesAlreadyInNazare.length === 1 && "flex-1")}
            />
          ))}
        </div>
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ul className="space-y-3">
            {fieldArray.fields.map((field, index) => (
              <li key={field.id}>
                <AddOpportunityForm index={index} disabled={isCompleted} />
              </li>
            ))}
          </ul>
        </form>
        <div>
          <div className="mt-4 flex flex-row justify-between">
            <div className="flex w-full flex-row gap-2">
              <Button text={"Show original email"} variant="outline" onClick={openEmailDetails} />
              {!isCompleted && (
                <>
                  <Button
                    className="ml-auto"
                    loading={loading}
                    text={"Decline"}
                    variant={"outline"}
                    onClick={decline}
                  />
                  <Button
                    loading={loading}
                    text={"Accept"}
                    variant={"primary"}
                    disabled={!isValid}
                    onClick={handleAccept}
                  />
                </>
              )}
              {!isCompleted && error && (
                <div className="flex items-end justify-end">
                  <Button
                    loading={loading}
                    text="Add"
                    iconLeft="Add"
                    variant={"primary"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddNewCompany();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
