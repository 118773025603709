import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { useEffect, useState } from "react";
import { addMonths } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export type CountsData = {
  sent: number;
  received: number;
  month: string;
};

export type NetworkMessagesCountsBarChartProps = {
  data: CountsData[];
  contactEmail: string;
};

const labelFormatter = new Intl.DateTimeFormat("en-GB", { timeZone: "UTC", month: "short", year: "numeric" });

function dateFromMonth(v: string) {
  const [y, m] = v.split("-");
  return new Date(Date.UTC(+y, +m - 1));
}

function dateToMonth(v: Date) {
  const year = v.getUTCFullYear();
  const month = String(v.getUTCMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
}

export function NetworkMessagesCountsBarChart(props: NetworkMessagesCountsBarChartProps) {
  const { data, contactEmail } = props;

  const [displayData, setDisplayData] = useState<any | null>(null);
  useEffect(() => {
    if (!data?.length) {
      setDisplayData(null);
      return;
    }

    const startDate = dateFromMonth(data[0].month);
    const endDate = dateFromMonth(data.at(-1)!.month);

    const labels: string[] = [];
    const sent: number[] = [];
    const received: number[] = [];

    let i = startDate;
    for (;;) {
      if (+i > +endDate) {
        break;
      }

      labels.push(labelFormatter.format(i));
      const iMonth = dateToMonth(i);
      const row = data.find((e) => e.month === iMonth);
      sent.push(row?.sent || 0);
      received.push(row?.received || 0);
      i = addMonths(new Date(i), 1);
    }

    const incoming = {
      labels,
      datasets: [
        {
          label: "Incoming",
          data: received,
          maxBarThickness: 50,
          // backgroundColor: "#e9366b",
          backgroundColor: "#13c2c2",
          borderRadius: 3,
        },
        {
          label: "Outgoing",
          data: sent,
          maxBarThickness: 50,
          // backgroundColor: "#1677ff",
          backgroundColor: "#1677ff",
          borderRadius: 3,
        },
      ],
    };

    setDisplayData(incoming);
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      /*
      title: {
        display: true,
        text: `Communication with ${contactEmail}`,
      },*/
      tooltip: {
        mode: "index" as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
          text: "Date",
        },
        grid: {
          drawTicks: true,
          color: (context) => {
            if (!context.tick) return "#eee";
            
            const { label }=  context.tick;
            if (label && typeof label === "string" && label.startsWith("Jan ")) {
              return "#aaa";
            }

            return "#eee";
          },

          tickBorderDash: [5, 5],
          tickBorderDashOffset: 5,
        },
      },
      y: {
        stacked: true,
        beginAtZero: false,
        title: {
          display: false,
          text: "Messages",
        },
      },
    },
  };

  if (!displayData) return null;
  return <Bar data={displayData} options={options} />;
}

export default NetworkMessagesCountsBarChart;
