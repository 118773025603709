import { useState } from "react";

import { cn, parseUrl } from "@/lib/utils";

import { Icon } from "../icon";

export interface CompanyImgProps {
  src?: string | null;
  className?: string;
  imgClassName?: string;
  onClick?: (...args) => void;
  iconType?: "OpportunityPlaceholder" | "Users";
}
export const CompanyImg = ({
  src,
  className,
  imgClassName,
  onClick,
  iconType = "OpportunityPlaceholder",
}: CompanyImgProps) => {
  const [failedToLoad, setFailed] = useState(false);
  const url = parseUrl(src);
  return (
    <div
      className={cn(
        "flex size-11 min-w-11 items-center overflow-hidden rounded-md border border-neutral-200 p-0 2xl:size-12 2xl:min-w-12",
        className,
      )}
      onClick={onClick}
    >
      {url && !failedToLoad ? (
        <img
          alt={"Company Image"}
          className={cn("aspect-1 w-full rounded-md", imgClassName)}
          src={url}
          onError={() => setFailed(true)}
        />
      ) : (
        <Icon type={iconType} className={cn("aspect-auto w-full ring-1", imgClassName)} />
      )}
    </div>
  );
};
