import { InputMaybe, GetCompanyAssigneesDocument, LgMembersDocument, GetCompanyAssigneesQuery } from "@/gql/graphql";

import { apolloClient } from "@/app/config/initConfig";

export type AssigneeEdges = NonNullable<GetCompanyAssigneesQuery["lgAssignees"]>["edges"];

export const readAssigneesCache = (companyId: string) => {
  const cachedAssignees = apolloClient.readQuery({
    query: GetCompanyAssigneesDocument,
    variables: {
      companyId,
    },
  });

  return cachedAssignees?.lgAssignees?.edges?.map((node) => node) || [];
};

export const readLgMembersCache = (memberIds: InputMaybe<string>[]) => {
  const cachedLgMembers = apolloClient.readQuery({
    query: LgMembersDocument,
    variables: { filter: { isInvestor: { equalTo: true } } },
  });

  return cachedLgMembers?.lgMembers?.nodes?.filter(({ id }) => memberIds.includes(id)) || [];
};

export const writeAssigneesCache = (companyId: string, edges: AssigneeEdges) => {
  apolloClient.writeQuery({
    query: GetCompanyAssigneesDocument,
    variables: {
      companyId,
    },
    data: {
      lgAssignees: {
        edges,
      },
    },
  });
};
