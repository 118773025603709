import { FetchResult } from "@apollo/client";
import z from "zod";

import { UpdateCompanyDetailsMutation } from "@/gql/graphql.ts";

import { GetCompanyDocument, useUpdateCompanyDetailsMutation } from "@/app/service/opportunities.gql.ts";
import { GetCompanyFoundersDocument } from "@/app/service/company-founders.gql.ts";

/**
 * request data when updating company details
 * should match ManualOpportunityInput
 */
export const CompanyDetailsUpdateSchema = z.object({
  name: z.string().optional(),

  about: z.string().optional(),

  city: z.string().optional(),
  country: z.string().optional(),

  industries: z.array(z.string()).optional(),

  // investmentType: z.enum(["core", "strategic"]).optional(),

  round: z.string().optional(),
  targetFund: z.string().optional(),
  websiteUrl: z.string().optional(),

  dealroomCompany: z.string().optional(),
});

export type CompanyDetailsData = z.infer<typeof CompanyDetailsUpdateSchema>;

export const useUpdateCompanyDetails = (): [
  (companyId: string, data: CompanyDetailsData) => Promise<FetchResult<UpdateCompanyDetailsMutation>>,
  {
    loading: boolean;
  },
] => {
  const [updateCompanyDetails, { loading }] = useUpdateCompanyDetailsMutation();

  const handleUpdate = (companyId: string, data: CompanyDetailsData) => {
    const { error, success } = CompanyDetailsUpdateSchema.safeParse(data);

    if (success) {
      return updateCompanyDetails({
        variables: {
          companyId,
          input: data,
        },
        refetchQueries: [GetCompanyDocument, GetCompanyFoundersDocument],
      });
    } else {
      console.log(error);
      // todo sentry
      return Promise.reject(error);
    }
  };

  return [handleUpdate, { loading }];
};
