/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react";

import { Combobox, ComboboxButton, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { CheckIcon } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "@/app/components";
import { combineTailwindStyles } from "@/app/misc/helpers.ts";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";

import { MultiInput } from "./multi-input";

export type SelectProps = {
  options: OptionsType[];
  defaultOptions?: OptionsType[];
  autoFocus?: boolean;
  multiSelect?: boolean;
  required?: boolean;
  placeholder?: string;
  action?: string;
  iconRight?: IconType | null;
  iconLeft?: IconType;
  optionsDirection?: "bottom" | "top";
  disableInputFocus?: boolean;
  loading?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  styles?: {
    containerClassName?: string;
    optionsClassName?: string;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
  };
  onChange?: (value: OptionsType[]) => void;
};

const Select = ({
  options = [],
  defaultOptions = [],
  iconLeft,
  autoFocus = false,
  iconRight = "",
  multiSelect = false,
  canDelete = true,
  placeholder = "",
  optionsDirection = "bottom",
  disableInputFocus = false,
  loading = false,
  disabled = false,
  styles = {
    containerClassName: "",
    labelClassName: "",
    className: "",
    inputClassName: "",
    optionsClassName: "",
  },
  onChange,
  ...props
}: SelectProps) => {
  const { containerClassName, className, inputClassName, labelClassName, optionsClassName } = styles;
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
  const [query, setQuery] = useState<string>("");

  const handleRemove = (option: OptionsType) => {
    const updatedOptions = selectedOptions.filter(({ value }) => value !== option?.value);
    setSelectedOptions(updatedOptions);

    if (onChange) {
      onChange(updatedOptions);
    }
  };

  const handleMultiSelect = (options: OptionsType[]) => {
    setQuery("");
    setSelectedOptions(options);

    if (onChange) onChange(options);
  };

  const handleSelect = (option: OptionsType) => {
    setQuery("");
    setSelectedOptions([option]);

    if (onChange) onChange([option]);
  };

  const filteredValues: Array<OptionsType> =
    query === ""
      ? options
      : options.filter(({ label, value }: OptionsType) => {
          return (
            label.toLowerCase().includes(query.toLowerCase()) && !selectedOptions?.some((opt) => opt.value === value)
          );
        });

  return (
    <div {...props} className={`flex w-full max-w-full flex-col items-start ${containerClassName}`}>
      <Combobox
        as={"div"}
        multiple={multiSelect}
        disabled={disabled}
        className={`w-full ${className}`}
        onChange={multiSelect ? handleMultiSelect : handleSelect}
        onClose={() => setQuery("")}
        value={multiSelect ? selectedOptions : selectedOptions[0]}
      >
        <div className="relative w-full rounded-tremor-default bg-white">
          <MultiInput
            canDelete={canDelete}
            autoFocus={autoFocus}
            iconLeft={loading ? "Loader" : iconLeft}
            containerClassName="pr-8"
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            disableFocus={disableInputFocus}
            selectedOptions={selectedOptions}
            disabled={disabled}
            placeholder={placeholder}
            query={query}
            onQueryChange={setQuery}
            onRemove={handleRemove}
          />
          {iconRight !== null && (
            <ComboboxButton className="absolute inset-y-0 flex w-full max-w-full items-center justify-end rounded-r-md px-4">
              <Icon
                data-cy="select-button-icon"
                type={optionsDirection === "bottom" ? "Filter Chevron Down" : "Filter Chevron Up"}
                className="size-5 text-black"
                aria-hidden="true"
              />
            </ComboboxButton>
          )}

          {filteredValues.length > 0 && (
            <ComboboxOptions
              className={cn(
                `text-md absolute z-20 mt-3 max-h-[40vh] w-full divide-y divide-neutral-200 overflow-auto rounded-md bg-white py-1 shadow-sm`,
                { "bottom-14": optionsDirection === "top" },
                optionsClassName,
              )}
            >
              {filteredValues
                .sort((a) => {
                  if (!multiSelect) return 0;
                  return selectedOptions.find((e) => e.value === a.value) ? -1 : 1;
                })
                .map((option, index) => (
                  <ComboboxOption
                    data-cy="select-option"
                    key={option.label + index}
                    value={option}
                    className={({ focus }) =>
                      cn("relative select-none hover:cursor-pointer", focus ? "bg-neutral-200" : "")
                    }
                  >
                    {({ focus, selected }) => (
                      <div className="size-full px-3 py-4">
                        <div className="flex items-center">
                          <span className={combineTailwindStyles("ml-3 truncate", selected && "text-primary")}>
                            {option.label}
                          </span>
                        </div>

                        {selected && canDelete && (
                          <span
                            className={combineTailwindStyles(
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              focus ? "text-primary" : "text-primary",
                            )}
                          >
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                    )}
                  </ComboboxOption>
                ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export const WebSelect = React.memo<SelectProps>(Select);
