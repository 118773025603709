import { cn } from "@/lib/utils.ts";

import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";

export const EmptySection = ({ text, Action }: { text: string; Action?: React.ReactNode }) => {
  return (
    <section
      className={cn(
        "flex h-[200px] w-full flex-col items-center justify-center bg-white",
        sectionBorderRadiusClassName,
      )}
    >
      <p className="text-xl font-semibold text-neutral-300">{text}</p>
      {Action ? Action : null}
    </section>
  );
};
