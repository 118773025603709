/// <reference types="@types/google.maps" />

import { useEffect } from "react";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { Autocomplete } from "@/app/components/forms/autocomplete.tsx";
import { googleMapsApiKey } from "@/app/config/google-maps.ts";

import PlaceResult = google.maps.places.PlaceResult;

type PlaceDetails = google.maps.places.AutocompletePrediction;

type LocationValue = {
  country: string;
  city: string;
  place_id?: string; // google place id
};

const usePlacesAutocomplete = () => {
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: googleMapsApiKey,
  });

  const getPredictions = (searchText: string) => {
    return getPlacePredictions({
      input: searchText,
      types: ["(cities)"], // limit to country and city as we don't need detailed address
    });
  };

  const getPlaceDetails = async (placeId: string): Promise<{ country: string; city: string }> => {
    return new Promise<LocationValue>((resolve, reject) => {
      placesService?.getDetails(
        {
          placeId,
        },
        (placeDetails: PlaceResult | null) => {
          if (!placeDetails?.address_components) {
            reject(`Could not find details for place: ${placeId}`);
            return;
          }

          const response: LocationValue = placeDetails.address_components?.reduce(
            (acc, component) => {
              if (component.types.includes("country")) {
                acc.country = component.long_name;
              }
              if (component.types.includes("locality")) {
                acc.city = component.long_name;
              }
              return acc;
            },
            {
              country: "",
              city: "",
            },
          );
          resolve(response);
        },
      );
    });
  };

  return {
    getPredictions,
    getPlaceDetails,
    placePredictions,
    isPlacePredictionsLoading,
  };
};

interface LocationInputProps {
  value: LocationValue;
  onChange: (value: LocationValue) => void;
}

const getLocationDescription = (location: LocationValue): string => {
  return [location.city, location.country].filter(Boolean).join(", ");
};

export const LocationInputV2 = ({ value, onChange }: LocationInputProps) => {
  const { getPredictions, getPlaceDetails, placePredictions, isPlacePredictionsLoading } = usePlacesAutocomplete();

  useEffect(() => {
    if (value) {
      getPredictions(getLocationDescription(value));
    }
  }, []);

  const handleSelect = (value: PlaceDetails | null) => {
    if (!value) {
      onChange({ country: "", city: "" });
    } else {
      getPlaceDetails(value.place_id)
        .then((response) => {
          onChange(response);
        })
        .catch((e) => {
          // todo sentry?
          console.error(e);
        });
    }
  };

  const handleClear = () => {
    onChange({ country: "", city: "" });
    getPredictions("");
  };

  const options = placePredictions.map((place) => ({
    label: place.description as string,
    value: place,
  }));

  return (
    <Autocomplete
      options={options}
      loading={isPlacePredictionsLoading}
      value={value}
      multiple={false}
      getOptionKey={(option) => option.place_id ?? option.description}
      onQueryChange={getPredictions}
      getDisplayValue={getLocationDescription}
      onClear={handleClear}
      onChange={handleSelect}
    />
  );
};
