import { useState } from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router";

import { CreateManualOpportunityDataInput } from "@/gql/graphql.ts";

import { CompanyDataSchema } from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import { CompanySignalSchema } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { CompanySignals } from "@/app/screens/add-company/company-signals-step/company-signals.tsx";
import { useAddOpportunityMutation } from "@/app/service/opportunities.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CompanyData } from "./company-data-step/company-data.tsx";

const useAddCompanySteps = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  return {
    currentStep,
    next: () => setCurrentStep(currentStep + 1),
    back: () => {
      setCurrentStep(currentStep - 1);
    },
    cancel: () => {
      navigate(-1);
    },
  };
};

export const AddCompanyView = () => {
  const [addOpportunity, { loading }] = useAddOpportunityMutation();
  const navigate = useNavigate();
  const [companyMainData, setCompanyMainData] = useState<CompanyDataSchema>({});

  const { currentStep, next, back, cancel } = useAddCompanySteps();

  const handleCreate = (companySignalData: CompanySignalSchema) => {
    const values = { ...companyMainData, ...companySignalData };

    const {
      // TODO: remove default value when BE is refactored to accept undefined name
      name = "Stealth Company",
      founders,
      fund = "",
      websiteUrl,
      note,
      sourceName,
      sourceType,
      introAvailable,
      highPriority,
      ballInOurControl,
      activelyRaising,
      dealroomCompany,
    } = values;

    /** todo either dealroom company or manual entry
     * something like:
     * {
     *  dealroomCompany: "id";
     *  manualEntry: {
     *    name: string;
     *    website: string;
     *    founders: {linkedinUrl: string; name: string}[]
     *  }
     *  signal: {
     *       note?: string;
     *       sourceName: string;
     *       sourceType: string;
     *       introAvailable: boolean;
     *       highPriority:  boolean;
     *       ballInOurControl:  boolean;
     *       activelyRaising:  boolean;
     *  }
     * }
     */
    const data: CreateManualOpportunityDataInput = {
      name,
      websiteUrl,
      targetFund: fund,
      founders:
        founders?.map((founder) => ({
          ...founder,
          dealroomFounder: founder.dealroomFounder?.id,
          lgFounder: founder.lgFounder?.id,
        })) ?? [],
      note,
      source: sourceName,
      sourceType,
      canIntro: introAvailable,
      highPriority,
      ballInControl: ballInOurControl,
      isActivelyFundraising: activelyRaising,
      assignees: [authStore.userId],
      dealroomCompany: dealroomCompany?.id,
    };

    console.log(data);

    addOpportunity({
      variables: {
        input: data,
      },
    }).then((res) => {
      if (res.data?.opportunityNew.id) {
        navigate(`/company/${res.data?.opportunityNew.id}`);
      }
    });
  };

  return (
    <div className="flex size-full h-screen flex-col items-center justify-between bg-white">
      <div className="flex size-full justify-center overflow-y-auto py-[2%]">
        <section className="size-full max-w-[680px] space-y-10 p-4">
          <h1 className="text-2xl font-bold text-neutral-900">Add a new company</h1>
          {currentStep === 0 && (
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <CompanyData
                data={companyMainData}
                onSubmit={(data) => {
                  setCompanyMainData(data);
                  next();
                }}
                onCancel={cancel}
              />
            </motion.div>
          )}
          {currentStep === 1 && (
            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <CompanySignals
                onSubmit={(data) => {
                  handleCreate(data);
                }}
                onCancel={back}
                loading={loading}
                companyData={companyMainData}
              />
            </motion.div>
          )}
        </section>
      </div>
    </div>
  );
};
