import { DealroomCompanyResult } from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";

// todo param type
export const isDealroomCompany = (company): company is DealroomCompanyResult => {
  return "__typename" in company && company.__typename === "DealroomCompany";
};

// todo param type
export const isNewCompany = (company): company is NewCompany => {
  return "isNewCompany" in company && company.isNewCompany;
};

export type CompanySignalToggles =
  | "introAvailable"
  | "highPriority"
  | "ballInOurControl"
  | "activelyRaising"
  | "isDeepTech";

export type NewCompany = {
  name: string;
  image: null;
  websiteUrl: string;
  about: null;
  isNewCompany: true;
};
