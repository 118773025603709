import { cn } from "@/lib/utils.ts";

import { TooltipInfo } from "@/app/components";

import { BasecampList } from "../opportunity/components/access";
import { MemberList } from "../opportunity/components/access/member-list";

import { NotAvailable } from "./not-available";

export const AccessOverview = ({ assignees, basecamp, showAll = false, isSad = false, inTable = false }) => {
  const isEmpty = assignees.length === 0 && !basecamp;

  return isEmpty ? (
    <NotAvailable
      containerStyle="justify-start"
      message={showAll ? "N/A" : "No Data"}
      textStyle={cn("lg:text-xs 2xl:text-sm", showAll ? "text-black" : "text-neutral-300")}
      tooltipContainer="max-w-[200px] mt-3"
      isSad={showAll && isSad}
      tooltipInfo={
        <TooltipInfo warning text="It looks like we meet the company but we don't have anyone associated with it" />
      }
    />
  ) : (
    <div className="flex items-center justify-start">
      {assignees.length > 0 && (
        <MemberList
          members={assignees}
          labelClassName="inline-block whitespace-nowrap text-xxs font-medium leading-4 lg:text-xs 2xl:text-sm"
          containerClassName="flex transform md:-translate-y-1 mr-2 items-center"
          imgClassName="lg:size-4.5 lg:min-w-4.5 2xl:size-6 2xl:min-w-6 md:h-6 md:w-6 rounded-xs "
          includeName={false}
          split={basecamp ? 1 : inTable ? 2 : 4}
          withTooltip
        />
      )}
      {basecamp && (
        <BasecampList
          basecamp={basecamp}
          label="Basecamp"
          className="mr-1 size-6 rounded-xs object-contain md:min-w-6 lg:size-4 lg:min-w-4 2xl:size-4.5 2xl:min-w-4.5"
          labelClassName="inline-block whitespace-nowrap text-xxs font-medium leading-4 md:text-sm mr-1 lg:text-xs 2xl:text-sm"
          containerClassName="flex transform md:-translate-y-1"
          split={assignees.length > 0 ? 1 : inTable ? 2 : 4}
        />
      )}
    </div>
  );
};
