import { Switch } from "@headlessui/react";
import { motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { ProgressSpinner } from "@/app/components";

export type BaseToggleProps = {
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  size?: "sm" | "md" | "lg";
  showLabels?: boolean;
  loading?: boolean;
  className?: string;
  "data-cy"?: string;
};

export function BaseToggle({
  value = false,
  onChange,
  disabled,
  size = "md",
  showLabels = true,
  loading,
  className,
  ...props
}: BaseToggleProps) {
  if (className?.includes("p-") || className?.includes("w-") || className?.includes("h-")) {
    console.warn("A custom sizing used inside switch toggle");
  }

  return (
    <Switch
      data-cy={props["data-cy"]}
      checked={value}
      disabled={disabled || loading}
      onChange={onChange}
      className={cn(
        "relative flex shrink-0 cursor-pointer items-center justify-around overflow-hidden border-transparent bg-gray-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-offset-2",
        size === "lg" && "h-8 w-16 rounded-xs p-1 text-sm",
        size === "md" && "h-6 w-12 rounded-xxs p-1 text-xs",
        size === "sm" && "h-4 w-8 rounded-xxs p-0.5 text-xxxs",
        value && "bg-gradient-to-r from-primary-400 to-secondary-400",
        "disabled:cursor-not-allowed disabled:bg-neutral-400",
        className,
      )}
    >
      {showLabels && (
        <>
          <span
            className={cn(
              "absolute font-medium text-white opacity-100 transition-opacity",
              !value && "opacity-0",
              size === "lg" && "left-2.5",
              size === "md" && "left-1.5",
              size === "sm" && "left-1",
            )}
          >
            Yes
          </span>
          <span
            className={cn(
              "absolute font-medium text-black opacity-100 transition-opacity",
              size === "lg" && "right-2.5",
              size === "md" && "right-2",
              size === "sm" && "right-1",
              value && "opacity-0",
            )}
          >
            No
          </span>
        </>
      )}
      <div className={cn("flex size-full justify-start", value && "justify-end")}>
        <motion.div
          className={cn(
            "pointer-events-none flex aspect-square h-full w-auto items-center justify-center overflow-hidden",
            size === "lg" && "rounded-xs",
            size === "md" && "rounded-xxs",
            size === "sm" && "rounded-[2px]",
            !loading && "bg-white ring-0",
          )}
          layout
          transition={{
            type: "spring",
            stiffness: 600,
            damping: 30,
          }}
        >
          {loading && <ProgressSpinner className={cn("size-4/5", value && "text-primary")} />}
        </motion.div>
      </div>
    </Switch>
  );
}
