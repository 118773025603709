import React, { useState } from "react";

import { Control, Controller } from "react-hook-form";

import { cn, getInitials } from "@/lib/utils.ts";

import { Button, Icon, ImageWithErrorProfile } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import {
  CompanyDataSchema,
  FoundersFieldArray,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import {
  FounderSearchAutocomplete,
  FounderSearchResult,
  isDealroomFounder,
  isLgFounder,
} from "@/app/screens/opportunities/components/founder-search-autocomplete.tsx";

const getImageUrl = (url: string): string => {
  return url.includes("http") ? url : "https://" + url;
};

const FounderPreview = ({ founder }: { founder: FounderSearchResult }) => {
  return (
    <div className={cn("flex flex-1 items-center gap-2")}>
      <ImageWithErrorProfile
        className={cn("size-16 shrink-0 rounded-sm bg-neutral-300")}
        labelClassName={"text-white text-3xl"}
        src={founder.image ? getImageUrl(founder.image) : null}
        alt={founder.name ? getInitials(founder.name) : "N/A"}
      />
      <span className="text-lg font-semibold">{founder.name}</span>
      {founder.linkedinUrl && (
        <a
          className={"flex cursor-pointer select-none items-center text-sm font-semibold text-linkedin underline"}
          href={founder.linkedinUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Icon type={"Linkedin"} className="size-4" />
          <span className="ml-0.5">{(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}</span>
        </a>
      )}
    </div>
  );
};

const FounderInputField = ({
  control,
  index = 0,
  handleRemove,
  disabled,
}: {
  control: Control<CompanyDataSchema>;
  index?: number;
  handleRemove?: () => void;
  disabled?: boolean;
}) => {
  const [selectedFounder, setSelectedFounder] = useState<FounderSearchResult | null>(null);

  return (
    <Controller
      control={control}
      disabled={disabled}
      name={`founders.${index}`}
      render={({ field, fieldState }) => (
        <div className="flex items-center justify-between gap-2">
          {!!selectedFounder && <FounderPreview founder={selectedFounder} />}
          {!selectedFounder && (
            <div
              className={cn(
                "mb-3 mt-4 grid flex-1 gap-2 rounded-xs bg-neutral-200 p-2 lg:grid-cols-2 lg:bg-transparent lg:p-0",
              )}
            >
              <FormField
                label={index === 0 ? "Founder name" : ""}
                labelVariant={"secondary"}
                error={fieldState.error?.details?.name?.message}
                styles={{ container: "space-y-0 lg:space-y-2", label: "hidden lg:inline" }}
              >
                <FounderSearchAutocomplete
                  value={field.value.details?.name}
                  selectedFounder={selectedFounder}
                  onChange={(founder) => {
                    if (typeof founder === "object") {
                      setSelectedFounder(founder);
                    } else {
                      // clear when custom name is used
                      setSelectedFounder(null);
                    }

                    if (isDealroomFounder(founder)) {
                      field.onChange({ dealroomFounder: founder });
                    } else if (isLgFounder(founder)) {
                      field.onChange({ lgFounder: founder });
                    } else {
                      field.onChange({
                        details: {
                          ...(field.value.details ?? {}),
                          name: founder ?? "",
                        },
                      });
                    }
                  }}
                />
              </FormField>
              <FormField
                label={index === 0 ? "Linkedin URL" : ""}
                labelVariant={"secondary"}
                error={fieldState.error?.details?.url?.message}
                styles={{ label: "hidden lg:inline" }}
              >
                <div className="flex items-center gap-2">
                  <InputV2
                    iconLeft={"Linkedin"}
                    placeholder="https://linkedin.com/in/user-name"
                    value={field.value?.details?.url}
                    onChange={({ target }) =>
                      field.onChange({
                        ...field.value,
                        details: { ...(field.value.details ?? {}), url: target.value },
                      })
                    }
                    disabled={field.disabled}
                  />
                  <IconButtonV2 icon="X" className="hidden lg:block" onClick={handleRemove} disabled={field.disabled} />
                </div>
              </FormField>
            </div>
          )}
        </div>
      )}
    />
  );
};

export const CompanyFoundersFormData = ({
  control,
  fieldArray,
}: {
  fieldArray: FoundersFieldArray;
  control: Control<CompanyDataSchema>;
}) => {
  const { fields, append, remove } = fieldArray;

  const handleAddNewFounder = () => {
    append({});
  };

  return (
    <FormField label="Founders" styles={{ container: "last:space-y-2" }}>
      {fields.map((fieldArrayItem, index) => (
        <FounderInputField key={fieldArrayItem.id} control={control} index={index} handleRemove={() => remove(index)} />
      ))}
      <Button
        className="mx-auto px-2.5 lg:mx-0 lg:w-1/3"
        type="button"
        variant={"outline"}
        text={"Add Founder"}
        size={"md"}
        onClick={handleAddNewFounder}
      />
    </FormField>
  );
};
