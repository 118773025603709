import { cn, getInitials, windowOpen } from "@/lib/utils.ts";

import { Dropdown, DropdownOption, Icon, ImageWithErrorProfile } from "@/app/components";

import { FounderSentiment } from "./founder-sentiment.tsx";
import { FounderDisplayData, FounderFromTeam } from "./founders.types.ts";

type FounderProps = {
  founder: FounderDisplayData;
  onRemove: () => void;
  onEdit?: () => void;
};

const isFounderFromTeamData = (founder: FounderDisplayData): founder is FounderFromTeam => {
  return "id" in founder;
};

const FounderEditButtons = ({ className, actions }: { actions: DropdownOption[]; className: string }) => {
  return (
    <Dropdown options={actions} className={cn(className)}>
      <Dropdown.Button onClick={(e) => e.stopPropagation()} size="sm">
        <Icon type={"MoreVertical"} className={"size-4"} />
      </Dropdown.Button>

      <Dropdown.Items className={"max-h-40"}>
        {({ label, action }) => (
          <Dropdown.Item className={"py-2 md:py-3"} key={label} onClick={action}>
            <span className={cn("truncate text-black")}>{label}</span>
          </Dropdown.Item>
        )}
      </Dropdown.Items>
    </Dropdown>
  );
};

const getImageUrl = (url: string): string => {
  return url.includes("http") ? url : "https://" + url;
};

export const Founder = ({ founder, onRemove }: FounderProps) => {
  const isFullFounderData = isFounderFromTeamData(founder);

  const actions: DropdownOption[] = [{ label: "Remove", action: onRemove }];

  return (
    <div className={cn("group flex w-full flex-col justify-between gap-2 lg:flex-row lg:items-center")}>
      <div className="flex w-full items-center justify-start gap-4">
        <ImageWithErrorProfile
          className={cn("size-16 shrink-0 rounded-sm bg-neutral-300")}
          labelClassName={"text-white text-3xl"}
          src={isFullFounderData && founder.image ? getImageUrl(founder.image) : null}
          alt={founder.name ? getInitials(founder.name) : "N/A"}
        />
        <div className="w-full items-center justify-between">
          <div className="flex h-full flex-col justify-center space-y-2">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold">{founder.name}</span>
              {founder?.linkedinUrl && (
                <span
                  className={"flex cursor-pointer select-none items-center"}
                  onClick={() => windowOpen(founder.linkedinUrl!, "_blank")}
                >
                  <Icon type={"Linkedin"} className="size-4 text-linkedin" />
                  <span className="ml-0.5 text-sm font-semibold text-linkedin underline">
                    {(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}
                  </span>
                </span>
              )}
            </div>
            {isFullFounderData && !!founder.titles?.length && (
              <span className="w-fit text-nowrap text-sm font-medium text-neutral-600">
                {founder.titles.reduce((acc, item, idx) => acc + (idx ? " • " : "") + item.name, "")}
              </span>
            )}
          </div>
        </div>
        <FounderEditButtons actions={actions} className="lg:hidden" />
      </div>
      <div className="flex items-center gap-2">
        {isFullFounderData && <FounderSentiment founder={founder} />}
        <FounderEditButtons actions={actions} className="hidden lg:flex" />
      </div>
    </div>
  );
};
