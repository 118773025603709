import { cn } from "@/lib/utils";

export const CardLabel = ({ label, className }: { label?: string | null; className?: string }) => {
  if (!label) {
    return null;
  }

  return (
    <span
      className={cn(
        "w-fit select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-neutral",
        className,
      )}
    >
      {label}
    </span>
  );
};
