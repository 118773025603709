import React from "react";

import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router";

import { Icon } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar";
import { Routes } from "@/app/constants";
import { useGetOpportunityEmailSuspenseQuery } from "@/app/service/dashboard.gql.ts";

import { AddOpportunitiesViaEmail } from "../add-new/via-email/add-opportunities-via-email.tsx";

export const EmailActionView = observer(({ navigationPath }: { navigationPath?: { url: string; label: string } }) => {
  const { id } = useParams();

  const { data } = useGetOpportunityEmailSuspenseQuery({
    variables: { id },
    skip: !id,
  });
  const emailId = data.nzrNotification?.nzrOpportunityEmail?.id;
  const navigate = useNavigate();
  const navigatePath = navigationPath?.url ?? Routes.home;

  const error = data?.nzrNotification?.nzrOpportunityEmail?.metadata?.error;

  if (!id || !emailId) {
    return;
  }

  return (
    <div className="flex size-full flex-col items-center overflow-auto bg-background p-8 px-[2%] pb-[82px] md:px-[5%] lg:px-[10%] lg:pb-2">
      <div id="header" className={"mb-10 flex w-full flex-row items-center justify-between"}>
        <div>
          <BreadCrumbs
            title={navigationPath?.label ?? "Opportunities via email"}
            action={() => navigate(navigatePath)}
          />
        </div>
        {error && (
          <div>
            <div
              className={"flex h-1/5 w-full cursor-pointer select-none items-center rounded-md p-4 hover:bg-neutral-50"}
            >
              <div className={"flex items-center justify-center"}>
                <Icon type={"Shield Alert"} className={"mr-2 size-10 text-red"} />
              </div>
              <div className={"flex flex-col"}>
                <p className="text-lg font-semibold" align={"justify"}>
                  Email parsing failed.
                </p>
                <p className="text-sm font-semibold italic text-neutral-600" align={"justify"}>
                  Please manually add required opportunities below.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={"mb-10 w-full"}>
        <AddOpportunitiesViaEmail data={data} />
      </div>
    </div>
  );
});
