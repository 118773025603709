import { useCallback, useMemo, useState } from "react";

import { Transition, TransitionChild } from "@headlessui/react";
import { useNavigate } from "react-router";

import { Loader, Modal, SearchBar } from "@/app/components";
import { Routes } from "@/app/constants";
import { useSearch, START_QUERY_AFTER } from "@/app/hooks";
import modalStore from "@/app/stores/modal.store";

import { SearchResults } from "./components";

export const Search = () => {
  const navigate = useNavigate();
  const [show, toggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { search, queryRef, loading } = useSearch();

  const handleSearch = (query: string) => {
    const shouldQuery = query.length > START_QUERY_AFTER;
    toggle(shouldQuery);
    if (shouldQuery) {
      search({ name: { fuzzy: query } });
      setSearchTerm(query);
    }
  };

  const goTo = useCallback(
    (path: string) => {
      modalStore.close();
      navigate(`company/${path}`);
    },
    [navigate],
  );

  const Content = useMemo(() => {
    if (queryRef) {
      return (
        <SearchResults
          queryRef={queryRef}
          searchTerm={searchTerm}
          handleNavigation={goTo}
          handleAddNewOpportunity={() => {
            navigate(Routes.addCompany);
            modalStore.close();
          }}
          loading={loading}
          containerClassName="grid grid-cols-3"
        />
      );
    }

    return null;
  }, [searchTerm, queryRef, loading, navigate, goTo]);

  return (
    <Modal
      isOpen={true}
      showX={false}
      handleClose={modalStore.close}
      bodyContainerClassName="md:items-start mt-24"
      bodyClassName="h-auto w-auto bg-transparent max-w-[920px] w-[75dvw] min-w-[400px] py-0"
      bodyContentClassName="rounded-lg bg-white overflow-hidden p-0"
    >
      <SearchBar onQueryChange={handleSearch} className="border-transparent" />
      <Transition show={show} as={"div"}>
        <TransitionChild
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative h-[500px] w-full border-t border-neutral-200">
            <Loader className={`${loading ? "z-10" : "hidden"}`} />
            {Content}
          </div>
        </TransitionChild>
      </Transition>
    </Modal>
  );
};
