import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { cn } from "@/lib/utils.ts";

import { Button, Icon } from "@/app/components";
import { titleCase } from "@/app/misc/helpers.ts";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { SearchMobile } from "@/app/screens/modal/search";
import { opportunityFilterStore } from "@/app/screens/opportunities";
import filterStore from "@/app/screens/opportunities/filter.store.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import modalStore from "@/app/stores/modal.store";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";

const displaySubNavbarRoutes = ["/prospects", "/pipeline", "/portfolio"];
const displayNavbarRoutes = [...displaySubNavbarRoutes, "/home", "/network", "/forms", "/settings"];

export const MobileNavbar = observer(function MobileNavbar() {
  const { pathname } = useLocation();
  // TODO: make this more robust, looks very limited
  if (!displayNavbarRoutes.includes(pathname) && !pathname.startsWith("/network")) return null;
  let customTitle;
  if (pathname.startsWith("/network/contact")) {
    customTitle = "Network Contact";
  }
  return (
    <div className="z-10 fixed flex h-fit w-full flex-col lg:hidden">
      <nav className="flex w-full items-center justify-between bg-[#F7F7F7] px-6 py-3">
        <div className={"flex items-center"}>
          <Icon
            type={"BurgerMenu"}
            width={24}
            height={24}
            className={"mr-3 fill-red"}
            onClick={() => {
              globalModalStore.toggleSlideOver({
                isOpen: true,
                slideOverType: "Settings",
              });
            }}
          />
          <span className="text-xl font-semibold">{customTitle || titleCase(pathname.substring(1))}</span>
        </div>

        <div className={"flex items-center space-x-3 gap-2"}>
          <Icon
            type="Search"
            width={20}
            height={20}
            className="ml-auto text-black"
            onClick={() => modalStore.open(SearchMobile)}
            data-cy="navbar-search-call-mobile"
          />
          {displaySubNavbarRoutes.includes(pathname) && (
            <div className={"relative"}>
              {Boolean(filterStore.filtersActive) && (
                <span className="absolute left-0 top-0 -ml-2 -mt-2 flex size-4 select-none items-center justify-center rounded-full bg-primary text-xs font-medium text-white transition-transform duration-200 ease-in-out">
                  {String(filterStore.filtersActive)}
                </span>
              )}
              <Icon
                type="Filter"
                width={20}
                height={20}
                className="ml-auto text-black"
                onClick={() => {
                  globalModalStore.navigate({
                    modalType: "OpportunityFilter",
                    navState: {},
                  });
                }}
              />
            </div>
          )}
        </div>
      </nav>
      {displaySubNavbarRoutes.includes(pathname) && <MobileSubNavbar />}
    </div>
  );
});

const MobileSubNavbar = observer(() => {
  const itemsCount = opportunitiesStore.state.totalOpportunityCount
    ? `${opportunitiesStore.state.opportunitiesLength} out of ${String(
        opportunitiesStore.state.totalOpportunityCount,
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    : "";

  return (
    <div className={"w-full bg-[#F7F7F7]"}>
      <nav className={"no-scrollbar flex gap-x-4 ml-6 pr-6 mt-0.5 overflow-auto"}>
        {opportunitiesStore.opportunityTab.map(({ value, label }, index) => {
          const isSelected = opportunitiesStore?.state?.currentTab.quickView === value;
          return (
            <div autoFocus={isSelected} key={index} className={"flex min-w-fit items-center text-nowrap"}>
              <Button
                size="xs"
                variant="secondary"
                className={cn(
                  "bg-transparent text-sm text-neutral-900 hover:bg-neutral-300 active:bg-neutral-400 rounded-xs",
                  isSelected && "bg-neutral-300",
                )}
                onClick={() =>
                  opportunitiesStore.setState({
                    currentTab: {
                      ...opportunitiesStore.state.currentTab,
                      quickView: value,
                    },
                  })
                }
              >
                {label}
              </Button>
              {isSelected && !filterStore.showRestorePreset && (
                <IconButtonV2
                  icon={"Reset"}
                  variant="text"
                  className="ml-2"
                  onClick={() => filterStore.currentTabChange()}
                />
              )}
            </div>
          );
        })}
      </nav>
      <div className={"my-0.5 px-6 flex items-center justify-between"}>
        <div
          className={cn("my-2 flex items-center rounded-md active:bg-white")}
          onClick={() => {
            globalModalStore.navigate({
              modalType: "OpportunitySort",
              navState: { detent: "content-height" },
            });
          }}
        >
          <Icon
            type={opportunityFilterStore?.ordering?.dir === "DESC" ? "DownUp" : "UpDown"}
            width={16}
            height={16}
            className={"mr-2 text-black"}
          />
          <span className={"select-none text-sm font-medium"}>
            {opportunityFilterStore?.ordering?.label || "Default"}
          </span>
        </div>
        <div className={"items-center justify-center "}>
          <span className={"select-none text-nowrap text-sm font-medium text-neutral-400"}>{itemsCount}</span>
        </div>
      </div>
    </div>
  );
});
