import React from "react";
import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { CompanyDataForHighlights } from "@/app/screens/opportunities/opportunity/components/highlights/highlights.types.ts";

export const getCashoutGroup = (val: number, breakeven: boolean) => {
  if (breakeven) return "Breakeven";

  if (val <= 12) return "< 12 months";
  if (val > 12 && val <= 18) return "12 - 18 months";
  if (val > 18 && val < 36) return "18 - 36 months";
  if (val >= 36) return "> 36 months";
};

export const CashProfile = ({ company }: { company: CompanyDataForHighlights }) => {
  const { relativeRunway, lastCalculatedUtc, employeesLatest, lastMetric } = company;
  const cashoutGroup = getCashoutGroup(
    lastMetric?.data?.runwayInMonths?.value,
    lastMetric?.data?.fullyFundedToBreakeven?.value,
  );

  const runway =
    cashoutGroup || relativeRunway
      ? String(cashoutGroup || relativeRunway)
          .replace("months", "m")
          .replace(/\s+/g, "")
      : undefined;
  const runwayDate = cashoutGroup ? lastMetric?.data?.runwayInMonths?.date : lastCalculatedUtc;

  const FTEMetric = lastMetric?.data?.FTE;
  const FTE = FTEMetric?.value || employeesLatest;
  const FTEDate = FTEMetric?.date || lastCalculatedUtc;

  return (
    <HighlightsCard type={"Insight"} className={"group"}>
      <div className={"flex w-full justify-between"}>
        <div className={"flex w-1/2 flex-col items-center justify-center"}>
          <HighlightsCardTitle text={"Runway"} />
          <div className={"flex h-28 items-center justify-center"}>
            {runway ? (
              <span className="text-center text-3xl font-semibold">{runway}</span>
            ) : (
              <HighlightsCardEmptyState text={"No Runway Available"} />
            )}
          </div>
          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={runwayDate} />
          </HighlightsCardFooter>
        </div>
        <div className={"flex w-1/2 flex-col items-center justify-center"}>
          <HighlightsCardTitle text={"FTEs"} />
          <div className={"flex h-28 items-center justify-center"}>
            {FTE ? (
              <span className="text-3xl font-semibold">{String(FTE)}</span>
            ) : (
              <HighlightsCardEmptyState text={"No FTE Available"} />
            )}
          </div>
          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={FTEDate} />
          </HighlightsCardFooter>
        </div>
      </div>
    </HighlightsCard>
  );
};
