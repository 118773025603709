import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";
import { useDashboardSettings } from "@/app/screens/settings/hooks";

export const SettingsDashboard = observer(() => {
  const { name, tabs, TabComponent, activeTab, setActiveTab } = useDashboardSettings();

  return (
    <div className={"relative min-h-dvh w-full pt-[10dvh] lg:pt-0"}>
      <div className={"my-8 flex w-full flex-col px-[7dvw] lg:px-[14dvw]"}>
        <div>
          <span className="text-3xl font-semibold">{name}</span>
          <span className="mt-3 text-sm font-semibold text-neutral">
            Manage your details and personal preferences here.
          </span>
        </div>
        <div className={"my-12 flex space-x-4.5"}>
          {tabs.map((tab) => {
            const isActive = tab === activeTab;
            return (
              <span
                key={tab}
                className={cn(
                  "cursor-pointer select-none text-sm",
                  isActive ? "font-bold" : "font-medium",
                  isActive ? "text-neutral-900" : "text-neutral",
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab.replace(/([A-Z])/g, " $1").trim()}
              </span>
            );
          })}
        </div>
        <div className={"w-full"}>
          <TabComponent />
        </div>
      </div>
    </div>
  );
});
