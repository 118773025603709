import { shared } from "./styles";
import { EmptyState } from "../../../../../components/label";
import { Badges } from "@/app/types";
import { cn } from "@/lib/utils.ts";

export interface IntroProps {
  intro?: Badges;
  className?: string;
}

export const IntroInfo = ({
  intro,
  className = "flex flex-col justify-start gap-y-3 py-6",
}: IntroProps) => {
  return (
    <div className={`${className}`}>
      <span className={cn("text-sm font-semibold", shared.subTitleText)}>Intros Available</span>
      {intro ? (
        <span className={cn("text-sm font-medium", shared.body)}>{`${intro.extra} on ${intro.date}`}</span>
      ) : (
        <EmptyState className={shared.body} />
      )}
    </div>
  );
};
