import { format, parseISO } from "date-fns";

import { TextArea, Toggle } from "@/app/components";
import Form from "@/app/components/forms";
import { FormDatePicker, FormSelect } from "@/app/components/input";

import { shouldResurface } from "../../../opportunities";

export const SnoozeForm = ({ step, form, resurfaceOption, handleResurfacePeriodChange }) => {
  return (
    <>
      <p className={"mb-2 w-full text-left text-sm font-medium text-neutral-700"}>{step.description}</p>
      <Form.Root form={form}>
        {step.index === 0 && (
          <SnoozeFormOne handleResurfacePeriodChange={handleResurfacePeriodChange} resurfaceOption={resurfaceOption} />
        )}
        {step.index === 2 && <SnoozeFormTwo />}
      </Form.Root>
    </>
  );
};

const SnoozeFormOne = ({ resurfaceOption, handleResurfacePeriodChange }) => {
  return (
    <>
      <Form.Field
        fieldId={"reason"}
        render={({ onChange, value }) => (
          <div className="flex flex-1 flex-col items-start">
            <Form.Label />
            <TextArea
              containerClassName={"w-full"}
              value={value}
              onChange={(value) => {
                onChange(value);
              }}
            />
          </div>
        )}
      />
      <Form.Field
        fieldId={"resurface"}
        render={({ onChange, value }) => (
          <div className={"flex w-full flex-col items-start "}>
            <Form.Label />
            <div className={"flex w-full flex-col items-start gap-y-3"}>
              <FormSelect
                options={shouldResurface}
                defaultOptions={value ? [{ value, label: value }] : undefined}
                iconLeft="Snooze"
                placeholder={"Select period"}
                multiSelect={false}
                required
                onChange={([option]) => handleResurfacePeriodChange(option?.value, onChange)}
              />
              {resurfaceOption === "custom" && (
                <FormDatePicker
                  onChange={(date) => (date ? onChange(format(date, "yyyy-MM-dd")) : onChange(""))}
                  selected={value ? parseISO(value) : undefined}
                  minDate={new Date()}
                  placeholder="Select Date"
                  className="mt-2 w-full"
                  enableYearNavigation
                />
              )}
            </div>
            <Form.Error />
          </div>
        )}
      />
    </>
  );
};

const SnoozeFormTwo = ({}) => {
  return (
    <>
      <Form.Field
        fieldId={"feedback_email"}
        render={({ onChange, value }) => (
          <div className={"flex w-full flex-col items-start "}>
            <TextArea
              autoFocus={true}
              containerClassName={"w-full"}
              onChange={(value) => {
                onChange(value);
              }}
              value={value}
              placeholder={"Add a brief reason for not progressing with the company..."}
            />

            <Toggle
              containerClassName={"-mt-3 pl-0.5"}
              rightLabel={"Apologize for delay"}
              setValue={(checked) => {
                if (checked) {
                  onChange(`${value}\n\n\nBegin your response with a sincere apology for the delay.`);
                  return;
                }
                onChange(value.replace("\n\n\nBegin your response with a sincere apology for the delay.", ""));
              }}
            />
            <Form.Error />
          </div>
        )}
      />
    </>
  );
};
