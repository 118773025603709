import globalModalStore from "@/app/screens/modal/global-modal.store";
import { text } from "@/app/styles";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { cn } from "@/lib/utils.ts";

export interface LinkedLabelProps {
  label: string;
  children: React.ReactNode;
  href: string;
  className?: string;
}

export const LinkedLabel = ({ label, href, children, className = "" }: LinkedLabelProps) => {
  return (
    <div className={`mb-3 flex w-full ${className}`}>
      <div className={"flex items-center gap-3"}>
        <span className={cn("text-sm", text.subTitleText)}>{label}</span>
      </div>
      <Link
        className={"flex cursor-pointer items-baseline gap-2"}
        to={href}
        onClick={() => globalModalStore.toggleModal()}
      >
        {children}
      </Link>
    </div>
  );
};
