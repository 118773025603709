import { useEffect } from "react";

import { LottieComponentProps, useLottie } from "lottie-react";

import { cn } from "@/lib/utils.ts";

import FourZeroFour from "./lottie/404.json";
import Completed from "./lottie/completed.json";
import Loading from "./lottie/loading-lg.json";
import NoResult from "./lottie/no-results.json";
import Microphone from "./lottie/recording.json";

const lottieMap = {
  "404": FourZeroFour,
  loading: Loading,
  recording: Microphone,
  completed: Completed,
  noResult: NoResult,
};

export type LottieKeys = keyof typeof lottieMap;

interface LottieProps {
  type: LottieKeys;
  className?: string;
  labelBottom?: string;
  subtitleBottom?: string;
  lottieClassName?: string;
  labelTop?: string;
  loop?: boolean;
  active?: boolean;
  onHoverPlay?: boolean;
}

export const Lottie = ({
  type,
  active,
  className,
  lottieClassName,
  loop = true,
  onHoverPlay,
  labelBottom = "",
  subtitleBottom = "",
  labelTop = "",
}: LottieProps & Partial<LottieComponentProps>) => {
  const options = {
    animationData: lottieMap[type],
    className: lottieClassName,
    loop,
  };

  const { View, play, pause } = useLottie(options);

  useEffect(() => {
    if (onHoverPlay && !active) {
      pause();
    } else if (onHoverPlay && active) {
      play();
    }
  }, [active]);

  return (
    <div
      className={cn("flex flex-col items-center justify-center", className)}
      onMouseEnter={() => {
        if (onHoverPlay) play();
      }}
      onMouseLeave={() => {
        if (onHoverPlay && !active) setTimeout(() => pause(), 100);
      }}
    >
      {labelTop && <p className={"text-neutral opacity-70"}>{labelTop}</p>}
      {View}
      {subtitleBottom && (
        <span className={"text-nowrap text-center text-xl text-neutral opacity-70"}>{subtitleBottom}</span>
      )}
      {labelBottom && (
        <span className={"mt-2 text-nowrap text-center text-lg font-medium text-neutral opacity-70"}>
          {labelBottom}
        </span>
      )}
    </div>
  );
};
