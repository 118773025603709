import { Control, Controller, UseFormWatch } from "react-hook-form";

import { BaseToggle, TextArea, Tooltip } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { CompanySignalSchema } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";

import { CompanySignalToggles } from "../add-company-view.types.tsx";

export const sourceOptions = [
  { value: "angel", label: "Angel" },
  { value: "investor", label: "Investor" },
  { value: "operator", label: "Operator" },
  { value: "portfolio_founder", label: "Portfolio Founder" },
  {
    value: "none_portfolio_founder",
    label: "Non Portfolio Founder",
  },
  { value: "service_provider", label: "Service Provider" },
  { value: "other", label: "Other" },
];

const toggles: Array<{ name: CompanySignalToggles; label: string; description?: string }> = [
  { name: "introAvailable", label: "Can we get connected?" },
  { name: "activelyRaising", label: "Are they actively raising?" },
  { name: "highPriority", label: "Is this a high priority?" },
  {
    name: "isDeepTech",
    label: "Is this Deep tech",
    description: "Deep tech companies are enabled by scientific or engineering breakthroughs",
  },
];

type FormData = Omit<CompanySignalSchema, "fund">;

export type SignalFormFieldProps = {
  control: Control<FormData>;
  watch: UseFormWatch<FormData>;
};

// TODO: this component is shared between edit and add screens so we should probably find better placement for it
// todo move and rename file
export const SignalFormFields = ({ control, watch }: SignalFormFieldProps) => {
  const selectedSource = watch("sourceType");

  return (
    <div className="flex w-full flex-col space-y-7">
      <div className="grid gap-2 lg:grid-cols-2">
        <Controller
          name="sourceType"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              labelVariant={"tertiary"}
              label="Where did you hear about this?"
              error={fieldState.error?.message}
              required
            >
              <SelectV2
                options={sourceOptions}
                onChange={(value) => field.onChange(value ?? "")}
                value={field.value}
                placeholder="Select source type"
              />
            </FormField>
          )}
        ></Controller>
        {selectedSource && (
          <Controller
            name="sourceName"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label={`Which ${sourceOptions.find((source) => source.value === selectedSource)?.label ?? selectedSource}?`}
                labelVariant={"tertiary"}
                error={fieldState.error?.message}
                required
              >
                <InputV2 placeholder={"Enter the name"} {...field} />
              </FormField>
            )}
          />
        )}
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
        {toggles.map(({ name, label, description }) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <FormField label={label} labelVariant={"tertiary"} styles={{ container: "w-full" }}>
                {description ? (
                  <Tooltip containerClassName="block" text={description}>
                    <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
                  </Tooltip>
                ) : (
                  <>
                    <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
                  </>
                )}
              </FormField>
            )}
          />
        ))}
      </div>

      <Controller
        name="note"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            label="Do you have any context from the source?"
            labelVariant={"tertiary"}
            error={fieldState.error?.message}
          >
            <TextArea {...field} placeholder={"Enter the context you want to share..."} value={field.value || ""} />
          </FormField>
        )}
      />
    </div>
  );
};
