import Form from "@/app/components/forms";
import { FormSelect } from "@/app/components/input";

export const MoveTrelloForm = ({ form, trelloColumns }) => {
  return (
    <Form.Root form={form}>
      <div className={"flex w-full flex-col"}>
        <Form.Field
          fieldId={"trello_column"}
          render={({ onChange, value }) => (
            <div className="mb-4 flex flex-1 flex-col items-start">
              <Form.Label />
              <FormSelect
                defaultOptions={value ? [value] : []}
                onChange={(values) => onChange(values?.[0])}
                options={trelloColumns}
                placeholder={"Please select a column..."}
                iconLeft={"Search"}
                multiSelect={false}
                required
              />
              <Form.Error />
            </div>
          )}
        />
      </div>
    </Form.Root>
  );
};
