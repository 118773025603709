import React, { PropsWithChildren } from "react";

import { Content, Editor } from "@tiptap/react";
import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import { RichTextEditor } from "@/app/components/rich-text-editor";
import { GetCompanyCommentsDocument, useUpdateCompanyCommentMutation } from "@/app/service/company-comments.gql.ts";

import { CommentDisplayData, isNazareComment } from "../comments.types.ts";

export const EditCommentInputActions = ({
  comment,
  editor,
  onClose,
}: {
  editor?: Editor;
  comment: CommentDisplayData;
  onClose?: () => void;
}) => {
  const [editComment, { loading }] = useUpdateCompanyCommentMutation({
    refetchQueries: [GetCompanyCommentsDocument],
  });

  const handleSave = () => {
    if (!editor) {
      throw new Error("No editor instance");
    }

    if (!comment.id) {
      throw new Error("No comment id");
    }

    editComment({
      variables: {
        id: comment.id,
        text: editor.getText(),
        metadata: {
          source: "tiptap",
          data: editor.getJSON(),
          html: editor.getHTML(),
        },
      },
    }).then(() => {
      toast.success("Comment saved successfully.");
      onClose?.();
    });
  };

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <div className={cn("mt-2 flex gap-4")}>
      <Button onClick={handleSave} variant="secondary" size="sm" loading={loading} disabled={loading || editor.isEmpty}>
        Save
      </Button>
      <Button onClick={handleCancel} variant="text" size={"sm"} disabled={loading}>
        Cancel
      </Button>
    </div>
  );
};

export const EditCommentInput = ({
  onClose,
  comment,
}: PropsWithChildren<{
  onClose?: () => void;
  comment: CommentDisplayData;
}>) => {
  const content: Content = isNazareComment(comment) ? comment.metadata.data : comment.text;

  const editorProps = {
    attributes: {
      class: "rounded-md bg-white p-4 border",
    },
  };

  return (
    <RichTextEditor content={content} autofocus={"end"} editorProps={editorProps}>
      <EditCommentInputActions onClose={onClose} comment={comment} />
    </RichTextEditor>
  );
};
