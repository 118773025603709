import { Signal } from "@/app/types";

import { Signals } from "../signals.tsx";

export interface TitleWithSignalProps {
  title: string;
  signals: Signal[];
  isPortco?: boolean;
  showSignals?: boolean;
  showType?: boolean;
  name?: string;
  signalIconStyle?: string;
}

export const TitleWithSignal = ({
  title,
  signals = [],
  showSignals = false,
  isPortco = false,
  showType = true,
  name,
  signalIconStyle,
}: TitleWithSignalProps) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="mb-2 flex items-center">
        <span
          className={"mr-2 line-clamp-1 w-auto font-semibold leading-none text-black md:text-lg lg:text-xs 2xl:text-sm"}
        >
          {title}
        </span>
        {showSignals && signals.length > 0 && <Signals signals={signals} iconClassName={signalIconStyle} />}
      </div>
      {showType && (
        <div className={"flex leading-none"}>
          <p className="line-clamp-1 flex items-center text-xxs font-medium text-neutral-500 sm:hidden md:text-sm lg:text-xs 2xl:text-sm">
            {isPortco ? "Portfolio Company" : "Company"}
            <span className="text-neutral-400 lg:text-sm 2xl:text-xl">&nbsp;•&nbsp;</span>
            {name || "Not Reviewed"}
          </p>
        </div>
      )}
    </div>
  );
};
