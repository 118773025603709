import { endOfDay, format, nextSunday } from "date-fns";

import { Button, Card, Icon } from "@/app/components";

export const AgendaReminder = () => {
  return (
    <Card className="group relative flex select-none flex-col items-center gap-y-2 py-5 ">
      <div className="flex w-full flex-row flex-wrap items-center">
        <p className="items-center text-sm font-normal md:flex">
          <Icon type="Time" className="relative bottom-1 mr-2 inline-block size-5 md:bottom-0" />
          Agenda submissions window is open until{" "}
          <span className="text-base font-medium md:ml-1">
            Sunday, {format(endOfDay(nextSunday(new Date())), "LLLL dd, HH:mm a")}
          </span>
        </p>
        <Button
          variant="primary"
          size="sm"
          className="mt-2 w-full md:ml-auto md:mt-0 md:w-auto"
          onClick={() =>
            window.open(
              "https://localglobe.retool.com/apps/2514fd4a-b637-11ee-921f-e347d5f4f273/Agenda%20Curator",
              "_blank",
            )
          }
        >
          Submit now
        </Button>
      </div>
    </Card>
  );
};
