import { useMemo } from "react";

import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { ResponsiveHorizontalList, Tag } from "@/app/components";

export const Tags = observer(function Tags({ city, country, badges, className, tagStyle }: any) {
  const tags = useMemo(() => {
    const location = badges?.find(({ id }) => id === "country");

    const otherTags =
      badges?.filter(({ value, group, id }) => value !== "N/A" && group !== "signal" && id !== "country") ?? [];

    const locs =
      location?.value || country
        ? [
            {
              type: "country",
              value: location?.extra || country,
              popover: `Company HQ'd in ${city ? city + ", " : ""}${country}.`,
            },
          ]
        : [];

    return [...locs, ...otherTags];
  }, [badges, city, country]);

  return (
    <ResponsiveHorizontalList
      className={cn("gap-1", className)}
      popoverProps={{
        className: "rounded-xxs bg-neutral-100 hover:bg-neutral-100 px-2 text-xs py-1 space-y-1 lg:text-xxs 2xl:text-xs",
        childrenClassName: "space-y-1",
      }}
    >
      {tags.map(({ value, popover }, index) => {
        return <Tag key={`tag_${value}_${index}`} label={value} tooltip={popover} tagClassName={tagStyle} />;
      })}
    </ResponsiveHorizontalList>
  );
});
