import React, { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

export interface TitleWithSignalProps {
  title?: string;
  iconRight?: React.ReactNode;
  styles?: {
    className?: string;
    titleClassName?: string;
  };
}

export const CardTitle = ({ title, iconRight, children, styles = {} }: PropsWithChildren<TitleWithSignalProps>) => {
  const { className, titleClassName } = styles;
  return (
    <div className={cn("flex flex-col justify-center", className)}>
      {title && (
        <div className="flex items-center">
          <span
            className={cn(
              "mr-2 line-clamp-1 w-auto text-sm font-semibold leading-none text-black md:text-lg lg:text-sm 2xl:text-base",
              titleClassName,
            )}
          >
            {title}
          </span>
          {iconRight}
        </div>
      )}
      {children}
    </div>
  );
};
