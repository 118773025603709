import { useState } from "react";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { IconType } from "@/app/components";
import { WebSelect } from "@/app/screens/modal/components";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";

interface LocationInputProps {
  containerClassName?: string;
  iconRight?: IconType | null;
  defaultValue?: OptionsType;
  required?: boolean;
  label?: string;
  onChange: (value) => void;
  disabled?: boolean;
}

/**
 * @deprecated use LocationInputV2
 */
export const LocationInput = ({
  required = false,
  onChange,
  iconRight,
  label,
  defaultValue,
  containerClassName,
  disabled,
}: LocationInputProps) => {
  const [place, setPlace] = useState<OptionsType[]>(defaultValue ? [defaultValue] : []);
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: "AIzaSyBUJKfrBUrZt7G-dz1GIlnglgHyz1Nw6E4",
  });

  return (
    <WebSelect
      by={"id"}
      allOptions={placePredictions.map((place) => ({
        id: place.place_id,
        label: place.description,
        value: place.description,
      }))}
      label={label}
      iconLeft={"LoopGradient"}
      multiple={false}
      required={required}
      loading={isPlacePredictionsLoading}
      defaultSelected={place}
      iconRight={iconRight === null ? null : "ArrowDown"}
      onChange={(e) => {
        getPlacePredictions({ input: e.target.value });
      }}
      storeCallback={(value) => {
        if (value?.length) {
          placesService?.getDetails(
            {
              placeId: value[0].id,
            },
            (placeDetails) => {
              const response: { country: string; city: string } = placeDetails.address_components.reduce(
                (acc, component) => {
                  if (component.types.includes("country")) {
                    acc.country = component.long_name;
                  }
                  if (component.types.includes("locality")) {
                    acc.city = component.long_name;
                  }
                  return acc;
                },
                {
                  country: "",
                  city: "",
                },
              );
              onChange(response);
            },
          );
        } else {
          onChange({ country: "", city: "" });
        }
        setPlace(value);
      }}
      placeholder={"Add the location..."}
      containerClassName={`${containerClassName}`}
      disabled={disabled}
    />
  );
};
