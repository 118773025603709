import { normaliseDate } from "@/app/misc/helpers";
import { TrelloAttachment } from "@/lib/service/trello.types";
import { cn, getFileExtension } from "@/lib/utils";
import { text } from "@/app/styles";
import { LinkedLabel } from "@/app/components/label/linked-label";
import { DocumentIcon } from "@/app/components/icon";

interface AttachmentListProps {
  total: number;
  displayAttachments: TrelloAttachment[];
  route?: string;
  container?: string;
  contentContainer?: string;
  attachmentContainer?: string;
  size?: "md" | "lg";
}

export const AttachmentList = ({
  total,
  displayAttachments,
  route = "",
  container,
  contentContainer,
  attachmentContainer,
  size = "lg",
}: AttachmentListProps) => {
  if (total === 0) return null;

  return (
    <div className={cn("flex flex-col pt-6", container)}>
      {route && (
        <LinkedLabel label={"Attachments"} href={route}>
          <span className={cn("ml-2 font-medium text-primary", text.body)}>Show All</span>
          <span className={`${text.titleText} text-lg font-semibold text-primary`}>{String(total)}</span>
        </LinkedLabel>
      )}
      <div className={cn("grid grid-cols-2", contentContainer)}>
        {displayAttachments.map(({ fileName, date, name }, i) => (
          <div
            key={i}
            className={cn(
              "flex cursor-pointer flex-col gap-y-1.5 rounded-md border border-neutral-200 p-1",
              attachmentContainer,
            )}
          >
            <div className={"flex items-start"}>
              <DocumentIcon
                ext={getFileExtension(fileName)}
                className={cn(size === "lg" ? "min-h-12 w-12 min-w-12" : "min-h-6 w-6 min-w-6")}
              />
              <div className={"overflow-hidden"}>
                <p
                  className={cn("text-sm font-medium", size === "lg" ? text.body : text.message, size === "lg" ? 1 : 2)}
                >
                  {name}
                </p>
                <span
                  className={cn(
                    "text-left text-sm font-medium text-neutral-400",
                    size === "lg" ? text.message : text.small,
                  )}
                >{`on ${normaliseDate(date)}`}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
