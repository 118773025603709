import { useState } from "react";

import { Checkbox as MaterialCheckbox } from "@material-tailwind/react";

import { cn } from "@/lib/utils.ts";
import { Input } from "@/app/components";

type CheckboxProps = {
  label?: string;
  disabled?: boolean;
  ripple?: boolean;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  onClick?: (e) => void;
  checked?: boolean;
};
export function Checkbox({
  label,
  disabled,
  className,
  labelClassName,
  containerClassName,
  ripple = true,
  onClick,
  checked = false,
}: CheckboxProps) {
  return (
    <MaterialCheckbox
      onChange={onClick}
      ripple={ripple}
      color={"pink"}
      crossOrigin={undefined}
      checked={checked}
      disabled={disabled}
      label={<span className={cn("-mx-0.5 line-clamp-1 text-sm font-semibold", labelClassName)}>{label}</span>}
      containerProps={{ className: `${containerClassName}` }}
      labelProps={{ className: `${labelClassName}` }}
      className={cn(
        "size-4 rounded-[4px] border-neutral-300 checked:bg-primary hover:before:opacity-5 checked:hover:bg-primary-200 focus:ring-1 focus:ring-primary-200 focus:before:opacity-0 disabled:border-neutral-300 disabled:bg-neutral-100",
        className,
      )}
    />
  );
}

export function CheckboxList({ data, filterInput }) {
  const [filteredDataSource, filterDataSource] = useState(data);
  return (
    <div>
      {data.length > 6 ? (
        <Input
          autoFocus={true}
          placeholder={"Search"}
          iconLeft={"Search"}
          onChange={({ target }) => {
            filterDataSource((prevState) =>
              data.filter((e) => e.value.toLowerCase().includes(target.value.toLowerCase())),
            );
          }}
          containerClassName={"mx-2cu "}
        />
      ) : null}

      {filteredDataSource.map(({ label }) => (
        <div>
          <div className="my-2 flex flex-row items-center">
            <input
              type="checkbox"
              title={"something"}
              className="mx-2 size-4 rounded border-neutral-300 checked:text-primary focus:ring-primary-600"
            />
            <span className="text-sm">{label}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
