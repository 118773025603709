import React from "react";

import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { BreadCrumbs, ImageWithErrorFallback, Label } from "@/app/components";
import { Routes } from "@/app/constants";
import { CompanySelectModal } from "@/app/screens/metrics-extraction/sections";
import { useMetricsHeader } from "@/app/screens/metrics-extraction/sections/company-header/use-metrics-header.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

export const Header = observer(({ containerClassName, email }) => {
  const { navigate, selectedCompanyId, name, image, loading } = useMetricsHeader();

  return (
    <>
      <div className={"flex items-center justify-between"}>
        <div className={"flex gap-x-4"}>
          <BreadCrumbs
            title={"Back"}
            action={() => {
              navigate(-1);
            }}
          />
          <Label description={"BETA"} type={"medium"} />
        </div>
        {email && (
          <span
            className={
              "cursor-pointer select-none rounded-sm bg-white px-3 py-2 text-sm font-semibold hover:bg-gray-50 active:bg-gray-100"
            }
            onClick={() => modalStore.open(CompanySelectModal, { props: { emailId: email.id } })}
          >
            Link to another company
          </span>
        )}
      </div>
      <div className={cn("group relative flex items-center", containerClassName)}>
        <ImageWithErrorFallback
          alt={name}
          src={image}
          loading={loading}
          iconClassName={"rounded-md p-2"}
          className={cn(
            "flex shrink-0 rounded-md border border-gray-300 lg:size-12 2xl:size-14",
            selectedCompanyId === "new" ? "" : "cursor-pointer hover:opacity-80",
          )}
          onClick={() => {
            if (selectedCompanyId === "new") return;
            navigate(`${Routes.company}/${selectedCompanyId}`);
          }}
        />

        <div className={"ml-4 flex flex-col justify-center"}>
          <span
            className={cn(
              "truncate lg:text-[18px] 2xl:text-[22px] text-semibold",
              selectedCompanyId === "new" ? "hover:opacity-100" : "cursor-pointer hover:opacity-80",
            )}
            onClick={() => {
              if (selectedCompanyId === "new") return;
              navigate(`${Routes.company}/${selectedCompanyId}`);
            }}
          >{name || "Unknown Company"}</span>
        </div>
      </div>
    </>
  );
});
