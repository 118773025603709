import { configurePersistable } from "mobx-persist-store";

export const configureMobxPersistable = () => {
  configurePersistable(
    {
      storage: window.localStorage,
      stringify: false,
      debugMode: import.meta.env.VITE_MOBX_DEBUG === "true",
    },
    { delay: 200, fireImmediately: false },
  );
}
