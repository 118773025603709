import { ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

type HeaderWithTotalsProps = {
  label: string;
  value: ReactNode;
};

export const TableCellTotals = ({ label, value }: HeaderWithTotalsProps) => {
  return (
    <div className={cn("w-full")}>
      <div className={cn("w-full justify-center bg-neutral-200 text-center")}>
        <span className="text-xxs font-medium leading-4">{label}</span>
      </div>
      {value}
    </div>
  );
};
