import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";

export const companyDataSchema = z
  .object({
    name: z.string().optional(),
    websiteUrl: z.string().optional().nullable(),
    founders: z
      .array(
        z
          .object({
            details: z
              .object({
                name: z.string().min(1, "Name is required"),
                url: z
                  .string()
                  .regex(linkedinProfileUrlRegex, "Please enter a valid url like https://linkedin.com/in/example"),
              })
              .optional(),
            dealroomFounder: z
              .object({
                id: z.string().min(1, "id is required"),
                name: z.string().min(1, "Name is required"),
                linkedinUrl: z.string().optional().nullable(),
              })
              .optional(),
            lgFounder: z
              .object({
                id: z.string().min(1, "id is required"),
                name: z.string().min(1, "Name is required"),
                linkedinUrl: z.string().optional().nullable(),
              })
              .optional(),
          })
          .refine((data) => !!data.dealroomFounder || !!data.lgFounder || !!data.details, {
            message: "Please provide at least one of these - name, website url or founders info",
            path: ["_required"],
          }),
      )
      .optional()
      .nullable(),
    dealroomCompany: z
      .object({
        id: z.string(),
        name: z.string().optional().nullable(),
        about: z.string().nullable().optional(),
        tagline: z.string().nullable().optional(),
        websiteUrl: z.string().nullable().optional(),
        image: z.string().nullable().optional(),
        founders: z
          .object({
            nodes: z.array(
              z.object({
                name: z.string().nullable().optional(),
                image: z.string().nullable().optional(),
                linkedinUrl: z.string().nullable().optional(),
              }),
            ),
          })
          .optional(),
      })
      .optional()
      .nullable(),
  })
  .refine(
    (data) =>
      data.name ||
      data.websiteUrl ||
      data.founders?.some(
        (value) => value.lgFounder || value.dealroomFounder || (value?.details?.name && value?.details?.url),
      ) ||
      !!data.dealroomCompany?.id,
    {
      message: "Please provide at least one of these three - name, website url or founders info",
      path: ["_required"],
    },
  );

export type CompanyDataSchema = z.infer<typeof companyDataSchema>;

export const useCompanyDataForm = (defaultValues?: CompanyDataSchema) => {
  const form = useForm<CompanyDataSchema>({
    resolver: zodResolver(companyDataSchema),
    mode: "all",
    defaultValues: {
      name: defaultValues?.name || "",
      websiteUrl: defaultValues?.websiteUrl || "",
      founders: defaultValues?.founders || [],
      dealroomCompany: defaultValues?.dealroomCompany,
    },
  });

  const founders = useFieldArray({
    name: "founders",
    control: form.control,
  });

  return {
    form,
    founders,
  };
};

export type FoundersFieldArray = ReturnType<typeof useCompanyDataForm>["founders"];
