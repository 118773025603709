import { useMemo } from "react";

import { observer } from "mobx-react";

import { extractFormCategory } from "@/lib/utils.ts";

import { NativeModal } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers.ts";
import modalStore from "@/app/stores/modal.store";

import { CompleteSentimentParams, useCompleteSentiment } from "./hooks";

export const CompleteSentimentMobile = observer((props: CompleteSentimentParams) => {
  const { title, sentimentForm, renderForm, actions } = useCompleteSentiment(props);

  const header = useMemo(
    () => (
      <>
        <div className="flex w-full items-center justify-center">
          <p className="text-lg font-semibold">{title}</p>
        </div>
        <div className="flex-items flex w-full items-center text-xs text-neutral">
          <span>{sentimentForm?.createdAt && normaliseDate(sentimentForm?.createdAt)}</span>
          &nbsp;&nbsp;•&nbsp;&nbsp;
          <span>{extractFormCategory(sentimentForm)}</span>
        </div>
      </>
    ),
    [sentimentForm, title],
  );

  return (
    <NativeModal
      isOpen
      handleClose={modalStore.close}
      header={header}
      footer={actions}
      bodyContainerClassName="p-4"
      detent="full-height"
    >
      {renderForm}
    </NativeModal>
  );
});
