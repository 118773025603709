import React, { memo, useCallback, useMemo } from "react";

import { motion } from "framer-motion";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { ImageWithErrorFallback } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar/index.ts";
import { usePageNav } from "@/app/components/page-nav";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import {
  OpportunityActions,
  OpportunityActionsMobile,
} from "@/app/screens/opportunities/opportunity/components/actions/actions.tsx";
import { SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components/social-media-icons.tsx";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";

import { useSocialMediaIcons } from "../use-opportunity.tsx";

type OpportunityHeaderStickyProps = {
  company: OpportunityCompany;
};

const useIsOpen = () => {
  const { activeElements } = usePageNav();

  return useMemo(() => {
    return !activeElements.includes("actions");
  }, [activeElements]);
};

const variants = {
  open: {
    height: 72,
  },
  closed: {
    height: 0,
  },
};

const HeaderContent = memo(function HeaderContent({
  onBack,
  isSidenavOpen,
  isOpen,
  company,
}: {
  onBack: () => void;
  isSidenavOpen: boolean;
  isOpen: boolean;
  company: NonNullable<OpportunityCompany>;
}) {
  const socialMediaIcons = useSocialMediaIcons(company);

  return (
    <motion.div
      className={cn(
        "flex items-center space-x-4 px-5 pl-11 shadow-xs",
        // positioning:
        // there's a weird bug when using sticky with a sidenav. The header jumps breaking the layout thus using fixed
        "fixed left-0 top-0 z-20 w-full",
        "lg:px-6 lg:pl-28",
        // we want to shift elements when sidebar is open. 296px = 256px(sidebar width) + 40px(margin left)
        isSidenavOpen && "lg:pl-60 xl:pl-[17rem]",
        "backdrop-blur-md",
        "bg-[rgba(255,255,255,0.1)]", // enabling blur only on larger screens because of bug in ios safari
        !isOpen ? "overflow-hidden" : "",
      )}
      variants={variants}
      initial={"closed"}
      animate={isOpen ? "open" : "closed"}
      transition={{ duration: 0.1, easeInOut: true }}
    >
      <BreadCrumbs title={"Back"} action={onBack} />
      <ImageWithErrorFallback
        src={company.image}
        alt={company.name}
        iconClassName={"rounded-md p-2"}
        className="flex size-10 shrink-0 rounded-md border border-neutral-300"
      />

      <div>
        <span className="truncate text-2xl font-semibold">{company.name}</span>
      </div>

      <SocialMediaIcons
        icons={socialMediaIcons}
        iconClassName="h-4 w-4 text-neutral hover:text-[#0072b1] cursor-pointer"
        containerClassName={"ml-3 space-x-5 hidden lg:flex mr-2"}
      />

      <div className="!ml-auto">
        <OpportunityActions company={company} className="hidden pt-0 lg:flex lg:pr-1 2xl:pr-4" />
        <OpportunityActionsMobile company={company} className="ml-4 lg:hidden" />
      </div>
    </motion.div>
  );
});

export const OpportunityHeaderSticky = observer(function OpportunityHeaderSticky({
  company,
}: OpportunityHeaderStickyProps) {
  const { isOpen: isSidenavOpen } = globalModalStore.navbarState;
  const navigate = useNavigate();

  const isOpen = useIsOpen();

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!company) {
    return null;
  }

  return <HeaderContent isOpen={isOpen} onBack={handleNavigateBack} isSidenavOpen={isSidenavOpen} company={company} />;
});
