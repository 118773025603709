import { useMemo } from "react";

import { ConfirmHookProps } from "../types";

export const useConfirm = ({ title, subTitle, icon }: ConfirmHookProps) => {
  const renderContent = useMemo(
    () => (
      <>
        {icon}
        <h6 className="mb-6 text-center text-xl font-medium leading-none">{title}</h6>
        <span className="mb-8 text-sm font-normal text-neutral-700">{subTitle}</span>
      </>
    ),
    [title, subTitle, icon],
  );

  return { renderContent };
};
