import { useCallback, useMemo, useState } from "react";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { DatePickerProps as TremorDatePickerProps } from "@tremor/react";
import { format as dtFormat } from "date-fns";
import { Matcher } from "react-day-picker";

import { cn } from "@/lib/utils";

import { IconType } from "@/app/components";

import { Calendar } from "../calendar";
import { Input, Select, SelectOption } from "../input";

import { dateAdd } from "./utils";

interface CustomProps {
  onChange?: (value?: Date) => void;
  iconRight?: IconType;
  iconLeftClassName?: string;
  iconRightClassName?: string;
  format?: string;
  selected?: Date;
  selectOptions?: SelectOption[];
  from?: Date;
}

export type DatePickerProps = TremorDatePickerProps & CustomProps;

export const DatePicker = ({
  onChange,
  selected,
  format = "yyyy-MM-dd",
  selectOptions,
  iconRight = "Filter Chevron Down",
  iconRightClassName = "text-black",
  iconLeftClassName = "",
  className,
  maxDate,
  minDate,
  from,
  ...props
}: DatePickerProps) => {
  const [value, setValue] = useState<Date | undefined>(selected);

  const handleChange = useCallback(
    (date, option?: SelectOption) => {
      if (!onChange) return;
      if (!date && option) {
        const range = dateAdd(option);
        setValue(range);
        onChange(range);
      }

      if (!date) {
        setValue(undefined);
        onChange();
      }

      if (date) {
        setValue(date);
        onChange(date);
      }
    },
    [onChange],
  );

  const disabled = useMemo(() => {
    const range: Matcher[] = [];

    if (maxDate) range.push({ after: maxDate });

    if (minDate) range.push({ before: minDate });

    if (from) range.push({ from });

    return range;
  }, [maxDate, minDate, from]);

  return (
    <div className={cn("relative w-full", className)}>
      <Popover>
        <PopoverButton className={"w-full outline-none"}>
          <Input
            value={value ? dtFormat(value, format) : ""}
            onChange={() => null}
            placeholder="Select Date"
            className="cursor-pointer outline-0 disabled:bg-white disabled:text-neutral-600"
            iconLeft="Calendar"
            iconRight={iconRight}
            iconLeftClassName={iconLeftClassName}
            iconRightClassName={iconRightClassName}
          />
        </PopoverButton>
        <PopoverPanel className={"absolute z-50 w-[300px] rounded-xs bg-white shadow-xs"}>
          {({ close }) => (
            <Calendar
              {...props}
              mode="single"
              disabled={disabled}
              onSelect={(date) => {
                handleChange(date);
                close();
              }}
            />
          )}
        </PopoverPanel>
      </Popover>
      {selectOptions && (
        <Select
          options={selectOptions}
          className="absolute bottom-0 right-0 flex h-full w-1/3 rounded-l-none bg-tremor-brand text-white hover:bg-primary-400"
          optionsClassName="text-black"
          label="Select Range"
          onChange={(option) => handleChange("", option)}
        />
      )}
    </div>
  );
};
