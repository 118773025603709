import { Button } from "@/app/components";
import { ModalHeader, ModalWebFrame } from "@/app/screens/modal/components";
import Form from "@/app/components/forms";
import { cn } from "@/lib/utils.ts";
import { observer } from "mobx-react";
import { StepperWithContent } from "@/app/components/steps/stepper-with-content.tsx";
import {
  opportunitiesSteps,
  StepType,
} from "@/app/screens/modal/opportunities";
import { useAddOpportunity } from "@/app/screens/modal/opportunities/hooks/useAddOpportunity.tsx";

export const AddOpportunityWeb = observer(({ form }) => {
  const {
    index,
    title,
    setStep,
    Component,
    primaryButton,
    primaryAction,
    secondaryButton,
    secondaryAction,
    addCompanyLoading,
    primaryActionValidation,
  } = useAddOpportunity(form, "web");

  return (
    <ModalWebFrame containerClassName={"min-h-[65dvh] h-fit"}>
      <div className={"my-4 flex w-full items-start justify-center"}>
        <StepperWithContent
          containerClassName={"w-full"}
          activeStep={index}
          setStep={(stepperIndex) => {
            setStep((prevState) => ({
              ...prevState,
              step: Object.keys(opportunitiesSteps())[stepperIndex] as StepType,
              index: stepperIndex,
            }));
          }}
          steps={Object.entries(opportunitiesSteps()).map(
            ([_, { icon, title }]) => ({
              icon,
              text: title,
              disabled:
                icon === "Opportunity" &&
                form.values()["stealth"] === "stealth",
            })
          )}
        />
      </div>
      <ModalHeader title={title} />

      <div className="bg-gree flex h-full w-full flex-1">
        <Form.Root form={form} className={"flex h-fit w-full"}>
          <Component form={form} />
        </Form.Root>
      </div>

      <div className={cn("flex w-full justify-between")}>
        <Button
          text={secondaryButton.text}
          variant="secondary"
          onClick={secondaryAction}
        />
        <Button
          text={primaryButton.text}
          disabled={primaryActionValidation()}
          variant={"primary"}
          iconLeft={primaryButton.icon}
          loading={addCompanyLoading}
          onClick={primaryAction}
        />
      </div>
    </ModalWebFrame>
  );
});
