import { useMemo } from "react";

import { useGetCompanyFoundersQuery } from "@/app/service/company-founders.gql.ts";

import { FounderFromTeam } from "./founders.types.ts";

export const useFounders = (companyId: string) => {
  const { data, loading } = useGetCompanyFoundersQuery({
    variables: { companyId },
  });

  // unique by linkedin url
  const unique: FounderFromTeam[] = useMemo(() => {
    const founders = data?.lgCompanyTeams?.nodes ?? [];

    return founders
      .filter((founder, index, array) => {
        return (
          index === array.findIndex((f) => founder.member && founder.member?.linkedinUrl === f.member?.linkedinUrl)
        );
      })
      .sort((a) => (a.member?.image ? -1 : 1))
      .map((founder) => ({
        id: founder.member?.id,
        name: founder.member?.name ?? "-",
        linkedinUrl: founder.member?.linkedinUrl,
        image: founder.member?.image,
        titles: founder.titles,
        forms: founder.member?.forms.nodes,
        sourceTypes: founder.member?.founderSources.nodes,
        member: founder.member,
      }));
  }, [data?.lgCompanyTeams?.nodes]);

  return { data: unique, loading };
};
