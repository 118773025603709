import React, { useState } from "react";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronUpIcon } from "lucide-react";
import { Control, Controller, FieldPath, useFormContext } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Toggle } from "@/app/components";
import { AddOpportunityFormSection } from "@/app/screens/opportunities/add-new/via-email/sections/add-opportunity-form-section.tsx";

import { CompanyAdditionalDetailsSection } from "./sections/company-additional-details-section.tsx";
import { CompanyDetailsSection } from "./sections/company-details-section.tsx";
import { CompanyFoundersSection } from "./sections/company-founders-section.tsx";
import { CompanyNoteSection } from "./sections/company-note-section.tsx";
import { CompanySourceSection } from "./sections/company-source-section.tsx";
import { MainCompanyDataSection } from "./sections/main-company-data-section.tsx";
import { MatchingCompanies } from "./sections/matching-companies-section/matching-companies-section.tsx";
import { CompaniesFromEmailForm } from "./use-add-company-from-email-form.ts";

type AddOpportunityComponentProps = {
  index: number;
  disabled?: boolean;
};

const SkipToggle = ({
  name,
  control,
  skipped,
  onClose,
}: {
  name: FieldPath<CompaniesFromEmailForm>;
  control: Control<CompaniesFromEmailForm>;
  skipped: boolean;
  onClose: () => void;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="absolute right-10 top-1/2 flex -translate-y-1/2 items-center gap-1 ">
          {skipped && (
            <span className="pointer-events-none select-none text-xs font-semibold text-neutral-500">Skipped</span>
          )}
          {/*reversing the value for ui purpose*/}
          <Toggle
            value={!field.value}
            size="md"
            setValue={(val) => {
              field.onChange(!val);
              if (!val) {
                onClose();
              }
            }}
          />
        </div>
      )}
    />
  );
};

enum FormSection {
  MainData,
  Details,
  Founders,
  AdditionalDetails,
  Note,
  SourceAndAssignees,
}

export const AddOpportunityForm = ({ index, disabled }: AddOpportunityComponentProps) => {
  const form = useFormContext<CompaniesFromEmailForm>();
  const [openedSection, setOpenedSection] = useState<FormSection | null>(FormSection.MainData);

  const fieldName = `companies.${index}` as const;

  const fieldState = form.getFieldState(fieldName, form.formState);
  const [skipped, companyName] = form.watch([`${fieldName}.skip`, `${fieldName}.company.mainData.name`]);

  const handleToggleSection = (section: FormSection) => {
    if (section === openedSection) {
      setOpenedSection(null);
    } else {
      setOpenedSection(section);
    }
  };

  return (
    <Disclosure>
      {({ open, close }) => (
        <div
          className={cn(
            "h-fit w-full space-y-4 rounded-md bg-white p-4 shadow-xs",
            skipped && "bg-white/50",
            disabled && "pointer-events-none opacity-80",
          )}
        >
          <div className="relative">
            <DisclosureButton
              className={cn("flex w-full flex-row items-center justify-between")}
              disabled={skipped && !open}
            >
              <div className="flex items-center gap-2">
                <span>{companyName || "Company without a name"}</span>
                {fieldState.invalid && !skipped && !disabled && (
                  <span className="ml-auto text-xs font-semibold text-red-600">Incomplete</span>
                )}
                {!fieldState.invalid && <span className="ml-auto text-xs font-semibold text-green-600">Complete</span>}
              </div>

              {!disabled && (
                <ChevronUpIcon className={cn(`ml-auto size-5 transition-transform`, open && "rotate-180")} />
              )}
            </DisclosureButton>

            {!disabled && (
              <SkipToggle
                control={form.control}
                skipped={skipped}
                name={`${fieldName}.skip` as const}
                onClose={close}
              />
            )}
          </div>
          <DisclosurePanel>
            <div>
              <AddOpportunityFormSection
                label="Main Data"
                state={form.getFieldState(`${fieldName}.company.mainData` as const, form.formState)}
                open={openedSection === FormSection.MainData}
                onToggle={() => handleToggleSection(FormSection.MainData)}
              >
                <MatchingCompanies index={index} />
                <MainCompanyDataSection index={index} />
              </AddOpportunityFormSection>
              <AddOpportunityFormSection
                label="Details"
                state={form.getFieldState(`${fieldName}.company.details` as const, form.formState)}
                open={openedSection === FormSection.Details}
                onToggle={() => handleToggleSection(FormSection.Details)}
              >
                <CompanyDetailsSection index={index} />
              </AddOpportunityFormSection>
              <AddOpportunityFormSection
                label="Founders"
                state={form.getFieldState(`${fieldName}.company.founders` as const, form.formState)}
                open={openedSection === FormSection.Founders}
                onToggle={() => handleToggleSection(FormSection.Founders)}
              >
                <CompanyFoundersSection index={index} />
              </AddOpportunityFormSection>
              <AddOpportunityFormSection
                label="Additional Details"
                state={form.getFieldState(`${fieldName}.company.additionalDetails` as const, form.formState)}
                open={openedSection === FormSection.AdditionalDetails}
                onToggle={() => handleToggleSection(FormSection.AdditionalDetails)}
              >
                <CompanyAdditionalDetailsSection index={index} />
              </AddOpportunityFormSection>
              <AddOpportunityFormSection
                label="Note"
                state={form.getFieldState(`${fieldName}.company.note` as const, form.formState)}
                open={openedSection === FormSection.Note}
                onToggle={() => handleToggleSection(FormSection.Note)}
              >
                <CompanyNoteSection index={index} />
              </AddOpportunityFormSection>
              <AddOpportunityFormSection
                label="Source & Assignees"
                state={form.getFieldState(`${fieldName}.company.attributes` as const, form.formState)}
                open={openedSection === FormSection.SourceAndAssignees}
                onToggle={() => handleToggleSection(FormSection.SourceAndAssignees)}
              >
                <CompanySourceSection index={index} />
              </AddOpportunityFormSection>
            </div>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
};
