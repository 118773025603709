import React, { MouseEventHandler } from "react";

import { cn } from "@/lib/utils.ts";

import { ColorType, Icon, IconType } from "@/app/components";

type LabelColor = "neutral" | "low" | "medium" | "high";

const labelStyleMap: (outline: boolean) => Record<
  LabelColor,
  {
    color: `text-${ColorType}`;
    bgColor: `bg-${ColorType}` | `border-${ColorType}`;
  }
> = (outline) => ({
  neutral: {
    color: "text-neutral-500",
    bgColor: outline ? "border-neutral-300" : "bg-neutral-100",
  },
  low: {
    color: "text-green-600",
    bgColor: outline ? "border-green-300" : "bg-green-100",
  },
  medium: {
    color: "text-yellow-600",
    bgColor: outline ? "border-yellow-500" : "bg-yellow-100",
  },
  high: {
    color: "text-red-400",
    bgColor: outline ? "border-red-300" : "bg-red-100",
  },
});

interface LabelProps {
  description: string;
  outline?: boolean;
  type?: LabelColor;
  onClick?: () => void;
  textColor?: `text-${ColorType}`;
  iconRight?: IconType;
  onIconRightClick?: MouseEventHandler<SVGSVGElement>;
  iconLeft?: IconType;
  containerClassName?: string;
}

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(function Label(
  {
    description,
    type = "neutral",
    outline = false,
    iconRight,
    iconLeft,
    onClick,
    textColor,
    containerClassName,
    onIconRightClick,
  }: LabelProps,
  ref,
) {
  const { bgColor, color } = labelStyleMap(Boolean(outline))[type];

  return (
    <label
      className={cn(
        "flex size-fit items-center justify-center rounded-md px-3 py-0.5",
        outline ? "border" : "",
        bgColor,
        containerClassName,
      )}
      onClick={onClick}
      ref={ref}
    >
      {iconLeft && <Icon type={iconLeft} className={cn(`mr-2 size-3`, color)} />}
      <span className={cn("whitespace-nowrap text-xs font-medium lg:text-sm", textColor || color)}>{description}</span>
      {iconRight && <Icon type={iconRight} className={cn(`ml-2 size-3`, color)} onClick={onIconRightClick} />}
    </label>
  );
});
