import { cn } from "@/lib/utils";

import { Button } from "@/app/components";

export interface CompleteSentimentActionProps {
  loading?: boolean;
  showNext?: boolean;
  showBack?: boolean;
  disabled?: boolean;
  onBack?: () => void;
  onSubmit?: () => void;
  onNext?: () => void;
}

export const CompleteSentimentActions = ({
  showBack,
  showNext,
  loading,
  disabled,
  onNext,
  onSubmit,
  onBack,
}: CompleteSentimentActionProps) => {
  return (
    <div className={"bottom flex w-full flex-col items-center justify-end lg:flex-row lg:space-x-4"}>
      <Button
        size="md"
        variant={"outline"}
        className={cn(
          "order-last mt-2 w-full lg:order-first lg:mt-0 lg:w-auto",
          showBack ? "" : "hidden",
        )}
        onClick={onBack}
        text="Back"
        data-cy="btn-back"
      />
      {showNext ? (
        <Button
          size="md"
          text="Next"
          variant={"gradient"}
          className={"w-full lg:w-auto"}
          disabled={disabled}
          onClick={onNext}
          data-cy="btn-next"
        />
      ) : (
        <Button
          size="md"
          text="Submit"
          variant={"gradient"}
          className={"w-full lg:w-auto"}
          loading={loading}
          disabled={disabled}
          onClick={onSubmit}
          data-cy="btn-submit"
        />
      )}
    </div>
  );
};
