import { cn } from "@/lib/utils";

import { ModalHeader } from "../../components";

export const ActionModalHeader = ({
  title = "",
  containerClassName,
}: {
  title?: string;
  containerClassName?: string;
}) => {
  return <ModalHeader title={title} containerClassName={cn("lg:mt-5 xl:mt-5", containerClassName)} />;
};
