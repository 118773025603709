import React from "react";

import { Action } from "@/gql/graphql";

import { cn, reduceSignalsToFormattedText } from "@/lib/utils.ts";

import { Button, Card, Icon } from "@/app/components";
import { useActionMutation } from "@/app/hooks";
import { getCompanyStatus } from "@/app/misc/helpers";
import { ConfirmModal } from "@/app/screens/modal";
import { AttachmentsCommentsCard } from "@/app/screens/opportunities/opportunity/components/highlights/attachments-comments/attachments-comments-card.tsx";
import { CapitalConcentration } from "@/app/screens/opportunities/opportunity/components/highlights/capital-concentration/capital-concentration.tsx";
import { CashProfile } from "@/app/screens/opportunities/opportunity/components/highlights/cash-profile/cash-profile.tsx";
import { EvReturnCard } from "@/app/screens/opportunities/opportunity/components/highlights/ev-return-card.tsx";
import { ExcitementScoreCard } from "@/app/screens/opportunities/opportunity/components/highlights/excitement-score/excitement-score-card.tsx";
import { InvestedBasecampFundsCard } from "@/app/screens/opportunities/opportunity/components/highlights/invested-basecamp-funds/invested-basecamp-funds-card.tsx";
import { LatestReturnProfileCard } from "@/app/screens/opportunities/opportunity/components/highlights/latest-return-profile/latest-return-profile-card.tsx";
import { RevenueCard } from "@/app/screens/opportunities/opportunity/components/highlights/revenue-card.tsx";
import {
  useHighlights,
  HighlightsProps,
} from "@/app/screens/opportunities/opportunity/components/highlights/use-highlights.tsx";
import { authStore } from "@/app/stores/auth.store";
import modalStore from "@/app/stores/modal.store";
import { Signal as SignalType } from "@/app/types";

import { useOpportunityContext } from "../../opportunity-context";

export const Highlights = ({ company, signal, portCo }: HighlightsProps) => {
  const { excitementScore, latestReturnProfile, investedBasecampFunds, timesFlagged, attachmentsComments } =
    useHighlights({ company, signal });
  return (
    <section data-cy="details-page-highlights" className="@container">
      {/*{signal && <Signal signal={signal} />}*/}
      <div className={cn("grid auto-cols-fr grid-cols-1 gap-3 @2xl:grid-cols-2 @6xl:grid-cols-4")}>
        <ExcitementScoreCard data={excitementScore} />
        <LatestReturnProfileCard data={latestReturnProfile} />
        {portCo && <CashProfile company={company} />}
        <InvestedBasecampFundsCard investedBasecampFunds={investedBasecampFunds} timesFlagged={timesFlagged} />
        <RevenueCard company={company} />
        <EvReturnCard company={company} />
        {portCo && <CapitalConcentration company={company} />}
        <AttachmentsCommentsCard data={attachmentsComments} />
      </div>
    </section>
  );
};

/**
 * @deprecated
 */
const Signal = ({ signal }: { signal: SignalType }) => {
  const { company, signalAssignees, refresh } = useOpportunityContext();
  const { inPipeline, hasSignal } = getCompanyStatus(company);
  const isAssignee = signalAssignees.some(({ id }) => id === authStore.userId);
  const { onSubmit } = useActionMutation({
    onSuccess: async () => {
      if (refresh) {
        await refresh();
      }
      modalStore.close();
    },
  });
  const formattedText = reduceSignalsToFormattedText([signal]);

  return (
    <Card className="mb-4 flex gap-2 p-3">
      <Icon type={"SignalGradient"} className="ml-1 size-6 shrink-0" />
      <div className="">
        <p
          className="text-sm font-medium"
          dangerouslySetInnerHTML={{
            __html:
              String(formattedText)
                ?.replaceAll("<b>", '<b class="font-semibold">')
                ?.replaceAll("<br/>", ' <b class="font-semibold">•</b> ') || "",
          }}
        />
        {inPipeline && hasSignal && isAssignee && (
          <Button
            variant="secondary"
            size="md"
            text="Not Relevant"
            className="mt-2"
            onClick={() =>
              modalStore.open(ConfirmModal, {
                props: {
                  title: "Are you sure this Company is not relevant to you?",
                  subTitle: "By confirming, you will be unassigned from the company",
                  handleConfirm: () =>
                    onSubmit({
                      entityId: company?.id,
                      resurface: "never",
                      assignees: signalAssignees.filter(({ id }) => id !== authStore.userId).map(({ id }) => id),
                      action: Action.Snooze,
                      successMessage: `Successfully unassigned from ${company?.name}`,
                    }),
                },
              })
            }
          />
        )}
      </div>
    </Card>
  );
};
