import React, { memo, useState } from "react";

import toast from "react-hot-toast";

import { Button } from "@/app/components";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay.tsx";
import { ConfirmModal } from "@/app/screens/modal";
import { FounderSearchResult } from "@/app/screens/opportunities/components/founder-search-autocomplete.tsx";
import {
  FounderEdit,
  FounderFormData,
} from "@/app/screens/opportunities/opportunity/components/founders/founder-edit.tsx";
import { Section, SectionTitle } from "@/app/screens/opportunities/opportunity/components/section.tsx";
import { useUnlinkFounderMutation, useUpsertFounderMutation } from "@/app/service/founders.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { EmptySection } from "../empty-section.tsx";

import { Founder } from "./founder.tsx";
import { FounderFromTeam } from "./founders.types.ts";
import { useFounders } from "./use-founders.ts";

export const Founders = memo(function Founders({ companyId }: { companyId: string }) {
  const { data: founders, loading: fetchLoading } = useFounders(companyId);
  const [unlinkFounder, { loading: unlinkLoading }] = useUnlinkFounderMutation({
    refetchQueries: ["GetCompanyFounders"],
  });
  const [upsertFounder, { loading: upsertLoading }] = useUpsertFounderMutation({
    refetchQueries: ["GetCompanyFounders"],
  });

  const loading = fetchLoading || unlinkLoading || upsertLoading;

  const [mode, setMode] = useState<"view" | "edit" | "create">("view");

  const handleRemove = async (founder: FounderFromTeam) => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will unlink the founder from this company!`,
        handleConfirm: async () => {
          await unlinkFounder({ variables: { unlinkFounderDataInput: { companyId, founderId: founder.id } } });
          toast.success("Succesfully unlinked");
          modalStore.close();
        },
      },
    });
  };

  const handleCreateFounder = async (data: FounderFormData, selectedFounder: FounderSearchResult) => {
    const founderData = selectedFounder?.__typename === "LgFounder" ? "lgFounder" : "dealroomFounder";

    await upsertFounder({
      variables: { upsertFounderDataInput: { company: companyId, [founderData]: selectedFounder?.id, details: data } },
    });
    toast.success("Succesfully linked founder");
    setMode("view");
  };

  return (
    <Section id={"founders"}>
      <SectionTitle
        title="Founders"
        actions={
          mode === "view" && (
            <Button
              size="sm"
              variant="secondary"
              text="Add Founder"
              onClick={() => {
                setMode("create");
              }}
            />
          )
        }
      />
      {loading && <LoadingOverLay className="rounded-sm" />}
      {mode === "create" && <FounderEdit onSubmit={handleCreateFounder} onClose={() => setMode("view")} />}
      {mode === "view" && (
        <>
          <div className="space-y-4">
            {!founders.length ? (
              <EmptySection text={"No Founders Listed"} />
            ) : (
              founders.map((founder) => (
                <Founder key={founder.id} founder={founder} onRemove={() => handleRemove(founder)} />
              ))
            )}
          </div>
        </>
      )}
    </Section>
  );
});
