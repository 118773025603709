import { LgCompanyFilter } from "@/gql/graphql.ts";

import { useDashboardCompanies } from "./use-dashboard-companies";

export const useAddedByMeCompanies = (creatorId: string, status: string = "ready") => {
  const filter: LgCompanyFilter = {
    creatorId: { equalTo: creatorId },
    status: { equalTo: status },
  };

  return useDashboardCompanies(filter);
};
