import { shared } from "./styles";
import { EmptyState } from "../../../../../components/label";
import { Badges } from "@/app/types";
import { cn } from "@/lib/utils";

export interface BaseCampProps {
  baseCamp?: Badges;
  className?: string;
}

export const BaseCamp = ({
  baseCamp,
  className = "flex flex-col justify-start gap-y-2 py-6",
}: BaseCampProps) => {
  return (
    <div className={`${className}`}>
      <span className={cn("text-sm font-semibold", shared.subTitleText)}>Basecamp</span>
      {baseCamp ? (
        <p className={cn(`text-sm font-medium`, shared.body)}>
          {baseCamp?.extra?.map(({ name }) => name).join(",") as string}
        </p>
      ) : (
        <EmptyState className={shared.body} />
      )}
    </div>
  );
};
