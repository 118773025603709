import { gql } from "@apollo/client";

export const contactsLookupQuery = gql`
  query networkContactsLookup($q: String, $sort: String, $filters: String) {
    contacts: networkContactsLookup(q: $q, sort: $sort, filterS: $filters) {
      nodes {
        email
        name
        tags
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
        contactType
      }
    }
  }
`;


