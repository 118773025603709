import { text } from "@/app/styles";
import { Icon } from "../../../../../components";
import { cn } from "@/lib/utils.ts";

export const EngagementTitle = () => {
  return (
    <div className={"mb-2 flex items-center gap-x-2"}>
      <Icon type={"HandShake"} className={text.titleIcon} />
      <span className={cn("text-xl font-semibold", text.titleText)}>Engagement</span>
    </div>
  );
};
