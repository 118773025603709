import Sheet from "react-modal-sheet";
import {
  Button,
  Icon,
  Input,
  IosTransitionEnter,
} from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { CloseModal, InputWithPills } from "@/app/screens/modal/components";
import Form from "@/app/components/forms";
import { observer } from "mobx-react";

import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { useRequestSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import { navigateToModal } from "./components/add-opportunity-misc";

export const RequestSentiment = observer(({ form }) => {
  const [requestSentiment, { data, loading }] =
    useRequestSentimentFormMutation();

  return (
    <IosTransitionEnter type={"exit"}>
      <div className={"flex h-full flex-grow flex-col bg-background"}>
        <CloseModal />
        <Sheet.Header
          className={"my-8 flex flex-col items-center justify-center"}
        >
          <span className={"text-xl font-semibold"}>Sentiment Form</span>
        </Sheet.Header>
        <Sheet.Content className={"mx-5 flex h-full justify-between"}>
          <Form.Root form={form}>
            <div>
              <Form.Field
                fieldId={"category"}
                render={({ onChange, value, fieldId }) => (
                  <div className="flex flex-col">
                    <Form.Label />
                    <InputWithPills
                      containerClassName={"px-2 py-1 h-10 bg-white relative"}
                      selectedOption={value}
                      setSelectedOption={(items = []) => onChange(items[0])}
                      inputPosition={"right"}
                      onClick={() =>
                        navigateToModal({
                          fieldId,
                          configId: "Meetings",
                          flowId: "SelectComponent",
                          label: "Select Form Category",
                          multiSelect: false,
                        })
                      }
                    >
                      <div className="flex flex-1 items-center justify-end pr-1">
                        <Icon
                          type={"Chevron Right"}
                          className={"h-4 w-4 text-primary"}
                          onClick={() =>
                            navigateToModal({
                              fieldId,
                              configId: "Meetings",
                              flowId: "SelectComponent",
                              label: "Select Form Category",
                            })
                          }
                        />
                      </div>
                    </InputWithPills>
                    <Form.Error />
                  </div>
                )}
              />
              <Form.Field
                fieldId="assignees"
                render={({ onChange, value, fieldId }) => (
                  <div className={"my-3 flex flex-col"}>
                    <Form.Label />
                    <InputWithPills
                      containerClassName={"px-2 py-1 h-10 bg-white relative"}
                      selectedOption={value}
                      setSelectedOption={onChange}
                      inputPosition={"right"}
                      onClick={() =>
                        navigateToModal({
                          fieldId,
                          configId: "Members",
                          flowId: "SelectComponent",
                          label: "Meeting Category",
                        })
                      }
                    >
                      <div className="flex flex-1 items-center justify-end pr-1">
                        <Icon
                          type={"Chevron Right"}
                          className={"h-4 w-4 text-primary"}
                          onClick={() =>
                            navigateToModal({
                              fieldId,
                              configId: "Members",
                              flowId: "SelectComponent",
                              label: "Meeting Category",
                            })
                          }
                        />
                      </div>
                    </InputWithPills>
                    <Form.Error />
                  </div>
                )}
              />
              <Form.Field
                fieldId="meetingDate"
                render={({ value, fieldId }) => (
                  <div className={"flex flex-col"}>
                    <Form.Label />
                    <Input
                      onClick={() =>
                        navigateToModal({
                          fieldId,
                          configId: "DatePicker",
                          flowId: "DateComponent",
                          label: "Choose Date",
                          value,
                          calendarProps: {
                            showOutsideDays: true,
                            disabled: [{ after: new Date() }],
                          },
                        })
                      }
                      value={value}
                      readOnly={true}
                      iconRight={"Chevron Right"}
                    />
                    <Form.Error />
                  </div>
                )}
              />
            </div>
          </Form.Root>
          <div className={"mb-8 flex w-full space-x-4"}>
            <Button
              text={"Cancel"}
              variant={"outline"}
              className={"w-full"}
              onClick={() => globalModalStore.toggleModal()}
            />
            <Button
              text={"Add"}
              disabled={!form?.isValid}
              loading={loading}
              variant={"primary"}
              className={"w-full"}
              iconLeft={"Sentiment"}
              onClick={async () => {
                const { assignees, meetingDate, category } = form.values();
                const { company }: any = opportunitiesStore.state.extra || {};

                await requestSentiment({
                  variables: {
                    input: {
                      company: { id: company?.id, name: company?.name },
                      assignees: Array.from(assignees).map(
                        (item: any) => item?.value
                      ),
                      meetingDate,
                      category: category?.value,
                    },
                  },
                  onCompleted: () => globalModalStore.toggleModal(),
                });
              }}
            />
          </div>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
});
