import { useState } from "react";

import { useNavigate } from "react-router";

import { Routes } from "@/app/constants";
import { RequestSentimentModal } from "@/app/screens/modal";
import modalStore from "@/app/stores/modal.store";

import { OptionValue } from "../modal-content";

export const useSelectModal = ({ company }) => {
  const navigate = useNavigate();
  const [selected, handleSelect] = useState<OptionValue | undefined>();

  const handleNext = () => {
    if (selected === "opportunity") {
      navigate(Routes.addCompany);
      modalStore.close();
    } else if (selected) {
      modalStore.progress(RequestSentimentModal, { props: { type: selected, company } });
    }
  };

  return {
    selected,
    handleNext,
    handleSelect,
  };
};
