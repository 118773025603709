import { cn } from "@/lib/utils";

import { Icon } from "@/app/components";

import { shared } from "./styles";

export interface AccessTitleProps {
  iconClassName?: string;
  titleClassName?: string;
  containerClassName?: string;
}

export const AccessTitle = ({ iconClassName = "", titleClassName = "", containerClassName = "" }: AccessTitleProps) => {
  return (
    <h2 className={cn("mb-2 flex  items-center gap-x-2", containerClassName)}>
      <Icon type={"Lock"} className={cn(shared.titleIcon, iconClassName)} />
      <span className={cn("text-xl font-semibold", shared.titleText, titleClassName)}>Access</span>
    </h2>
  );
};
