import React from "react";

import { addYears, format, subDays } from "date-fns";
import { DayPicker, DayPickerRangeProps, DayPickerSingleProps, useNavigation } from "react-day-picker";

import { Icon } from "../icon/icon";

import { NavButton } from "./nav-button";

export type CalendarProps = Omit<DayPickerSingleProps | DayPickerRangeProps, "value">;

export function Calendar<T extends CalendarProps>({
  mode,
  defaultMonth,
  selected,
  onSelect,
  locale,
  disabled = [{ from: new Date(0), to: subDays(new Date(), 1) }],
  classNames,
  weekStartsOn = 0,
  showOutsideDays = true,
  enableYearNavigation = false,
  ...other
}: T & { enableYearNavigation?: boolean }) {
  return (
    <DayPicker
      className={"outline-none"}
      showOutsideDays={showOutsideDays}
      mode={mode as any}
      defaultMonth={defaultMonth}
      selected={selected}
      onSelect={onSelect}
      locale={locale}
      disabled={disabled}
      weekStartsOn={weekStartsOn}
      classNames={{
        months: "w-full flex flex-col",
        month: "flex flex-col items-center",
        caption: "flex justify-center pt-2 relative items-center",
        caption_label:
          "text-tremor-default text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis font-medium",
        nav: "space-x-1 flex items-center",
        nav_button:
          "flex items-center justify-center p-1 h-7 w-7 outline-none transition duration-100 border border-tremor-border dark:border-dark-tremor-border rounded-tremor-small text-tremor-content-subtle dark:text-dark-tremor-content-subtle hover:text-tremor-content dark:hover:text-dark-tremor-content",
        nav_button_previous: "ml-2",
        nav_button_next: "mr-2",
        table: "border-collapse mt-1 w-full",
        head_row: "grid grid-cols-7",
        head_cell: "w-full font-normal text-center text-tremor-content-subtle dark:text-dark-tremor-content-subtle",
        row: "grid grid-cols-7 mt-0.5",
        cell: "w-full text-center relative focus-within:relative text-tremor-default text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis",
        day: "w-6 h-6 p-0 hover:bg-tremor-background-subtle dark:hover:bg-dark-tremor-background-subtle outline-tremor-brand dark:outline-dark-tremor-brand rounded-tremor-small",
        day_today: "font-bold",
        day_selected:
          "aria-selected:bg-tremor-background-emphasis hover:bg-tremor-background-emphasis focus:bg-tremor-background-emphasis aria-selected:text-tremor-content-inverted dark:aria-selected:bg-dark-tremor-background-emphasis dark:aria-selected:text-dark-tremor-content-inverted ",
        day_disabled: "text-tremor-content-subtle dark:text-dark-tremor-content-subtle disabled:hover:bg-transparent",
        day_outside: "text-tremor-content-subtle dark:text-dark-tremor-content-subtle",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <Icon type="Chevron Left" className="size-8" {...props} />,
        IconRight: ({ ...props }) => <Icon type="Chevron Right" className="size-8" {...props} />,
        Caption: ({ ...props }) => {
          const { goToMonth, nextMonth, currentMonth, previousMonth } = useNavigation();

          return (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center space-x-1">
                {enableYearNavigation && (
                  <NavButton
                    onClick={() => currentMonth && goToMonth(addYears(currentMonth, -1))}
                    icon={"DoubleArrowLeft"}
                  />
                )}

                <NavButton onClick={() => previousMonth && goToMonth(previousMonth)} icon={"Chevron Left"} />
              </div>

              <span className="dark:text-dark-tremor-content-emphasis text-tremor-title font-medium capitalize tabular-nums text-tremor-content-emphasis">
                {format(props.displayMonth, "LLLL yyy", { locale })}
              </span>

              <div className="flex items-center space-x-1">
                <NavButton onClick={() => nextMonth && goToMonth(nextMonth)} icon={"Chevron Right"} />
                {enableYearNavigation && (
                  <NavButton
                    onClick={() => currentMonth && goToMonth(addYears(currentMonth, 1))}
                    icon={"DoubleArrowRight"}
                  />
                )}
              </div>
            </div>
          );
        },
      }}
      {...other}
    />
  );
}
