import { WatchQueryFetchPolicy } from "@apollo/client";

import { GetDashboardCompaniesDocument, LgCompanyFilter } from "@/gql/graphql.ts";

import { usePaginatedQuery } from "@/app/hooks";
import { authStore } from "@/app/stores/auth.store.tsx";

import { DashboardCompany, DashboardSectionData } from "../dashboard.types";

export const useDashboardCompanies = (
  filter: LgCompanyFilter = {},
  fetchPolicy: WatchQueryFetchPolicy = "cache-and-network",
): DashboardSectionData<DashboardCompany> => {
  const queryVariables = {
    filter: {
      nzrUserCompanyTagsByCompanyId: {
        none: {
          userId: { equalTo: authStore.userId },
          tags: {
            contains: {
              hide: "1",
            },
          },
        },
      },
      ...filter,
    },
    pageSize: 6,
    after: null,
  };

  const {
    data,
    loading,
    loadLess,
    loadMore: refetch,
    loadingLess,
    loadingMore,
  } = usePaginatedQuery({
    query: GetDashboardCompaniesDocument,
    rootEntity: "lgCompanies",
    variables: queryVariables,
    options: { fetchPolicy },
  });

  const loadMore = () => {
    const endCursor = data?.lgCompanies?.pageInfo.endCursor;

    if (refetch) {
      refetch(queryVariables, endCursor);
    }
  };

  if (!data?.lgCompanies) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingMore: false,
      loadingLess: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.lgCompanies.totalCount,
    items: data.lgCompanies.edges.map(({ node }) => node),
    pageInfo: data.lgCompanies.pageInfo,
    loading: loading && !loadingLess,
    loadingMore,
    loadingLess,
    loadLess,
    loadMore,
  };
};
