import { Icon, IconType, Label } from "@/app/components";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";
import { cn } from "@/lib/utils.ts";
import { TW_INPUT_CORE, TW_INPUT_CORE_FOCUS } from "@/app/components/input";

type InputWithPillsProps = {
  children?: React.ReactNode;
  inputPosition?: "right" | "left";
  inputFocus?: boolean;
  iconLeft?: IconType;
  containerClassName?: string;
  placeholder?: string;
  selectedOption: OptionsType | Array<OptionsType>;
  disableFocus?: boolean;
  canDelete?: boolean;
  setSelectedOption?: (args?: OptionsType | Array<OptionsType>) => void;
  disabled?: boolean;
  onClick?: () => void;
};

export const InputWithPills = forwardRef<React.ReactNode, InputWithPillsProps>(
  (
    {
      children,
      iconLeft,
      containerClassName = "",
      selectedOption,
      setSelectedOption,
      inputPosition = "right",
      disableFocus = false,
      disabled = false,
      canDelete = true,
      placeholder,
      onClick,
    }: InputWithPillsProps,
    ref
  ) => {
    const scrollIntoViewLastElement = useRef<HTMLLabelElement>(null);

    useEffect(() => {
      if (scrollIntoViewLastElement?.current && !disableFocus) {
        scrollIntoViewLastElement?.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, [selectedOption, disableFocus]);

    const values = useMemo(() => {
      if (Array.isArray(selectedOption)) {
        return selectedOption.filter(Boolean).map((e, i) => (
          <Label
            key={e?.label + i}
            description={e?.label}
            textColor={"text-black"}
            onClick={() => {
              const filteredOptions = selectedOption.filter(
                (option) => option.value !== e.value
              );
              setSelectedOption?.(filteredOptions);
            }}
            iconRight={canDelete ? "X" : undefined}
            containerClassName={"select-none cursor-pointer"}
            ref={
              selectedOption.length - 1 === i ? scrollIntoViewLastElement : null
            }
          />
        ));
      }

      if (selectedOption?.label) {
        return (
          <Label
            description={selectedOption?.label}
            containerClassName={"select-none cursor-pointer"}
            textColor={"text-black"}
            onClick={() => setSelectedOption?.(undefined)}
            iconRight={canDelete ? "X" : undefined}
          />
        );
      }

      if (placeholder)
        return (
          <span
            className={"text-sm text-neutral-400 font-light select-none"}
            onClick={() => setSelectedOption?.(undefined)}
          >{placeholder}</span>
        );
    }, [canDelete, placeholder, selectedOption, setSelectedOption]);

    return (
      <button
        disabled={disabled}
        className={cn(
          `${TW_INPUT_CORE} ${TW_INPUT_CORE_FOCUS} h-[47px] cursor-pointer items-center px-4 ${containerClassName}`,
          {
            "text-neutral-300": disabled,
          }
        )}
        onClick={() => {
          if ((ref as React.RefObject<HTMLInputElement>)?.current)
            (ref as React.RefObject<HTMLInputElement>)?.current?.focus();

          onClick?.();
        }}
      >
        {iconLeft && (
          <div>
            <Icon
              type={iconLeft}
              className={
                "z-5 ml-2 mr-2 h-4 w-4 text-primary lg:ml-0 lg:h-5 lg:w-5"
              }
            />
          </div>
        )}
        {inputPosition === "left" && children}
        <div
          className={
            "no-scrollbar z-10 flex space-x-0.5 overflow-auto lg:space-x-2"
          }
          onClick={(e) => e.stopPropagation()}
        >
          {values}
        </div>
        {inputPosition === "right" && children}
      </button>
    );
  }
);
