import { LgMember } from "@/gql/graphql.ts";

import { getFullName } from "@/lib/utils.ts";

import { MemberCard } from "@/app/components";

import { getNetworkSignalSourceLabel } from "./network-signal-utils.ts";
import { NetworkSignalData } from "./network-signal.types.ts";

const SignalDataValue = ({ label, value }) => {
  return (
    <div className={"flex flex-col gap-2"}>
      <span className={"text-sm text-neutral-400"}>{label}</span>
      <span className={"text-sm font-semibold text-neutral-700"}>{value}</span>
    </div>
  );
};

export type NetworkSignalView = {
  author: Pick<LgMember, "firstName" | "lastName" | "avatar">;
  note: string;
  attributes: {
    canConnect: boolean;
    isActivelyFundraising: boolean;
    isHighPriority: boolean;
    isDeepTech: boolean;
  };
};

export const NetworkSignalView = ({ signal }: { signal: NetworkSignalData }) => {
  const { note, attributes } = signal;
  const { avatar, firstName, lastName } = signal.createdBy || {};

  const name = getFullName(firstName, lastName);
  const sourceType = getNetworkSignalSourceLabel(signal.sourceType);

  return (
    <div>
      <div className={"mb-3 flex items-center gap-1"}>
        <MemberCard
          firstname={firstName}
          lastname={lastName}
          image={avatar}
          includeName={false}
          imgClassName={"w-7 h-7 rounded-sm border-white bg-neutral-100"}
          className={"flex justify-start"}
        />
        <span className={"text-base font-semibold text-black"}>{name}</span>
        {!!signal.lastUpdatedUtc && <span className="text-xs text-gray-600">(edited)</span>}
      </div>
      <span className={"text-sm font-normal text-neutral-800"}>{note}</span>
      <div className={"mt-3 flex flex-wrap items-center gap-6"}>
        <SignalDataValue label="Where did you hear about it?" value={sourceType} />
        <SignalDataValue label={`Which ${sourceType}?`} value={signal.sourceName} />
        <SignalDataValue label={"Can we get connected?"} value={attributes.canConnect ? "Yes" : "No"} />
        <SignalDataValue label={"Are they actively raising?"} value={attributes.isActivelyFundraising ? "Yes" : "No"} />
        <SignalDataValue label={"Is this a high priority?"} value={attributes.isHighPriority ? "Yes" : "No"} />
        <SignalDataValue label={"Is this Deep tech?"} value={attributes.isDeepTech ? "Yes" : "No"} />
      </div>
    </div>
  );
};
