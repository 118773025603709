import React, { useEffect, useMemo, useState } from "react";

import { DealroomCompanyFilter, LgCompanyFilter } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { CompanyImg, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  Autocomplete,
  AutocompleteButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
} from "@/app/components/forms/autocomplete.tsx";
import { isLgCompany } from "@/app/screens/opportunities/add-new/add-company-new.types.ts";
import { useSearchCompaniesGlobalQuery } from "@/app/service/opportunities.gql.ts";

type SearchField = keyof Pick<LgCompanyFilter | DealroomCompanyFilter, "name" | "websiteUrl">;

const mapSearchFields = (searchFields: SearchField[], value: string): LgCompanyFilter | DealroomCompanyFilter => {
  if (searchFields.length === 0) {
    return {};
  }

  if (searchFields.length === 1) {
    return {
      [searchFields[0]]: { fuzzy: value },
    };
  }

  return {
    or: searchFields.map((field) => ({ [field]: { fuzzy: value } })),
  };
};

const useSearchResults = (searchFields: SearchField[] = ["name"]) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useSearchCompaniesGlobalQuery({
    variables: {
      limit: 10,
      offset: 0,
      lgFilter: mapSearchFields(searchFields, searchTerm),
      dealroomFilter: {
        and: [mapSearchFields(searchFields, searchTerm), { srcCompanyExist: false }],
      },
    },
    skip: !searchTerm,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = useMemo(() => {
    return [...(data?.lgCompanies?.nodes ?? []), ...(data?.dealroomCompanies?.nodes ?? [])];
  }, [data]);

  return { results, search, loading, searchTerm };
};

const CompanySearchInput = ({ handleSearch, loading, selectedCompany, onClear, placeholder }) => {
  const displayValue = (company: DealroomCompanyResult | string | null): string => {
    return typeof company === "string" ? company : company?.name ?? "";
  };

  return (
    <div className="relative">
      {selectedCompany && (
        <CompanyImg
          src={typeof selectedCompany === "object" ? selectedCompany.image : null}
          className="absolute left-4 top-1/2 !size-7 !min-w-7 basis-7 -translate-y-1/2 rounded-xs"
          imgClassName="rounded-xs"
        />
      )}
      <AutocompleteInput
        className={cn(
          "w-full rounded-sm border px-4 py-3 text-sm",
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
          selectedCompany && "pl-12",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-4 flex items-center gap-2">
        {loading && <ProgressSpinner className="size-4 text-inherit" />}
        {selectedCompany && <IconButtonV2 icon="X" size="xs" onClick={onClear} />}
        <AutocompleteButton as="div" className={"transition data-[open]:-scale-100"}>
          <IconButtonV2 icon="Chevron Down" size="xs" />
        </AutocompleteButton>
      </div>
    </div>
  );
};

type CompanySearchResult = ReturnType<typeof useSearchResults>["results"][number];

// extracting just DealroomCompany since we want to disable selection of LgCompany in the dropdown
export type DealroomCompanyResult = Extract<CompanySearchResult, { __typename?: "DealroomCompany" }> | undefined;

export type CompanyChangeData = DealroomCompanyResult | string | null;

type CompanySearchAutocompleteProps = {
  onChange: (company: CompanyChangeData) => void;
  value?: string;
  selectedCompany?: DealroomCompanyResult | null;
  searchFields?: SearchField[];
  placeholder?: string;
};

export const CompanySearchAutocomplete = ({
  onChange,
  value,
  selectedCompany,
  searchFields,
  placeholder,
}: CompanySearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults(searchFields);

  useEffect(() => {
    // pre-search companies on initial load to open dropdown with results when input is clicked
    if (value) {
      search(value);
    }
  }, []);

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (company: DealroomCompanyResult | string | null) => {
    console.log(company);
    // clear company selection when using custom name
    onChange(company);
  };

  const handleClear = () => {
    onChange(undefined);
  };

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <CompanySearchInput
            loading={loading}
            handleSearch={handleSearch}
            selectedCompany={selectedCompany}
            onClear={handleClear}
            placeholder={placeholder}
          />
        }
        options={
          <AutocompleteOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--input-width)] rounded-sm border bg-white p-1 [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "z-10", // needed because of broken location input
            )}
          >
            {!searchTerm && (
              <AutocompleteOption
                value={null}
                className={cn(
                  "mb-1 select-none rounded-sm bg-neutral-100 px-3 py-1.5 text-center text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
                disabled={true}
              >
                Start typing to see the results...
              </AutocompleteOption>
            )}
            {searchTerm && (
              <AutocompleteOption
                value={searchTerm}
                className={cn(
                  "mb-1 select-none rounded-sm bg-neutral-100 px-3 py-1.5 text-center text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
              >
                Use "{searchTerm}"
              </AutocompleteOption>
            )}
            {loading && (
              <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
            )}
            {results.map((company) => (
              <AutocompleteOption
                key={company.id ?? company.name}
                value={company}
                className={cn(
                  "flex select-none items-center gap-2 rounded-sm px-3 py-1.5 text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
                disabled={isLgCompany(company)}
              >
                <CompanyImg
                  src={company.image}
                  className="!size-8 !min-w-8 basis-8 rounded-xs"
                  imgClassName="rounded-xs"
                />
                <div>
                  <div className="text-sm">{company.name}</div>
                  <div className="text-xs text-gray-600">{company.websiteUrl}</div>
                </div>
                {isLgCompany(company) && (
                  <span className="ml-auto rounded-sm bg-gray-100 px-2 py-1 text-xs text-gray-800">
                    Exists in Nazare
                  </span>
                )}
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
