import posthog from "posthog-js";

export const initPosthog = () => {
  // disable on dev mode
  if(import.meta.env.MODE === "development") {
    return;
  }

  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    autocapture: !import.meta.env.DEV,
    disable_session_recording: import.meta.env.DEV,
    loaded: (ph) => {
      if (import.meta.env.DEV) {
        ph.stopSessionRecording();
        ph.opt_out_capturing();
      }
    },
    on_xhr_error: () => null,
  });
}
