import Sheet from "react-modal-sheet";
import { Button, Icon, IosTransitionEnter, Input, MultiRange, Checkbox } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { Fragment, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { OptionsType, singleSelections, opportunityFilterStore } from "@/app/screens/opportunities";

import { cn } from "@/lib/utils.ts";
import { defaultFilterRangeOptions } from "@/app/stores/filter.store.tsx";

export const FilterListMobile = () => {
  const {
    navState: { label, type },
  } = globalModalStore.state;

  return (
    <IosTransitionEnter type={"enter"}>
      <div className={"mx-5 flex h-full flex-grow flex-col py-5"}>
        <Sheet.Header className={"mb-6 flex items-center justify-center"}>
          <Icon
            type={"Chevron Left"}
            height={25}
            width={25}
            className={"absolute left"}
            onClick={() =>
              globalModalStore.navigate({
                modalType: "OpportunityFilter",
                navState: {
                  transitionAnimation: "exit",
                },
              })
            }
          />
          <span className="text-2xl font-semibold">{label}</span>
        </Sheet.Header>
        <Sheet.Content>
          {type === "multiselect" && <MultiSelectComponent label={label} />}
          {type === "range" && <RangeComponent label={label} />}
          <div className={"absolute bottom-0 w-full"}>
            <Button
              text={"Apply"}
              className={"w-full"}
              variant={"primary"}
              onClick={() =>
                globalModalStore.navigate({
                  modalType: "OpportunityFilter",
                  navState: {
                    transitionAnimation: "exit",
                  },
                })
              }
            />
          </div>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
};

const MultiSelectComponent = observer(({ label }) => {
  const category = "multiselect";
  const [query, setQuery] = useState<string>("");

  const list = opportunityFilterStore.getChoice({
    category: category,
    label,
  });
  const singleSelect = singleSelections.includes(label);
  const dataSource = opportunityFilterStore.multiSelectData[label];

  let filteredValues: Array<OptionsType> =
    query === ""
      ? dataSource
      : dataSource.filter((person: OptionsType) => {
          return person.label?.toLowerCase()?.includes(query.toLowerCase());
        });

  if (!singleSelect && list?.length > 10) {
    filteredValues = filteredValues
      ?.sort((a, b) => (a.label > b.label ? 1 : -1))
      .sort((a, _) => (list?.includes(a.label) ? -1 : 0));
  }

  return (
    <>
      {dataSource.length > 7 && (
        <Input
          placeholder={`Search ${label}`}
          iconLeft={"Search"}
          iconRight={"X"}
          value={query}
          onChange={({ target: { value } }) => {
            setQuery(value);
          }}
          iconRightOnClick={() => {
            setQuery("");
          }}
          className={"rounded-xs border-neutral-200"}
          containerClassName={"w-full mb-2"}
        />
      )}
      <div className={cn("mt-2 h-full", "pb-[20%]")}>
        <Sheet.Scroller className={"no-scrollbar"}>
          <div className={"flex max-h-[67dvh] flex-col space-y-2"}>
            {filteredValues?.map((e) => (
              <Fragment key={e.label}>
                <div
                  className={
                    "flex w-full cursor-pointer items-center justify-between rounded-sm border border-neutral-200 p-4 transition-all duration-100 ease-in-out active:bg-neutral-100"
                  }
                  onClick={() => {
                    let data;
                    if (!singleSelect) {
                      data = list?.includes(e.label) ? list.filter((i) => i !== e.label) : [...list, e.label];
                    } else {
                      data = [e.label];
                    }
                    opportunityFilterStore.changeAllChoice({
                      action: "select",
                      category,
                      label,
                      data,
                    });
                  }}
                >
                  <span className={"select-none text-sm font-medium"}>{e.label}</span>
                  <Icon
                    type={"FillCheck"}
                    width={20}
                    height={20}
                    className={cn(
                      "transition-all duration-100 ease-in-out",
                      list?.includes(e.label)
                        ? "text-black"
                        : "rounded-full border border-neutral-200 text-transparent",
                    )}
                  />
                </div>
              </Fragment>
            ))}
          </div>
        </Sheet.Scroller>
      </div>
    </>
  );
});

const filter_input_classname = "rounded-xs border border-neutral-200 px-0.5 py-2 text-sm text-center";

const RangeComponent = observer(({ label }) => {
  const { min, max, step } = useMemo(() => defaultFilterRangeOptions[label], [label]);

  const [{ minValue, maxValue }, setValue] = useState({
    minValue: min,
    maxValue: max,
  });
  const [showCustom, setShowCustom] = useState(false);

  useLayoutEffect(() => {
    const rangeValues = opportunityFilterStore.getChoice({
      category: "range",
      label,
    });
    if (rangeValues) {
      setValue({
        minValue: rangeValues[0],
        maxValue: rangeValues[1],
      });
    }
  }, []);

  return (
    <div className={"px-4"}>
      <div className={"flex w-full flex-col justify-center"}>
        <div className={"flex self-end"}>
          <span
            className={cn("rounded-sm bg-neutral-100 p-2 text-xs font-semibold text-neutral-600")}
          >{`${minValue} - ${maxValue} million`}</span>
        </div>
        <MultiRange
          containerClassName={"w-full"}
          disabled={showCustom}
          min={min}
          max={max}
          step={step}
          minValue={minValue}
          maxValue={maxValue}
          handleInput={setValue}
        />
        <Checkbox
          checked={showCustom}
          onClick={() => setShowCustom((prevState) => !prevState)}
          label={"Add Custom Amount"}
          containerClassName={"-ml-2.5 my-2"}
          labelClassName={"text-gray-900 font-medium"}
        />

        {showCustom && (
          <div className={"flex w-full flex-col justify-center gap-x-2"}>
            <span className={"text-xs font-semibold text-neutral-400"}>Between</span>
            <div className={"flex w-full gap-x-3"}>
              <div className={cn("flex w-full items-center", filter_input_classname)}>
                <span className={"px-1 text-xs font-semibold text-neutral-400"}>$</span>
                <input
                  type={"number"}
                  className={"w-full bg-transparent"}
                  value={minValue}
                  onChange={({ target: { value } }) => {
                    if (Number.isNaN(Number(value)) || Number(value) > maxValue) return;
                    setValue((prevState) => ({
                      ...prevState,
                      minValue: Number(value),
                    }));
                  }}
                />
              </div>

              <div className={cn("flex w-full items-center", filter_input_classname)}>
                <span className={"px-1 text-xs font-semibold text-neutral-400"}>$</span>
                <input
                  type={"number"}
                  className={"w-full bg-transparent"}
                  value={maxValue}
                  onChange={({ target: { value } }) => {
                    if (Number.isNaN(Number(value)) || Number(value) < minValue) {
                      return;
                    }

                    setValue((prevState) => ({
                      ...prevState,
                      maxValue: Number(value),
                    }));
                  }}
                />
                <span className={"px-2 text-xs font-semibold text-neutral-400"}>million</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
