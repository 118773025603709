export const text = {
  titleText: `text-title cursor-pointer`,
  subTitleText: `text-base cursor-pointer`,
  titleIcon: `h-6 w-6 text-primary xl:h-7 xl:w-7 cursor-pointer`,
  body: `text-sm lg:text-base font-normal cursor-pointer`,
  message: `lg:text-xs xl:text-sm cursor-pointer`,
  small: "text-xxs",
};

export const container = {
  boxContainer: `border border-gray-300 rounded-md w-full p-6`,
};
