import React from "react";

import { Controller } from "react-hook-form";

import { RadioGroups } from "@/app/components";

import { FormField } from "./form-field.tsx";

const investmentTypeOptions = [
  { value: "core", label: "Core" },
  { value: "strategic", label: "Strategic" },
];

// todo types
export const InvestmentTypeField = ({ name, control }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormField label="Select Type" required errorMessage={fieldState.error?.message}>
          <RadioGroups
            onSelected={(op) => field.onChange(op?.value)}
            options={investmentTypeOptions}
            defaultValue={field.value}
            className={`flex`}
            optionClassName="flex font-semibold cursor-pointer mr-4 pb-2"
            checkedClassName="text-black"
            labelClassName="inline-block order-last text-sm font-normal"
            checkIconContainer="mt-0 mr-2"
            iconClassName="h-11 w-11 text-black"
            withCheck
          />
        </FormField>
      )}
    />
  );
};
