import { useEffect } from "react";

import { observer } from "mobx-react";

import { refreshTokenCall } from "@/app/screens/login/refreshToken.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

const useRefreshToken = () => {
  const { isHydrated, expiresAt = 0 } = authStore;

  useEffect(() => {
    let timeout;

    if (!isHydrated) {
      return;
    }

    // should be one minute before token expires so there's time to refresh
    const authTokenExpirationDiff = expiresAt * 1000 - Date.now() - 60e3;

    if (authTokenExpirationDiff > 0) {
      timeout = setTimeout(() => {
        refreshTokenCall();
      }, authTokenExpirationDiff);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [expiresAt]);
};

export const AuthProvider = observer(function AuthProvider({ children }) {
  useRefreshToken();

  return children;
});
