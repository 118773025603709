export enum FlagFilterKey {
  EngagedOnly = "engaged_only",
}

export enum FilterType {
  Flag = "flag",
}

export type FlagFilter = {
  key: FlagFilterKey.EngagedOnly;
  value: boolean;
  type: FilterType.Flag;
};

export type Filters = Map<FlagFilterKey, FlagFilter>;

export const defaultFlagValues = new Map([[FlagFilterKey.EngagedOnly, true]]);

export function filtersToStr(filters: Filters): string {
  const res: string[] = [];
  for (const [_, filter] of filters) {
    if (filter.type === "flag") {
      if (Object.values(FlagFilterKey).includes(filter.key as FlagFilterKey)) {
        const value = filter.value ? "t" : "f";
        res.push([filter.key, value].join("-"));
      }
    }
  }
  return res.join(":");
}

export function filtersToStrGql(filters: Filters): string {
  const res: string[] = [];
  for (const [_, filter] of filters) {
    if (Object.values(FlagFilterKey).includes(filter.key as FlagFilterKey)) {
      if (filter.type === "flag" && filter.value === true) {
        res.push(filter.key);
      }
    }
  }
  return res.join(":");
}

export function filtersFromStr(str?: string | null): Filters {
  const filters: Filters = new Map();
  // NOTE: Default values
  for (const [key, value] of defaultFlagValues.entries()) {
    filters.set(key, { key, value, type: FilterType.Flag });
  }

  if (!str) {
    return filters;
  }

  const splited = str.split(":");
  for (const maybeFilter of splited) {
    const [maybeKey, maybeValue] = maybeFilter.split("-");
    if (!maybeKey || !maybeValue) continue;
    if (Object.values(FlagFilterKey).includes(maybeKey as FlagFilterKey)) {
      let value: boolean;
      if (maybeValue === "t") {
        value = true;
      } else if (maybeValue === "f") {
        value = false;
      } else {
        continue;
      }
      const filterKey = maybeKey as FlagFilterKey;
      filters.set(filterKey, { key: filterKey, value, type: FilterType.Flag });
    }
  }
  return filters;
}


export enum Column {
  Contact = "contact",
  Connectivity = "connectivity",
  LIncoming = "lincoming",
  LOutgoing = "loutgoing",
  NIncoming = "nincoming",
  NOutgoing = "noutgoing",
  Connections = "connections",
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type ColumnMapValue = {
  title: string;
  extraTheadClasses?: string;
  ascText?: string;
  descText?: string;
};

export const columns = new Map<Column, ColumnMapValue>([
  [Column.Contact, { title: "Contact", extraTheadClasses: "rounded-tl-md @5xl:w-[200px] @7xl:w-[350px]", ascText: "A-Z", descText: "Z-A" }],
  [
    Column.Connectivity,
    { title: "Connectivity", extraTheadClasses: "text-center w-[1px]", ascText: "Low to high", descText: "High to low" },
  ],
  [
    Column.LIncoming,
    {
      title: "Last Incoming",
      extraTheadClasses: "text-center w-[1px]",
      ascText: "Newest to oldest",
      descText: "Oldest to newest",
    },
  ],
  [
    Column.LOutgoing,
    {
      title: "Last Outgoing",
      extraTheadClasses: "text-center w-[1px]",
      ascText: "Newest to oldest",
      descText: "Oldest to newest",
    },
  ],
  [Column.NIncoming, { title: "# Incoming", extraTheadClasses: "w-[1px]", ascText: "Fewest to most", descText: "Most to fewest" }],
  [Column.NOutgoing, { title: "# Outgoing", extraTheadClasses: "w-[1px]", ascText: "Fewest to most", descText: "Most to fewest" }],
  [
    Column.Connections,
    { title: "Connections", extraTheadClasses: "rounded-tr-md", ascText: "Fewest to most", descText: "Most to fewest" },
  ],
]);

export const sortNodes = Array.from(columns.entries()).map(([column, c]) => {
  return {
    column: column,
    title: c.title,
    nodes: [SortDirection.Asc, SortDirection.Desc].map((direction) => {
      return {
        title: c[`${direction}Text`] || direction,
        extraAttrs: { "data-column-next-sort": sortPairValueToString(column, direction) },
        closeOnClick: true,
      };
    }),
  };
});
export const ColumnValues = Object.values(Column);
export const SortDirectionValues = Object.values(SortDirection);
export const defaultColumn = Column.Connectivity;
export const defaultSortDirection = SortDirection.Desc;

export function sortPairValueFromString(v?: string | null): [Column, SortDirection] {
  if (!v) return [defaultColumn, defaultSortDirection];
  const [desiredColumn, desiredSortDirection] = v.split("_");
  const resolvedColumn = ColumnValues.includes(desiredColumn as Column) ? (desiredColumn as Column) : defaultColumn;
  const resolvedSortDirection = SortDirectionValues.includes(desiredSortDirection as SortDirection)
    ? (desiredSortDirection as SortDirection)
    : defaultSortDirection;

  return [resolvedColumn, resolvedSortDirection];
}

export function sortPairValueToString(column: Column, sortDirection: SortDirection): string {
  return [column, sortDirection].join("_");
}
