import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { Button } from "@/app/components";
import { titleCase } from "@/app/misc/helpers.ts";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

export const UnderConstructionMobile = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onPress, text } = globalModalStore.state.navState;
  const path = titleCase(pathname?.split("/").filter(Boolean).at(0) || "");
  return (
    <div className={"flex min-h-[25dvh] flex-1 flex-col items-center justify-between px-4 pt-8"}>
      <div className={"flex flex-col items-center"}>
        <p className="text-xl font-semibold"> Under Construction</p>
        <p className={"mb-4 mt-2.5 text-lg font-medium text-neutral-400"}>🚧 Coming Soon! 🚧</p>
        <p className={"text-center text-sm font-medium"}>
          {text || `${path} is not yet available on mobile. Stay tuned for updates!`}
        </p>
      </div>
      <Button
        text={"I Understand"}
        variant={"gradient"}
        className="my-10 w-full"
        onClick={() => {
          if (onPress) {
            onPress();
          } else {
            navigate(-1);
          }
          globalModalStore.toggleModal();
        }}
      />
    </div>
  );
});
