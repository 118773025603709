import React, { PropsWithChildren, ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Card } from "@/app/components";
import { AnimatedNumber, AnimatedNumberProps } from "@/app/components/animated-number/animated-number.tsx";
import { normaliseDate } from "@/app/misc/helpers.ts";

type HighlightsBoxProps = {
  type: BoxType;
  className?: string;
};

export type BoxType = "Access" | "Insight" | "Engagement" | "Capital Concentration";

const colorScheme = {
  Access: "bg-highlightsCard-sky-blue",
  Insight: "bg-highlightsCard-light-green",
  "Capital Concentration": "bg-highlightsCard-light-green",
  Engagement: "bg-highlightsCard-pink",
};

export const HighlightsCard = ({ className, children, type }: PropsWithChildren<HighlightsBoxProps>) => {
  return (
    <Card
      className={cn(
        "group relative flex h-[250px] select-none flex-col items-center justify-between gap-y-6 pb-8 pt-12",
        className,
      )}
    >
      <Card.BoxType label={type} className={colorScheme[type]} />

      {children}
    </Card>
  );
};

export const HighlightsCardTitle = ({ text, className }: { text: string; className?: string }) => {
  return <span className={cn("text-base font-medium text-neutral-800", className)}>{text}</span>;
};

export const HighlightsCardFooter = ({ children }: PropsWithChildren) => {
  return <div className="flex h-8 items-center">{children}</div>;
};

export const HighlightsCardEmptyState = ({ text, textClassName }: { text: string; textClassName?: string }) => {
  return (
    <div className="flex h-full items-center justify-center text-center">
      <span className={cn("select-none text-xl font-semibold text-neutral-300", textClassName)}>{text}</span>
    </div>
  );
};

export const HighlightsCardNumber = ({
  value,
  options,
  className,
  prefix,
  suffix,
}: {
  value: number;
  options?: AnimatedNumberProps["options"];
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}) => {
  return (
    <AnimatedNumber
      value={value}
      options={options}
      className={cn("text-3xl font-medium text-neutral-800", className)}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export const HighlightsCardLastUpdated = ({ date }: { date?: string | null }) => {
  if (!date) {
    return null;
  }

  return (
    <span
      className={
        "w-fit select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs font-medium text-neutral"
      }
    >
      {normaliseDate(date) ?? ""}
    </span>
  );
};
