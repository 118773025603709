import React from "react";

import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ToastBar, Toaster } from "react-hot-toast";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";

import { AuthProvider } from "@/app/contexts/auth-provider.tsx";
import { initConfig } from "@/app/config/initConfig.ts";
import { MainRouter } from "@/app/router/router.tsx";

import { HotKeysProvider } from "../providers";

const { apolloClient } = initConfig();

export const Wrappers = () => (
  <PostHogProvider client={posthog}>
    <ApolloProvider client={apolloClient}>
      <GoogleOAuthProvider clientId={"937136485794-tkmuj3uvpjbl0e1n06avjnqhdb4u83sd.apps.googleusercontent.com"}>
        <HotKeysProvider>
          <AuthProvider>
            <RouterProvider router={createBrowserRouter(createRoutesFromElements(MainRouter))} />
          </AuthProvider>
          <Toaster>
            {(t) => (
              <ToastBar toast={t} style={{ padding: "12px" }}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
        </HotKeysProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  </PostHogProvider>
);
