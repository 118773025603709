import { cn } from "@/lib/utils";

import { Icon, Popover } from "@/app/components";

interface NotAvailableProps {
  tooltipText?: string;
  tooltipInfo?: React.ReactNode;
  tooltipContainer?: string;
  message?: string;
  textStyle?: string;
  containerStyle?: string;
  isSad?: boolean;
}

export const NotAvailable = ({
  tooltipText,
  tooltipInfo,
  tooltipContainer,
  message = "No Data",
  textStyle,
  containerStyle,
  isSad,
}: NotAvailableProps) => {
  return (
    <Popover
      containerClassName={containerStyle}
      childrenContent={
        (tooltipInfo || tooltipText) && <div className={tooltipContainer}>{tooltipText || tooltipInfo}</div>
      }
    >
      <p className={cn("whitespace-nowrap text-xxs font-medium leading-4 text-neutral-300 md:text-sm", textStyle)}>
        {message}
      </p>
      {isSad && <Icon type="SadSmile" className={cn("ml-1 inline-block size-4")} />}
    </Popover>
  );
};
