import { ReactNode } from "react";

import { cn } from "@/lib/utils";

import { AnimatedNumberProps, AnimatedNumber } from "../animated-number/animated-number";

import { ScoreChange } from "./score-change";

export const CardScore = ({
  current,
  previous,
  options,
  className,
  scoreClassname,
  prefix,
  suffix,
}: {
  current: number;
  previous?: number;
  options?: AnimatedNumberProps["options"];
  className?: string;
  scoreClassname?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}) => {
  return (
    <div className={cn(className)}>
      <AnimatedNumber
        value={current}
        options={options}
        className={cn("text-2xl font-medium text-neutral-800", scoreClassname)}
        prefix={prefix}
        suffix={suffix}
      />
      {previous !== undefined && <ScoreChange current={current} previous={previous} />}
    </div>
  );
};
