import {
  NativeModal,
} from "@/app/components";
import { observer } from "mobx-react";
import modalStore from "@/app/stores/modal.store";
import { useMoveTrello } from "./hooks";
import { MoveTrelloModalParams } from "./types";
import { ActionModalHeader } from "../components";

export const AddTrelloMobile = observer((props: MoveTrelloModalParams) => {
  const { actionBtns, renderForm } = useMoveTrello(props);

  return (
    <NativeModal
      isOpen
      handleClose={modalStore.close}
      footer={actionBtns}
      header={<ActionModalHeader title={props.title} />}>
        {renderForm}
    </NativeModal>
  );
});
