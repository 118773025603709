import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { Button, Icon, TextArea } from "@/app/components";
import { useEmailFeedback } from "@/app/screens/settings/hooks";
import { authStore } from "@/app/stores/auth.store.tsx";

export const EmailFeedback = () => {
  const { disabled, uuid, exampleEmails, setExampleEmails, updateSettings, updateSettingsLoading } = useEmailFeedback();
  return (
    <div className={"w-full"}>
      <p className={"mb-12 text-sm font-medium text-neutral-700"}>
        Our new Email Feedback feature is designed to help you craft emails that reflect your unique communication
        style. By providing feedback on a few sample emails, our AI can learn to simulate your tone, making your emails
        more consistent and personalized.
      </p>
      <div className={"flex w-full flex-col items-center"}>
        {exampleEmails.map(({ email, id }, index) => {
          return (
            <div className={"flex w-full items-center"} key={id}>
              <TextArea
                value={email}
                containerClassName={"w-full"}
                label={`#${index + 1} Example`}
                onChange={(text) => {
                  setExampleEmails((prevState) => {
                    const newEmails = [...prevState];
                    newEmails[index].email = text;
                    return newEmails;
                  });
                }}
                autoFocus={index === exampleEmails.length - 1}
              />
              <Icon
                type={"Trash"}
                onClick={() => {
                  if (exampleEmails.length <= 1) return;
                  setExampleEmails((prevState) => {
                    return prevState.filter((_, i) => {
                      return i !== index;
                    });
                  });
                }}
                className={cn(
                  "ml-4 size-8 cursor-pointer rounded-sm bg-white p-2 hover:bg-neutral-50",
                  exampleEmails.length === 1 ? "bg-neutral-300 text-white hover:bg-neutral-300 hover:text-white" : "",
                )}
              />
            </div>
          );
        })}
        <Button
          text={"Add New"}
          iconLeft={"Add"}
          variant={"secondary"}
          className={"self-center bg-neutral-800"}
          onClick={() => setExampleEmails((prevState) => [...prevState, { id: uuid(), email: "" }])}
        />
      </div>
      <div className={"flex w-full justify-center"}>
        <Button
          text={"Save Changes"}
          variant={"gradient"}
          disabled={disabled}
          className={cn(exampleEmails.length <= 2 ? "absolute bottom-10 lg:inset-x-[45.4%]" : "mt-6 self-center")}
          loading={updateSettingsLoading}
          onClick={() => {
            const feedbackEmailTemplate = exampleEmails.map(({ email }) => email).filter(Boolean);
            updateSettings({
              variables: {
                input: { settings: { feedbackEmailTemplate } },
              },
            })
              .then(() => {
                authStore.updateUser({
                  lgMemberSettingsByUserId: { nodes: [{ feedbackEmailTemplate }] },
                });
                toast.success("Settings updated successfully!");
              })
              .catch(() => toast.error("Failed to update settings"));
          }}
        />
      </div>
    </div>
  );
};
