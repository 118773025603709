import { ComponentType } from "react";

import { useBreakpoints } from "@/lib/hooks";

import { RenderModal } from "@/app/stores/modal.store.tsx";

export const withResponsiveRendering = <T extends object>(
  ModalWeb: ComponentType<T>,
  ModalMobile: ComponentType<T>,
  breakpoint: keyof ReturnType<typeof useBreakpoints> = "isBigTablet",
): RenderModal<T> => {
  return function ResponsiveContainer(props: T) {
    const breakpoints = useBreakpoints();

    return breakpoints[breakpoint] ? <ModalWeb {...props} /> : <ModalMobile {...props} />;
  };
};
