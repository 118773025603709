import { RadioGroups, RadioOption } from "@/app/components";
import { cn } from "@/lib/utils";

export interface AddGeneralProps<T extends string> {
  onSelect: (option: RadioOption<T>) => void;
  options: RadioOption<T>[];
}

export const SelectAction = <T extends string>({
  onSelect,
  options,
}: AddGeneralProps<T>) => {
  return (
    <section className="flex h-full flex-1 flex-col justify-center">
      <div className="mb-4 lg:mb-0 mt-auto w-full">
        <RadioGroups
          options={options}
          onSelected={(option) => option && onSelect(option)}
          className={cn(`flex flex-col lg:flex-row justify-center gap-4`, options.length === 3 ? "justify-center" : "justify-around")}
          optionContainerClassName="lg:w-[181px]"
          optionClassName="flex items-center justify-start px-2 py-2 lg:pb-4.5 lg:pt-12 bg-neutral-50 rounded-lg text-center font-semibold cursor-pointer border hover:shadow-radio hover:bg-white lg:block"
          checkedClassName="text-black bg-white"
          checkIconContainer="mr-2 mt-0 lg:mt-6 lg:mr-auto w-4 h-4"
          checkIconClassName="w-3 h-3"
          iconClassName="h-6 w-6 lg:h-10 lg:w-10 ml-2 mr-auto lg:mx-auto lg:mb-4 text-black"
          labelClassName="text-sm text-black font-semibold"
          withCheck
        />
      </div>
    </section>
  );
};
