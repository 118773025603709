import { Card } from "@/app/components";

export const EmptySection = ({ message }: { message: string }) => {
  return (
    <Card className="group relative flex select-none flex-col items-center gap-y-2">
      <Card.Body className="flex justify-center py-8">
        <p className="text-lg font-medium text-neutral-300">{message}</p>
      </Card.Body>
    </Card>
  );
};
