import { Button } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { RequestSentimentModal } from "@/app/screens/modal";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import modalStore from "@/app/stores/modal.store";

import { FounderFromTeam } from "./founders.types.ts";

const useFounderSentiment = (founder: FounderFromTeam) => {
  const sentimentData = founder?.forms ?? [];
  const firstNonNullForm = sentimentData?.find((form) => form?.responses?.length > 0);

  return {
    sentimentData,
    firstNonNullForm,
    isEmpty: !(sentimentData.length > 0 && firstNonNullForm),
  };
};

const AddSentimentButton = ({ founder }: { founder: FounderFromTeam }) => {
  const { company } = useOpportunityContext();

  const handleClick = () => {
    if (!company || !founder.member) {
      return;
    }

    modalStore.open(RequestSentimentModal, {
      props: {
        company,
        founderId: founder.member.id,
        type: "founderSentiment",
      },
    });
  };

  return (
    <Button className="w-full lg:w-auto" size="sm" onClick={handleClick} text="Add Sentiment" variant="secondary" />
  );
};

const FounderSentimentButton = ({
  sentimentData,
  firstNonNullForm,
}: Pick<ReturnType<typeof useFounderSentiment>, "sentimentData" | "firstNonNullForm">) => {
  const submittedAt = normaliseDate(firstNonNullForm?.responses.at(-1).submitted_at) ?? "";

  const handleClick = () => {
    opportunityModal({
      id: `sentiment_details`,
      platform: window.innerWidth <= 976 ? "mobile" : "web",
      action: "Sentiment",
    });

    opportunitiesStore.setModalState("sentiment_details", {
      form: sentimentData?.find((form) => form?.responses?.length > 0),
    });
  };

  return (
    <Button
      onClick={handleClick}
      iconLeft={"MoveUpRight"}
      text={`Sentiment added on ${submittedAt}`}
      variant="secondary"
      className={"w-full lg:w-auto"}
      size="sm"
    />
  );
};

export const FounderSentiment = ({ founder }: { founder: FounderFromTeam }) => {
  const { sentimentData, firstNonNullForm, isEmpty } = useFounderSentiment(founder);

  return isEmpty ? (
    <AddSentimentButton founder={founder} />
  ) : (
    <FounderSentimentButton sentimentData={sentimentData} firstNonNullForm={firstNonNullForm} />
  );
};
