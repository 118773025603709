import React from "react";

import { motion } from "framer-motion";
import { Controller } from "react-hook-form";

import { Button } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import {
  CompanyDataSchema,
  useCompanyDataForm,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import { SelectedCompanyPreview } from "@/app/screens/add-company/company-data-step/selected-company-preview.tsx";
import {
  CompanySearchAutocomplete,
  DealroomCompanyResult,
} from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";

import { isDealroomCompany } from "../add-company-view.types.tsx";

import { CompanyFoundersFormData } from "./company-founders-form-data.tsx";

export type AddCompanyStepProps = {
  onSubmit: (data: CompanyDataSchema) => void;
  onCancel: () => void;
  data?: CompanyDataSchema;
  loading?: boolean;
};

export const CompanyData = ({ onSubmit, onCancel, data }: AddCompanyStepProps) => {
  const {
    form: { control, setValue, reset, handleSubmit, formState, ...form },
    founders,
  } = useCompanyDataForm(data);

  const selectedDealroomCompany = form.watch("dealroomCompany");

  const selectDealroomCompany = (company?: DealroomCompanyResult | null) => {
    // clear selected company data and reset form
    if (!company) {
      reset({ name: "", websiteUrl: "", founders: [], dealroomCompany: null });
      return;
    }

    setValue(
      "dealroomCompany",
      {
        ...company,
        founders: {
          nodes: company.founders.nodes.map((founder) => founder.member!).filter(Boolean) ?? [],
        },
      },
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleSelectCompanyByName = (data?: DealroomCompanyResult | string | null) => {
    if (typeof data === "string" && selectedDealroomCompany) {
      reset({ name: data, websiteUrl: "", founders: [], dealroomCompany: null });
    } else if (typeof data === "string") {
      setValue("name", data, { shouldValidate: true, shouldDirty: true });
    } else if (data && isDealroomCompany(data)) {
      selectDealroomCompany(data);
    } else {
      selectDealroomCompany(null);
    }
  };

  return (
    <form className="relative flex flex-1 flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      {!selectedDealroomCompany && (
        <motion.div
          initial={{ x: "-100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100%", opacity: 0 }}
          transition={{ duration: 0.2 }}
          key="form-fields"
          className="absolute w-full"
        >
          <h2 className="mb-4 text-sm text-neutral">
            To submit a company, only one field is required. Complete additional fields later if needed.
          </h2>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <FormField
                label="Company name"
                error={fieldState.error?.message}
                styles={{ container: "mb-8 lg:space-y-4" }}
              >
                <CompanySearchAutocomplete
                  value={field.value}
                  selectedCompany={selectedDealroomCompany}
                  onChange={handleSelectCompanyByName}
                />
              </FormField>
            )}
          />

          <Controller
            name="websiteUrl"
            control={control}
            disabled={!!selectedDealroomCompany}
            render={({ field, fieldState }) => (
              <FormField styles={{ container: "mb-8 lg:space-y-4" }} label="Website" error={fieldState.error?.message}>
                <InputV2
                  value={field.value ?? ""}
                  onChange={(e) => field.onChange(e.target.value)}
                  disabled={field.disabled}
                />
              </FormField>
            )}
          />

          <CompanyFoundersFormData control={control} fieldArray={founders} />

          <div className="mt-4 flex w-full flex-col items-center justify-between gap-2 lg:flex-row lg:pb-10">
            <Button
              text={"Continue"}
              type="submit"
              variant={"gradient"}
              disabled={!formState.isValid}
              className="w-full px-10 lg:order-last lg:w-auto"
            />
            <Button
              text={"Cancel"}
              type="button"
              variant="outline"
              onClick={onCancel}
              className="w-full px-10 lg:w-auto"
            />
          </div>
        </motion.div>
      )}
      {!!selectedDealroomCompany && (
        <motion.div
          key="selected-company"
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="absolute w-full space-y-2"
        >
          <SelectedCompanyPreview selectedDealroomCompany={selectedDealroomCompany} />
          <div className="flex justify-between">
            <Button
              text="Clear"
              type="button"
              variant="outline"
              onClick={() => {
                handleSelectCompanyByName(null);
              }}
              className="w-full px-10 lg:w-auto"
            />

            <Button
              text={"Continue"}
              type="submit"
              variant={"gradient"}
              className="w-full px-10 lg:order-last lg:w-auto"
            />
          </div>
        </motion.div>
      )}
    </form>
  );
};
