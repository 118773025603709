export const numberFormat = Intl.NumberFormat("en-GB");
export const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

export function formatDate(dateTime?: string): string {
  if (!dateTime) return "—";

  // HACK: we expect data w/ not timezone here
  // but we know it Zulu so gonna treat it like one
  return dateTimeFormat.format(new Date(`${dateTime}Z`));
}

