import { PropsWithChildren } from "react";

import { flexRender } from "@tanstack/react-table";

import { cn } from "@/lib/utils.ts";

import { getColumnStyles, useTable, useTableDensity } from "@/app/components/table-new/table.hooks.ts";

import { Cell } from "./table-cell.tsx";
import { Row } from "./table-row.tsx";

export const Body = (props: PropsWithChildren<{ className?: string }>) => {
  const tableProps = useTable();
  const { config } = useTableDensity();

  if ("children" in props) {
    return <thead className={props.className}>{props.children}</thead>;
  }

  const { table, options } = tableProps;
  const { className } = props;
  const noResults = table?.getRowModel().rows.length === 0;

  return (
    <tbody className={cn(className, config.body)}>
      {noResults ? (
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-neutral-300">
          No Results Found
        </span>
      ) : (
        table?.getRowModel().rows.map((row, index) => {
          return (
            <Row
              key={row.id}
              className={cn(
                options?.onRowClick && "cursor-pointer",
                options?.elementOptions?.row?.className,
                options?.elementOptions?.row?.selectedIndex === index && options?.elementOptions.row.selectedClassName,
              )}
              onClick={() => {
                options?.onRowClick?.(row.original);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <Cell
                  key={cell.id}
                  className={cn(cell.column.columnDef.meta?.className, cell.column.columnDef.meta?.className)}
                  extraStyles={getColumnStyles(cell)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Cell>
              ))}
            </Row>
          );
        })
      )}
    </tbody>
  );
};
