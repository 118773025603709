import { useNavigate } from "react-router";

import { ImageWithErrorProfile } from "@/app/components";

export function IconMenu({ user }) {
  const navigate = useNavigate();
  return (
    <div className="group flex items-center hover:opacity-80">
      <div className={"absolute -ml-px size-[34px] rounded-md bg-gradient-to-r from-primary to-secondary"} />
      <ImageWithErrorProfile
        onClick={() => {
          navigate("/settings");
        }}
        className="z-10 flex size-8 cursor-pointer items-center justify-center rounded-md text-center text-xl font-bold "
        src={user.avatar}
        alt={user.initials}
      />
    </div>
  );
}
