import { useNavigate } from "react-router";

import { cn } from "@/lib/utils";

import { CompanyImg, Pill, Icon } from "@/app/components";
import { TableCellTotals } from "@/app/components/table-new/components/table-cell-total.tsx";

import { Column } from "./types";

export interface CellWrapperProps {
  className?: string;
  scalarValue?: string | number;
  children?: React.ReactNode;
  id: Column;
  isFooter?: boolean;
  depth?: number;
  footerLabel?: string;
}

export const CellWrapper = ({ scalarValue = "", children, id, className, isFooter }: CellWrapperProps) => {
  if (id === "fundName" || id === "investmentType" || id === "mostRecentRound") {
    return (
      <div className="flex justify-center">
        <Pill label={scalarValue.toString()} pillClassName="" />
      </div>
    );
  }

  if (isFooter) {
    return <TableCellTotals label={"TOTAL"} value={<span className={className}>{children || scalarValue}</span>} />;
  }

  return (
    <div className="space-y-1.5">
      <span className={className}>{children || scalarValue}</span>
    </div>
  );
};

export const ExpandCellWrapper = ({ row }) => {
  return (
    <div className={cn("mr-2 inline-block", row.getCanExpand() ? "visible" : "invisible")}>
      {row.getIsExpanded() ? (
        <Icon type="Sub" className="size-6 text-neutral-500" />
      ) : (
        <Icon type="Add" className="size-6 text-neutral-500" />
      )}
    </div>
  );
};

export const CompanyCellWrapper = ({ company, row }) => {
  const navigate = useNavigate();
  return (
    <button
      className={cn("flex w-full cursor-pointer items-center justify-start overflow-x-hidden text-ellipsis", {
        invisible: row.depth > 0,
      })}
      onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : undefined}
    >
      {row && <ExpandCellWrapper row={row} />}
      <CompanyImg src={company?.srcCompany?.company?.image} className="mr-2" />
      <span
        className="select-none overflow-hidden text-ellipsis text-left text-sm font-semibold hover:underline"
        onClick={() => company?.srcCompany?.company?.id && navigate(`/company/${company?.srcCompany?.company?.id}`)}
      >
        {company.name}
      </span>
    </button>
  );
};

export const getRunwayColors = (value?: string) => {
  switch (value?.trim()) {
    case ">24 months":
    case "24+ months":
    case "> 36 months":
    case "36+ months":
      return {
        bg: "!bg-green-100",
        text: "!text-green-600",
      };
    case "18 - 36 months":
    case "18-24 months":
      return {
        bg: "!bg-green-100",
        text: "!text-green-600",
      };
    case "12-18 months":
      return {
        bg: "!bg-yellow-100",
        text: "!text-yellow-600",
      };
    case "6-12 months":
    case "< 12 months":
      return {
        bg: "!bg-red-100",
        text: "!text-red-400",
      };
    case "<6 months":
      return {
        bg: "!bg-red-100",
        text: "!text-red-400",
      };
    default:
      return {
        bg: "",
        text: "!text-neutral-600",
      };
  }
};

export const RunwayCellWrapper = ({ value }) => {
  const { bg: containerStyle, text: textStyle } = getRunwayColors(value);

  return (
    <div className={cn("rounded-xs px-2.5 py-1.5 text-center", containerStyle)}>
      <span className={cn("whitespace-nowrap text-sm font-medium", textStyle)}>{value}</span>
    </div>
  );
};
