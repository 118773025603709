import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

export type ColumnStyles = {
  container?: string;
  labelStyle?: string;
  content?: string;
};

export type DetailsColumnProps = {
  label?: string;
  styles?: ColumnStyles;
};

export const DetailsColumn = ({ label, children, styles = {} }: PropsWithChildren<DetailsColumnProps>) => {
  const { container, content, labelStyle } = styles;
  return (
    <div className={cn("w-auto text-sm", container)}>
      {label && <p className={cn("mb-1 font-normal text-neutral-400", labelStyle)}>{label}</p>}
      <div className={cn("flex items-center text-neutral-700", content)}>{children}</div>
    </div>
  );
};
