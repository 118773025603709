import React, { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import z from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";
import { cn, getInitials } from "@/lib/utils.ts";

import { Button, Icon, ImageWithErrorProfile } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import {
  FounderSearchAutocomplete,
  FounderSearchResult,
} from "@/app/screens/opportunities/components/founder-search-autocomplete.tsx";
import { FounderData } from "@/app/screens/opportunities/opportunity/components/founders/founders.types.ts";

const founderSchema = z.object({
  name: z.string().min(1, "Name is required"),
  url: z.string().regex(linkedinProfileUrlRegex, `A valid LinkedIn url is required`),
});

export type FounderFormData = z.infer<typeof founderSchema>;

const useFounderEditForm = (defaultValues?: FounderData) => {
  return useForm<FounderFormData>({
    resolver: zodResolver(founderSchema),
    mode: "onSubmit",
    defaultValues: {
      name: defaultValues?.name ?? "",
      url: defaultValues?.linkedinUrl ?? "",
    },
  });
};

const getImageUrl = (url: string): string => {
  return url.includes("http") ? url : "https://" + url;
};

const FounderPreview = ({
  founder,
  onClear,
  errors,
}: {
  founder: FounderSearchResult;
  onClear: () => void;
  errors?: string;
}) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <ImageWithErrorProfile
          className={cn("size-16 shrink-0 rounded-sm bg-neutral-300")}
          labelClassName={"text-white text-3xl"}
          src={founder.image ? getImageUrl(founder.image) : null}
          alt={founder.name ? getInitials(founder.name) : "N/A"}
        />
        <span className="text-lg font-semibold">{founder.name}</span>
        {founder.linkedinUrl && (
          <a
            className={"flex cursor-pointer select-none items-center text-sm font-semibold text-linkedin underline"}
            href={founder.linkedinUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Icon type={"Linkedin"} className="size-4" />
            <span className="ml-0.5">{(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}</span>
          </a>
        )}
        <Button onClick={onClear} type="button" variant="secondary" className="ml-3">
          Clear
        </Button>
      </div>
      {!!errors && <div className="rounded-xs bg-red-300 p-4 text-sm mt-2">{errors}</div>}
    </div>
  );
};

export const FounderEdit = ({
  founder,
  onClose,
  onSubmit,
}: {
  founder?: FounderData;
  onSubmit: (data: FounderFormData, selectedFounder: FounderSearchResult) => void;
  onClose: () => void;
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useFounderEditForm(founder);
  const [selectedFounder, setSelectedFounder] = useState<FounderSearchResult | null>(null);

  const handleCancel = () => {
    reset();
    onClose();
  };

  const handleClearSelected = () => {
    setSelectedFounder(null);
    reset();
  };

  const errorMessage = Object.values(errors)
    .map((error) => error.message)
    .filter(Boolean)
    .join("; ");

  return (
    <form className="@container" onSubmit={handleSubmit((data) => selectedFounder && onSubmit(data, selectedFounder))}>
      {!!selectedFounder && (
        <FounderPreview founder={selectedFounder} onClear={handleClearSelected} errors={errorMessage} />
      )}
      {!selectedFounder && (
        <div className="grid w-full gap-4 @lg:grid-cols-2">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormField label="Founder name" labelVariant="tertiary">
                <FounderSearchAutocomplete
                  value={field.value}
                  selectedFounder={selectedFounder}
                  onChange={(founder) => {
                    if (typeof founder === "object") {
                      setSelectedFounder(founder);
                    } else {
                      // clear when custom name is used
                      setSelectedFounder(null);
                    }

                    const name = typeof founder === "object" ? founder?.name : founder;
                    const linkedinUrl = typeof founder === "object" ? founder?.linkedinUrl ?? "" : "";
                    field.onChange(name ?? "");
                    setValue("url", linkedinUrl ?? "");
                  }}
                />
              </FormField>
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field, fieldState }) => (
              <FormField label="Linkedin URL" labelVariant={"tertiary"} error={fieldState.error?.message}>
                <InputV2 placeholder={"Type"} {...field} />
              </FormField>
            )}
          />
        </div>
      )}
      <div className="mt-4 flex justify-end gap-4">
        <Button className={"w-full min-w-40 lg:w-auto"} variant="outline" size={"md"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button className={"w-full min-w-40 lg:w-auto"} size={"md"} variant="gradient">
          Save
        </Button>
      </div>
    </form>
  );
};
