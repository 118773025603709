import React, { memo, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { Button, Icon, Toast } from "@/app/components";
import { EmptySection, SectionTitle } from "@/app/screens/opportunities/opportunity/components";
import {
  CompanySignalSchema,
  NetworkSignalEdit,
} from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal-edit.tsx";
import { NetworkSignalData } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal.types.ts";
import { Section } from "@/app/screens/opportunities/opportunity/components/section.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import {
  NetworkSignalsByCompanyDocument,
  useCreateNetworkSignalMutation,
  useDeleteNetworkSignalMutation,
  useNetworkSignalsByCompanyQuery,
  useUpdateNetworkSignalMutation,
} from "@/app/service/network-signal.gql.ts";

import { NetworkSignal } from "./network-signal.tsx";

const useNetworkSignals = () => {
  const { company } = useOpportunityContext();

  const { data } = useNetworkSignalsByCompanyQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company,
  });

  const signals: NetworkSignalData[] =
    data?.networkSignals?.nodes.map((signal) => ({
      id: signal.id,
      sourceName: signal.sourceName,
      sourceType: signal.sourceType,
      note: signal.note,
      // this should match data from db since we store it as jsonb
      attributes: {
        canConnect: signal.attributes.can_intro,
        isActivelyFundraising: signal.attributes.is_actively_fundraising,
        isHighPriority: signal.attributes.high_priority,
        isDeepTech: signal.attributes.is_deep_tech,
      },
      createdBy: signal.lgMemberByCreatedBy!,
      createdUtc: signal.createdUtc,
      lastUpdatedUtc: signal.lastUpdatedUtc,
    })) ?? [];

  return {
    signals,
  };
};

export const NetworkSignals = memo(function NetworkSignals() {
  const { signals } = useNetworkSignals();
  const { company } = useOpportunityContext();
  const [createNetworkSignal, { loading: isBeingCreated }] = useCreateNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });
  const [updateNetworkSignal, { loading: isBeingUpdated }] = useUpdateNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });
  const [deleteNetworkSignal, { loading: isBeingDeleted }] = useDeleteNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });

  const [mode, setMode] = useState<"view" | "edit" | "create">("view");
  const [signal, setSignal] = useState<NetworkSignalData | null>(null);

  const handleEditMode = (signal: NetworkSignalData) => {
    setMode("edit");
    setSignal(signal);
  };

  const handleCreate = async (data: CompanySignalSchema) => {
    if (!company) {
      return;
    }

    await createNetworkSignal({
      variables: {
        signal: {
          companyId: company.id,
          sourceType: data.sourceType,
          sourceName: data.sourceName,
          attributes: {
            canIntro: data.introAvailable,
            highPriority: data.highPriority,
            isActivelyFundraising: data.activelyRaising,
            isDeepTech: data.isDeepTech,
          },
          note: data.note,
        },
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle="Network signal has been created"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));

    setMode("view");
  };

  const handleEdit = async (data: CompanySignalSchema) => {
    if (!signal) {
      return;
    }

    await updateNetworkSignal({
      variables: {
        signalId: signal.id,
        data: {
          sourceType: data.sourceType,
          sourceName: data.sourceName,
          attributes: {
            canIntro: data.introAvailable,
            highPriority: data.highPriority,
            isActivelyFundraising: data.activelyRaising,
            isDeepTech: data.isDeepTech,
          },
          note: data.note,
        },
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle="Network signal has been updated"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));
    setMode("view");
  };

  const handleRemove = async (signal: NetworkSignalData) => {
    await deleteNetworkSignal({
      variables: {
        signalId: signal.id,
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle="Network signal has been deleted"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  return (
    <Section id="signals">
      <SectionTitle
        title="Network signals"
        actions={
          mode === "view" && (
            <Button
              size="sm"
              variant="secondary"
              text="Add Network Signal"
              onClick={() => {
                setMode("create");
              }}
            />
          )
        }
      />
      {mode === "create" && (
        <NetworkSignalEdit onSubmit={handleCreate} onClose={() => setMode("view")} loading={isBeingCreated} />
      )}
      {mode === "edit" && !!signal && (
        <NetworkSignalEdit
          signal={signal}
          onSubmit={handleEdit}
          onClose={() => setMode("view")}
          loading={isBeingUpdated}
        />
      )}
      <ul className={cn("space-y-4", ["edit", "create"].includes(mode) && "hidden")}>
        <AnimatePresence>
          {!signals.length && <EmptySection text={"No signals"} />}
          {signals.map((signal) => (
            <motion.li
              key={signal.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <NetworkSignal key={signal.id} signal={signal} onEdit={handleEditMode} onRemove={handleRemove} />
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </Section>
  );
});
