import { Fragment, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { IconToInput } from "@/app/components/input";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { AddAdditional, CompanyMetrics } from "@/app/screens/metrics-extraction/sections/metrics-controller";

type MetricsDashboardType = "Company Metrics" | "Add Additional";

export const MetricsDashboard = ({ email }) => {
  if (email) return <MetricsWithEmail containerClassName={"max-h-[85%] min-w-[30dvw] h-full"} />;
  return <MetricsWithoutEmail containerClassName={"gap-x-4 w-full max-h-[85%] h-full"} />;
};

const MetricsWithEmail = ({ containerClassName }) => {
  const [selectedBoard, setSelectedBoard] = useState<MetricsDashboardType>("Company Metrics");

  return (
    <div className={cn("flex min-h-full w-[33dvw] flex-col rounded-md bg-white", containerClassName)}>
      <div className={"flex h-[7%] w-full items-center  justify-between rounded-t-md border-b border-gray-200 px-6"}>
        <div className={"flex w-3/4 items-center space-x-8"}>
          {["Company Metrics", "Add Additional"].map((tab: MetricsDashboardType) => (
            <Fragment key={tab}>
              <span className={cn("text-sm font-semibold select-none cursor-pointer 2xl:text-base", selectedBoard === tab ? "text-black" : "text-gray")}
                onClick={() => setSelectedBoard(tab)}>{tab}</span>
            </Fragment>
          ))}
        </div>
        <IconToInput
          iconType={"Search"}
          containerInputClassname={"w-1/2"}
          inputClassName={"py-2"}
          iconTypeOnSelected={"SearchPrimary"}
          iconClassName={"text-gray-900 size-4 active:text-gray-700"}
          onStartFn={(showInput) => {
            metricsStore.setState({ expandSearch: showInput });
          }}
          inputFn={(search) => {
            metricsStore.setState({ search });
          }}
        />
      </div>
      <div className={"flex h-[93%] w-full py-4"}>
        {selectedBoard === "Company Metrics" && <CompanyMetrics />}
        {selectedBoard === "Add Additional" && <AddAdditional containerClassName={"px-6"} withEmail={true} />}
      </div>
    </div>
  );
};

const MetricsWithoutEmail = ({ containerClassName }) => {
  return (
    <div className={cn("flex", containerClassName)}>
      <div className={"flex w-full flex-col rounded-md bg-white"}>
        <span className={cn("my-4 border-b px-6 pb-4 text-sm font-semibold text-black 2xl:text-base")}>
          Company metrics
        </span>
        <CompanyMetrics containerClassName={"h-[90%]"} />
      </div>
      <div className={"flex w-full flex-col rounded-md bg-white"}>
        <span className={cn("my-4 border-b px-6 pb-4 text-sm font-semibold text-black 2xl:text-base")}>Add new</span>
        <AddAdditional containerClassName={"px-6 h-[90%]"} withEmail={false} />
      </div>
    </div>
  );
};
