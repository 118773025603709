import { Fragment, useLayoutEffect, useMemo, useState } from "react";

import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { observer } from "mobx-react";
import { usePopper } from "react-popper";

import { cn } from "@/lib/utils";

import { Button, Checkbox, Icon, MultiRange } from "@/app/components";
import { filterNoSort } from "@/app/misc/filters.ts";
import type { FilterRouteType } from "@/app/screens/opportunities/components/sub-header.tsx";
import filterStore, { singleSelections } from "@/app/screens/opportunities/filter.store";
import { default as opportunityFilterStore, filterGroups } from "@/app/screens/opportunities/filter.store.ts";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store";
import { defaultFilterRangeOptions } from "@/app/stores/filter.store.tsx";

type FiltersProps = {
  containerClassName?: string;
  filterRoute: FilterRouteType;
  setFilterRoute: React.Dispatch<React.SetStateAction<FilterRouteType>>;
};
export const Filters = observer(function Filters({ containerClassName, filterRoute, setFilterRoute }: FiltersProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  return (
    <div className={cn("flex flex-row items-center", containerClassName)}>
      <Popover className="relative">
        {() => (
          <>
            {filterRoute.open && (
              <div
                className="fixed left-0 top-0 h-screen w-full bg-black opacity-0"
                onClick={() =>
                  setFilterRoute((prevState) => ({
                    ...filterRoute,
                    open: !prevState.open,
                  }))
                }
              />
            )}

            <PopoverButton
              onClick={() => {
                setFilterRoute((prevState) => ({
                  ...filterRoute,
                  type: "dashboard",
                  open: !prevState.open,
                }));
              }}
              ref={setReferenceElement}
              className={cn("flex justify-end  outline-none transition-all duration-200 ease-in-out")}
            >
              <div
                className={cn(
                  "flex items-center rounded-xs bg-transparent px-3 py-2 text-xs text-neutral-900 hover:bg-neutral-300 active:bg-neutral-400",
                  filterRoute.open && "bg-neutral-300",
                )}
              >
                <FilterCounter />
                <div className={"group flex items-center"}>
                  <span
                    className={
                      "mr-3 select-none text-sm font-semibold group-hover:text-neutral-800 lg:text-xs 2xl:text-sm"
                    }
                  >
                    Filters
                  </span>
                  <Icon type={"Filter"} className={"text-gray-900 group-hover:text-neutral-800 lg:size-3 2xl:size-4"} />
                </div>
              </div>
            </PopoverButton>

            <Transition
              as={Fragment}
              show={filterRoute.open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <PopoverPanel
                static
                className="absolute z-10 mt-4 min-w-[360px] rounded-md shadow-xs"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <div className="relative flex flex-col rounded-md bg-white py-4">
                  <Icon
                    type={"Triangle"}
                    width={15}
                    color={"white"}
                    fill={"white"}
                    height={15}
                    className={cn("fixed -top-3 self-center")}
                  />
                  {filterRoute.type === "dashboard" && <FilterComponent setFilterRoute={setFilterRoute} />}
                  {filterRoute.type === "list" && (
                    <FilterList setFilterRoute={setFilterRoute} filterRoute={filterRoute} />
                  )}
                  {filterRoute.type === "range" && (
                    <FilterRange setFilterRoute={setFilterRoute} filterRoute={filterRoute} />
                  )}
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
});

const TagFilter = ({ label, value }) => (
  <div className={"my-2.5 flex w-full cursor-pointer select-none items-center justify-between"}>
    <div
      className={"flex items-center"}
      onClick={() => {
        opportunityFilterStore.changeChoice({
          category: "tag",
          label,
          value: value == null ? true : null,
        });
      }}
    >
      <Icon
        type={label}
        width={20}
        height={20}
        className={cn("mr-2 size-5", value != null ? "text-gray-900" : "text-neutral-400")}
      />
      <span className={"text-sm font-medium hover:text-neutral-700"}>{label}</span>
    </div>
    {value !== null && (
      <div className={"flex gap-2 rounded-xs bg-neutral-100"}>
        <span
          onClick={() => {
            opportunityFilterStore.changeChoice({
              category: "tag",
              label,
              value: true,
            });
          }}
          className={cn(
            "cursor-pointer select-none p-1.5 text-xs font-semibold transition-all ease-in-out",
            value === true ? "text-black" : "text-neutral-400",
          )}
        >
          Include
        </span>
        <span
          onClick={() => {
            opportunityFilterStore.changeChoice({
              category: "tag",
              label,
              value: false,
            });
          }}
          className={cn(
            "cursor-pointer select-none p-1.5 text-xs font-semibold transition-all ease-in-out",
            value === false ? "text-black" : "text-neutral-400",
          )}
        >
          Exclude
        </span>
      </div>
    )}
  </div>
);
const MultiSelectFilter = ({ label, value, setFilterRoute }) => (
  <div
    className={"my-2.5 flex w-full cursor-pointer select-none items-center justify-between"}
    onClick={() => {
      setFilterRoute((prevState) => ({
        ...prevState,
        type: "list",
        route: label,
        category: "multiselect",
      }));
    }}
  >
    <div className={"flex items-center"}>
      <Icon
        type={label}
        width={20}
        height={20}
        className={cn("mr-2 size-5", value.length ? "text-gray-900" : "text-neutral-400")}
      />
      <span className={"text-sm font-medium hover:text-neutral-700"}>{label}</span>
    </div>
    {Boolean(value.length) && (
      <div className={"mr-2 flex size-4 items-center justify-center rounded-full bg-primary"}>
        <span className={"text-xs font-semibold text-white"}>{String(value.length)}</span>
      </div>
    )}
  </div>
);
const RangeFilter = ({ label, value, setFilterRoute }) => (
  <div
    className={"my-2.5 flex w-full cursor-pointer select-none items-center justify-between"}
    onClick={() => {
      setFilterRoute((prevState) => ({
        ...prevState,
        type: "range",
        route: label,
        category: "range",
      }));
    }}
  >
    <div className={"flex items-center"}>
      <Icon
        type={label}
        width={20}
        height={20}
        className={cn("mr-2 size-5", value ? "text-gray-900" : "text-neutral-400")}
      />
      <span className={"hover:text-neutral-700 text-sm font-medium"}>{label}</span>
    </div>
    {Boolean(value && value?.[0] !== 0) && (
      <Icon type={"FillCheck"} width={16} height={16} className={"mr-1 text-primary"} />
    )}
  </div>
);

const compMap = {
  tag: TagFilter,
  multiselect: MultiSelectFilter,
  range: RangeFilter,
};

const FilterComponent = observer(({ setFilterRoute }) => {
  return (
    <div className={"no-scrollbar flex max-h-[70dvh] flex-col space-y-2 overflow-auto px-4 pb-4"}>
      {filterGroups.map(({ name: filterName }) => {
        const items = opportunityFilterStore.currentFilters.filter(({ group }) => group === filterName);
        if (!items.length) return null;

        return (
          <div key={filterName} className={"flex flex-col"}>
            <span className={"my-3 select-none text-xs font-semibold text-neutral-400 lg:text-xs 2xl:text-sm"}>
              {filterName}
            </span>
            <div className={"flex flex-wrap gap-y-2"}>
              {items.map(({ name: label, type: category }) => {
                const value = opportunityFilterStore.getChoice({
                  category,
                  label,
                });
                const Component: any = compMap[category];

                return (
                  <Component key={`${label}_${category}`} value={value} label={label} setFilterRoute={setFilterRoute} />
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="absolute bottom-2 right-0 h-10 w-full rounded-b-lg bg-gradient-to-t from-white to-transparent" />
    </div>
  );
});

const FilterList = observer(({ setFilterRoute, filterRoute }) => {
  const [query, setQuery] = useState("");
  const list = filterStore.getChoice({
    category: filterRoute.category,
    label: filterRoute.route,
  });
  const singleSelect = singleSelections.includes(filterRoute.route);
  const dataSource = opportunityFilterStore.multiSelectData[filterRoute.route];

  let filteredValues: Array<OptionsType> =
    query === ""
      ? dataSource
      : dataSource.filter((person: OptionsType) => {
          return person.label?.toLowerCase()?.includes(query.toLowerCase());
        });
  if (!filterNoSort.includes(filterRoute.route) && !singleSelect) {
    filteredValues = filteredValues
      ?.sort((a, b) => (a.label > b.label ? 1 : -1))
      .sort((a) => (list?.includes(a.label) ? -1 : 0));
  }

  return (
    <div className={"px-4"}>
      <div
        className={"my-3 flex cursor-pointer items-center"}
        onClick={() => {
          setFilterRoute((prevState) => ({
            ...prevState,
            type: "dashboard",
            route: null,
          }));
        }}
      >
        <Icon type={"Chevron Left"} width={20} height={20} className={"mr-2 text-neutral"} />
        <span className={"text-lg font-semibold hover:text-neutral-700 lg:text-xxs 2xl:text-xs"}>
          {filterRoute.route}
        </span>
      </div>
      {dataSource?.length > 9 ? (
        <input
          autoFocus={true}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={`Search ${filterRoute.route}`}
          className={"my-2 w-full rounded-sm border border-neutral-300 px-3 py-2"}
        />
      ) : (
        <div className={"h-2 py-1"} />
      )}
      <div
        className={cn(
          "no-scrollbar mt-3 h-fit max-h-[55dvh] space-y-3 overflow-auto px-1 pb-4",
          dataSource?.length > 9 ? "mt-5" : "",
        )}
      >
        {filteredValues?.map((e) => (
          <div
            key={e.id}
            className={"flex w-full cursor-pointer items-center justify-between rounded-sm p-2 hover:bg-neutral-100"}
            onClick={() => {
              let data;
              if (!singleSelect) {
                data = list?.includes(e.label) ? list.filter((i) => i !== e.label) : [...list, e.label];
              } else {
                data = [e.label];
              }
              opportunityFilterStore.changeAllChoice({
                category: filterRoute.category,
                label: filterRoute.route,
                action: "select",
                data,
              });
            }}
          >
            <span className={"select-none text-sm font-medium"}>{e.label}</span>
            {list?.includes(e.label) && <Icon type={"FillCheck"} width={16} height={16} className={"text-primary"} />}
          </div>
        ))}
        {dataSource?.length > 9 && (
          <div className="absolute bottom-2 right-0 h-12 w-full rounded-b-lg bg-gradient-to-t from-white to-transparent" />
        )}
      </div>
    </div>
  );
});

const filter_input_classname = "rounded-xs border border-neutral-200 w-1/2 px-0.5 py-2 text-sm text-center";

const toNumber = (value) => {
  if (typeof value === "number") return value;
  if (value?.charAt(value.length - 1) === ".") value = `${value}0`;
  return Number(value);
};

export const FilterRange = observer(({ setFilterRoute, filterRoute }) => {
  const label = filterRoute.route;
  const {
    min,
    max,
    step,
    denomination = "million",
    currency = "$",
  } = useMemo(() => defaultFilterRangeOptions[label], [label]);

  const [{ minValue, maxValue }, setValue] = useState({
    minValue: min,
    maxValue: max,
  });
  const [showCustom, setShowCustom] = useState(false);

  const applyFilter = () => {
    opportunityFilterStore.changeAllChoice({
      category: "range",
      label,
      action: "select",
      data: [minValue, maxValue],
    });
    setFilterRoute((prevState) => ({
      ...prevState,
      type: "dashboard",
      route: null,
    }));
  };

  useLayoutEffect(() => {
    const rangeValues = opportunityFilterStore.getChoice({
      category: "range",
      label: filterRoute.route,
    });
    if (rangeValues) {
      setValue({
        minValue: rangeValues[0],
        maxValue: rangeValues[1],
      });
    }
  }, []);

  const displayMinValue = toNumber(minValue);
  const displayMaxValue = toNumber(maxValue);

  return (
    <div className={"px-4"}>
      <div
        className={"my-3 flex cursor-pointer items-center"}
        onClick={() => {
          setFilterRoute((prevState) => ({
            ...prevState,
            type: "dashboard",
            route: null,
          }));
        }}
      >
        <Icon type={"Chevron Left"} width={20} height={20} className={"mr-2 text-neutral"} />
        <span className={"text-lg font-semibold hover:text-neutral-700 lg:text-xxs 2xl:text-xs"}>
          {filterRoute.route}
        </span>
      </div>
      <div className={"flex w-full flex-col justify-center"}>
        <div className={"flex self-end"}>
          <span
            className={cn(
              "rounded-sm bg-neutral-100 p-2 text-xs font-semibold text-neutral-600 lg:text-xxs 2xl:text-xs",
            )}
          >{`${currency}${displayMinValue} - ${currency}${displayMaxValue} ${denomination}`}</span>
        </div>
        <MultiRange
          containerClassName={"w-full"}
          disabled={showCustom}
          min={min}
          max={max}
          step={step}
          minValue={displayMinValue}
          maxValue={displayMaxValue}
          handleInput={setValue}
        />
        <Checkbox
          checked={showCustom}
          onClick={() => setShowCustom((prevState) => !prevState)}
          label={"Add Custom Amount"}
          containerClassName={"-ml-2.5 my-2"}
          labelClassName={"text-black font-medium"}
        />

        {showCustom && (
          <div className={"flex flex-col justify-center gap-x-2"}>
            <span className={"text-xs font-semibold text-neutral-400"}>Between</span>
            <div className={"flex w-full gap-x-3"}>
              <div className={cn("flex items-center", filter_input_classname)}>
                <span className={"px-1 text-xs font-semibold text-neutral-400"}>{currency}</span>
                <input
                  className={"w-full bg-transparent"}
                  pattern={"^[0-9]*.?[0-9]*&"}
                  onChange={({ target: { value } }) => {
                    const x = /^[0-9]*\.?[0-9]*$/;
                    if (x.test(value)) {
                      setValue((prevState) => ({
                        maxValue: toNumber(prevState.maxValue) < toNumber(value) ? value : prevState.maxValue,
                        minValue: value,
                      }));
                    }
                  }}
                  value={minValue}
                />
              </div>

              <div className={cn("flex items-center", filter_input_classname)}>
                <span className={"px-1 text-xs text-neutral-400"}>{currency}</span>
                <input
                  className={"w-full bg-transparent"}
                  value={maxValue}
                  onChange={({ target: { value } }) => {
                    const x = /^[0-9]*\.?[0-9]*$/;
                    if (x.test(value)) {
                      setValue((prevState) => ({
                        minValue: toNumber(prevState.minValue) > toNumber(value) ? value : prevState.minValue,
                        maxValue: value,
                      }));
                    }
                  }}
                />
                <span className={"px-2 text-xs font-semibold text-neutral-400"}>{denomination}</span>
              </div>
            </div>
          </div>
        )}
        <Button
          disabled={minValue === 0 && maxValue === 99}
          onClick={applyFilter}
          text={"Apply"}
          className={"mt-3 w-full"}
          variant={"primary"}
        />
      </div>
    </div>
  );
});

const FilterCounter = observer(() => {
  return (
    <>
      <div
        className={cn(
          "mr-2 flex w-fit items-center justify-center rounded-full bg-primary lg:h-4 lg:min-w-4 2xl:h-4.5 2xl:min-w-4.5",
          filterStore.filtersActive ? "" : "opacity-0",
        )}
      >
        <span className={"select-none text-sm font-medium text-white lg:text-xxs 2xl:text-xs"}>
          {String(filterStore.filtersActive)}
        </span>
      </div>
    </>
  );
});
