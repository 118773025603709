import { useMemo } from "react";

import { getFullName } from "@/lib/utils.ts";

import { ImageWithErrorFallback } from "@/app/components";
import { UserImage } from "@/app/screens/dashboard/components/user-image.tsx";
import { Badges, SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components";
import { useSocialMediaIcons } from "@/app/screens/opportunities/opportunity/use-opportunity.tsx";
import { LGMember } from "@/app/stores/members.store.tsx";

import { useOpportunityContext } from "../../opportunity-context.tsx";
import { Assignees } from "../assign-users";
import { CompanyFlagToggle } from "../toggle-flags";

export const CompanyDetailsViewMode = () => {
  const { image, portCo, company, badges, tagline, trelloColumn, name = "Stealth Company" } = useOpportunityContext();

  const socialMediaIcons = useSocialMediaIcons(company);

  const assignees = useMemo(
    () =>
      company?.assignees
        ? company.assignees?.nodes?.reduce<Array<LGMember & { assigneeId: string }>>(
            (accumulator, { member, id }) => (member ? [...accumulator, { assigneeId: id, ...member }] : accumulator),
            [],
          )
        : [],
    [company?.assignees],
  );

  return (
    <>
      <div className={"flex items-center space-x-3 lg:space-x-6"}>
        <ImageWithErrorFallback
          src={image}
          alt={name}
          iconClassName={"rounded-sm p-2 lg:p-5 bg-white"}
          className="flex size-20 rounded-md border border-neutral-300 lg:size-24 lg:rounded-sm"
        />
        <div className={"flex flex-1 flex-col justify-center space-y-1 lg:space-y-2"}>
          <div className="flex items-center justify-between gap-4">
            <div className="flex h-full items-center space-x-3">
              <span
                className={
                  "line-clamp-2 h-full shrink overflow-hidden text-ellipsis text-2xl leading-8 font-semibold leading-5 lg:text-3xl lg:leading-9"
                }
              >
                {name}
              </span>
              <SocialMediaIcons
                icons={socialMediaIcons}
                iconClassName={"w-4 h-4 lg:w-6 lg:h-6 lg:p-0.5 text-neutral hover:text-[#0072b1] cursor-pointer"}
                containerClassName={"space-x-3 lg:space-x-5 lg:mt-1"}
              />
            </div>
          </div>
          <div className={"flex"}>
            <span className="whitespace-nowrap text-sm font-medium text-neutral-700 lg:text-base">
              {portCo ? "Portfolio Company" : "Company"}
            </span>
            <span className={"mx-2 text-sm font-semibold lg:text-base"}>•</span>
            <span className="truncate text-sm font-medium text-neutral-700 lg:text-base">{trelloColumn}</span>
          </div>
          {company?.creator && (
            <div className="flex cursor-default items-center text-sm font-medium text-neutral-700">
              <span className="mr-2">Created By:</span>
              <UserImage user={company.creator} />
              <span className="ml-1">{getFullName(company?.creator?.firstName, company?.creator?.lastName)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        {tagline && (
          <div className="mb-4">
            <span className="text-lg font-medium text-neutral-900">Overview</span>
            <p className={"text-sm font-semibold text-neutral-700 lg:text-base"}>{tagline}</p>
          </div>
        )}
        <div className="flex flex-col md:flex-row md:items-start md:gap-8">
          {company && (
            <CompanyFlagToggle
              company={company}
              label={company.isOpCompany ? "Raising in <6 Months?" : "Share with Network?"}
              flag={company.isOpCompany ? "6_month_runway" : "sharing_allowed"}
              styles={{ toggleContainer: "flex" }}
            />
          )}
          <Assignees assignees={assignees} companyId={company?.id} />
        </div>
      </div>
      <Badges badges={badges} />
    </>
  );
};
