import React from "react";

import { Link } from "react-router-dom";

import { getInitials } from "@/lib/utils.ts";

import { Card, CompanyImg, Icon, ImageWithErrorProfile } from "@/app/components";
import { CompanyDataSchema } from "@/app/screens/add-company/company-data-step/company-data-form.ts";

type SelectedDealroomCompanyData = NonNullable<CompanyDataSchema["dealroomCompany"]>;

export const SelectedCompanyPreview = ({
  selectedDealroomCompany,
}: {
  selectedDealroomCompany: SelectedDealroomCompanyData;
}) => {
  return (
    <Card className="flex gap-2">
      <CompanyImg src={selectedDealroomCompany.image} className="size-12 shrink-0" />
      <div className="flex w-full flex-col">
        <div className="flex items-center gap-1">
          <span>{selectedDealroomCompany.name}</span>
          {selectedDealroomCompany.websiteUrl && (
            <Link to={selectedDealroomCompany.websiteUrl} target="_blank" className="text-sm">
              <Icon type="Continent" className="size-4" />
            </Link>
          )}
        </div>
        <span className="text-sm text-gray-700">{selectedDealroomCompany.tagline}</span>

        {!!selectedDealroomCompany.founders?.nodes?.length && (
          <div className="mt-5">
            <span className="font-semibold">Founders</span>
            <div className="max-h-[300px] divide-y overflow-y-auto">
              {selectedDealroomCompany.founders?.nodes.map((founder) => (
                <div className="grid grid-cols-2 gap-2 py-2" key={`${founder.name}-${founder.linkedinUrl}`}>
                  <div className="flex items-center gap-2">
                    <ImageWithErrorProfile
                      labelClassName={"text-gray-800 text-lg overflow-hidden text-nowrap"}
                      src={founder.image ? "https://" + founder.image : null}
                      alt={founder.name ? getInitials(founder.name) : "N/A"}
                      className="size-8 rounded-sm bg-gray-200"
                    />
                    <span className="overflow-hidden text-ellipsis text-nowrap text-sm font-semibold">{founder.name}</span>
                  </div>
                  {founder?.linkedinUrl && (
                    <div className={"flex cursor-pointer select-none items-center"}>
                      <Icon type={"Linkedin"} className="size-4 shrink-0 text-linkedin" />
                      <Link
                        to={founder.linkedinUrl}
                        target="_blank"
                        className="overflow-hidden text-ellipsis text-nowrap text-sm font-semibold text-blue-900 underline"
                      >
                        {(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
