import { action, computed, makeObservable, observable } from "mobx";
import { hydrateStore, isHydrated, makePersistable } from "mobx-persist-store";
import posthog from "posthog-js";

import { UserRoleType } from "@/gql/graphql.ts";

export type User =
  | {
      __typename?: "LgMember";
      id: string;
      email: string;
      initials?: string;
      firstName: string;
      isInvestor: boolean;
      lastName: string;
      avatar?: string | null;
      lgMemberSettingsByUserId: {
        __typename?: "LgMemberSettingsConnection";
        nodes: Array<{ __typename?: "LgMemberSetting"; feedbackEmailTemplate?: any | null }>;
      };
    }
  | null
  | undefined;

class AuthStore {
  public user: Partial<NonNullable<User>> | null = null;
  public userRoles: UserRoleType[] | null = null;
  public accessToken?: string;
  public expiresAt?: number;
  public refreshToken?: string;

  public isHydrated = false;

  constructor() {
    makeObservable(this, {
      user: observable,
      accessToken: observable,
      expiresAt: observable,
      refreshToken: observable,
      isHydrated: observable,
      userRoles: observable,
      logout: action,
      login: action,
      updateUser: action,
      userId: computed,
      isSessionExpired: computed,
    });
    makePersistable(
      this,
      {
        stringify: true,
        debugMode: import.meta.env.VITE_MOBX_DEBUG === "true",
        name: "auth",
        storage: window.localStorage,
        properties: ["user", "refreshToken", "accessToken", "expiresAt"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration();
  }

  logout() {
    this.user = null;
    this.userRoles = null;
    this.refreshToken = undefined;
    this.accessToken = undefined;
    this.expiresAt = undefined;
    posthog?.reset();
  }

  updateUser = (user?: Partial<User>) => {
    if (!user) return;
    this.user = { ...this.user, ...user };
  };

  login(user: User) {
    this.user = user ?? null;
  }

  waitForHydration = async () => {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
  };

  get userId() {
    return this.user?.id || "";
  }

  get isSessionExpired(): boolean {
    return this.expiresAt ? this.expiresAt >= Date.now() : true;
  }
}

export const authStore = new AuthStore();
