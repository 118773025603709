import { CompanyCard } from "../components/company-card.tsx";
import { DashboardSection } from "../components/dashboard-section.tsx";
import { dashboardSections } from "../dashboard.config.ts";
import { useReviewingCompanies } from "../hooks/use-reviewing-companies.ts";

export const ReviewingSection = () => {
  const { items, totalCount, pageInfo, loading, loadingLess, loadingMore, loadMore, loadLess } =
    useReviewingCompanies();

  return (
    <DashboardSection
      id={dashboardSections.assigned.id}
      title={dashboardSections.assigned.label}
      count={totalCount}
      loading={loading}
      emptyPlaceholder={{ enabled: totalCount === 0 && !loading, message: "No companies in review" }}
      loadMoreBtn={{ onLoadMore: loadMore, enabled: !!pageInfo?.hasNextPage, loading: loadingMore }}
      loadLessBtn={{ onLoadLess: loadLess, enabled: pageInfo?.hasPreviousPage, loading: loadingLess }}
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3">
        {items.map((item) => (
          <CompanyCard
            key={item.id}
            company={item}
            primaryAction="convertToProspect"
            defaultActions={["unAssign", "follow"]}
          />
        ))}
      </div>
    </DashboardSection>
  );
};
