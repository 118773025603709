import { SyntheticEvent } from "react";

import { useNavigate } from "react-router";

import { Button, Card } from "@/app/components";
import { ActionNames, useActionWrapper, useOpportunityActions } from "@/app/hooks";

import { Tags } from "../../opportunities/components/card-list/tags";
import { getAvailableOpportunityActionNames } from "../../opportunities/utils";
import { DashboardCompany } from "../dashboard.types";

type CompanyCardProps = {
  company: DashboardCompany;
  primaryAction?: ActionNames;
  defaultActions?: ActionNames[];
};

const getCompanyTitle = (company: DashboardCompany): string => {
  if (company.name) {
    return company.name;
  }

  if (company.websiteUrl) {
    return company.websiteUrl;
  }

  const companyTeamMember = company.lgCompanyTeamsByCompanyId.nodes[0];
  const founder = companyTeamMember?.member?.name;

  return founder ? `Stealth (${founder})` : "Stealth company";
};

export const CompanyCard = ({ company, primaryAction, defaultActions = [] }: CompanyCardProps) => {
  const navigate = useNavigate();
  const actions = useOpportunityActions({ company }) || {};
  const primary = primaryAction && actions[primaryAction];
  const { loading, wrapAction } = useActionWrapper();

  const skipActions: ActionNames[] = [
    "convertToPortfolio",
    "convertToProspect",
    "moveStage",
    "moveToPipeline",
    "removeProspect",
  ];

  const availableActionNames = getAvailableOpportunityActionNames({
    company,
    defaultActions,
    skip: primaryAction ? [...skipActions, primaryAction] : skipActions,
  });

  const onPrimaryToggle = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (primary) {
      wrapAction({ ...primary, isAsync: true });
    }
  };

  const cardTitle = getCompanyTitle(company);

  return (
    <Card
      key={company.id}
      data-cy="company-card"
      className="group relative flex cursor-pointer select-none flex-col items-center gap-y-2 transition hover:scale-[1.01]"
      onClick={() => navigate(`/company/${company.id}`)}
    >
      <Card.Header>
        <Card.Image src={company.image} className="mr-3 rounded-sm" />
        <Card.Title title={cardTitle} />
        {actions && (
          <Card.Actions
            containerClassName="ml-auto"
            actions={actions}
            availableActionNames={availableActionNames as string[]}
          >
            {primary && (
              <Button
                data-cy={`action-${primaryAction}`}
                size={"sm"}
                variant={"secondary"}
                className="min-w-[80px] text-xs 2xl:text-sm"
                loading={loading[primary.label]}
                onClick={onPrimaryToggle}
              >
                {primary.label}
              </Button>
            )}
            {!primary && company.status === "draft" && (
              <span className="inline-block rounded-xs bg-red-100 p-2 text-xs font-medium text-red-500">
                Incomplete
              </span>
            )}
          </Card.Actions>
        )}
      </Card.Header>
      <Card.Body>
        <p className="line-clamp-1 min-h-10 text-sm font-medium text-neutral-700">{company.tagline}</p>
      </Card.Body>
      <Card.Footer className="mt-auto">
        <Tags city={company.city} country={company.country} badges={company.badges} />
      </Card.Footer>
    </Card>
  );
};
