import React, { forwardRef } from "react";

import { cn } from "@/lib/utils.ts";

export interface PillProps {
  label: string;
  className?: string;
  pillClassName?: string;
}

export const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(
  { label, className = "", pillClassName = "text-xxs font-medium md:text-xs" }: PillProps,
  ref,
) {
  return (
    <div
      ref={ref}
      className={`inline-block overflow-hidden rounded-xxs border-0 bg-neutral-100 px-2 py-1 text-center outline-none transition-all ${className}`}
    >
      <span
        className={cn(
          "block whitespace-nowrap text-sm font-semibold text-neutral-700 group-hover:text-black lg:text-xxs 2xl:text-xs",
          pillClassName,
        )}
      >
        {label}
      </span>
    </div>
  );
});
