import { cn } from "@/lib/utils";

import { Card, Button } from "@/app/components";
import { EmailFeedback } from "@/app/screens/modal";
import modalStore from "@/app/stores/modal.store";

import { CompanyLink } from "../../components/company-link";
import { DateColumn } from "../../components/email-opportunity/date-column";
import { DetailsColumn } from "../../components/email-opportunity/details-column";
import { NotificationStatus } from "../../dashboard.types";
import { useSetEmailStatus } from "../../hooks/use-set-email-status";

import { FeedbackEmailTemplate } from "./use-feedback-templates";

type FeedbackCardProps = {
  template: FeedbackEmailTemplate;
  className?: string;
};

export const FeedbackCard = ({ template, className }: FeedbackCardProps) => {
  const { id, createdUtc, payload, status } = template;
  const { handleMarkAsDone, loading } = useSetEmailStatus({ notificationId: id });

  const openTemplateModal = () => {
    modalStore.open(EmailFeedback, { props: payload });
  };

  const isCompleted = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED].includes(
    status as NotificationStatus,
  );

  return (
    <Card
      data-cy="dashboard-feedback-item"
      key={id}
      className={cn(
        "group relative flex select-none flex-col items-center justify-between  gap-y-2 transition hover:scale-[1.01] lg:flex-row",
        className,
      )}
    >
      <Card.Body className="grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          <DetailsColumn styles={{ labelStyle: "hidden md:block" }}>
            <CompanyLink
              id={payload.company.id}
              name={payload.company.name}
              subTitle="Moved back to Pipeline"
              subTitleClassName="md:hidden"
            >
              <Card.Image src={payload.company?.image} className="mr-2" />
            </CompanyLink>
          </DetailsColumn>
          <DateColumn date={createdUtc} styles={{ container: "md:hidden", labelStyle: "text-xs" }} />
        </div>

        <DateColumn
          date={createdUtc}
          label="Moved back to Pipeline"
          styles={{ container: "hidden md:block col-span-4" }}
        />
        <div className="col-span-2 flex items-center justify-end gap-2 md:col-span-4">
          {!isCompleted && (
            <Button
              iconLeft="Check"
              variant={"secondary"}
              size="sm"
              className="w-full md:w-auto"
              onClick={handleMarkAsDone}
              loading={loading}
            >
              Mark as done
            </Button>
          )}
          <Button
            variant="primary"
            className="w-full md:w-auto"
            size="sm"
            text="View Template"
            onClick={openTemplateModal}
          >
            View template
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
