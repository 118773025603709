import React, { PropsWithChildren, SyntheticEvent } from "react";

import { Menu, MenuButton, MenuButtonProps, MenuItem, MenuItems, MenuItemsProps } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Button as GenericButton } from "../button/button.tsx";
import { IconButtonV2 as IconButton } from "../button/icon-button-v2.tsx";

type DefaultDropdownProps<T> = {
  options: T[];
  title: string;
  onChange: (value: T) => void;
  getKey: T extends object ? (value: T) => string : never;
  renderItem: (value: T) => React.ReactNode;
};

type DropdownProps<T> =
  | PropsWithChildren<{
      className?: string;
      options?: null;
    }>
  | DefaultDropdownProps<T>;

type DropdownItemsProps = PropsWithChildren<{
  anchor?: MenuItemsProps["anchor"];
  className?: string;
}>;

type DropdownOptionProps = PropsWithChildren<{
  disabled?: boolean;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
}> &
  MenuItemsProps;

type ButtonVariants = {
  IconButton: typeof IconButton;
  GenericButton: typeof GenericButton;
};

type DropdownButtonProps<TVariant extends keyof ButtonVariants> = {
  buttonVariant?: TVariant;
} & Omit<MenuButtonProps<ButtonVariants[TVariant]>, "as">;

function Button<TVariant extends keyof ButtonVariants = "GenericButton">({
  buttonVariant,
  ...props
}: DropdownButtonProps<TVariant>) {
  const as = buttonVariant === "IconButton" ? IconButton : GenericButton;

  return <MenuButton {...props} as={as} data-cy="expand" />;
}

function Items({ children, className, anchor = "bottom end", ...props }: DropdownItemsProps) {
  return (
    <MenuItems
      {...props}
      anchor={anchor}
      className={cn(
        `mt-1 overflow-y-auto rounded-sm bg-white shadow-dropdown transition duration-100 ease-out`,
        className,
      )}
    >
      {children}
    </MenuItems>
  );
}

function Item({ children, className, disabled, onClick }: DropdownOptionProps) {
  return (
    <MenuItem>
      <button
        className={cn(
          "flex w-full items-center bg-white px-4 py-3 text-xs font-semibold text-black hover:bg-neutral-200 disabled:bg-neutral-50",
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        data-cy="menu-item"
      >
        {children}
      </button>
    </MenuItem>
  );
}

function DropdownRoot<T>(props: DropdownProps<T>) {
  if (!props.options) {
    const { className, children } = props;
    return (
      <Menu {...props} className={className} as="div">
        {children}
      </Menu>
    );
  }

  const { options, title, getKey, onChange, renderItem, ...rest } = props;
  return (
    <Menu {...rest} as="div">
      <Button>{title}</Button>
      <Items>
        {options.map((op) => (
          <Item key={getKey(op)} onClick={() => onChange(op)}>
            {renderItem(op)}
          </Item>
        ))}
      </Items>
    </Menu>
  );
}

export const DropdownV2 = Object.assign(DropdownRoot, { Button, Items, Item });
